import React from "react";
import { Modal } from "react-bootstrap";

const ModalIcons = (props) => {
  return (
    <Modal
      size="lg"
      show={props.show}
      onHide={props.handleClose}
      scrollable={true}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Pilih File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-wrap">
          {props.assets &&
            props.assets
              .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
              .map((item, index) => (
                <div
                  key={index}
                  className="cursor-pointer d-flex flex-column mr-3 mb-3"
                  onClick={props.assetsSelect(item.url)}
                >
                  <img
                    src={item.url}
                    alt={item.name}
                    className="assets-img img-thumbnail"
                  />
                  <span className="fz-13 ellipsis">{item.name}</span>
                </div>
              ))}
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default ModalIcons;
