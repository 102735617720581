import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import {
  Link,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom";
import ModalIcons from "./ModalIcons";
import SweetAlerMessage from "../../../js/sweetalert";
import swal from "sweetalert";
import { Helmet, HelmetProvider } from "react-helmet-async";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";

const AddItems = () => {
  const history = useHistory();
  const location = useLocation();

  const handleGoBack = () => {
    if (window.location.pathname === "/products/games/add-games") {
      history.goBack();
    } else {
      history.goBack();
    }
  };

  const [show, setShow] = useState(false);
  const [icon, setIcon] = useState("");
  const [assets, setAssets] = useState("");
  const [select, setSelect] = useState("");
  const [game, setGame] = useState([]);
  const [gameSelect, setGameSelect] = useState("");
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [category, setCategory] = useState("");
  const [vendor, setVendor] = useState([]);
  const [vendorSelect, setVendorSelect] = useState("");
  const [vendorPrice, setVendorPrice] = useState("");
  const [typePrice, setTypePrice] = useState("");
  const [price, setPrice] = useState("");
  const [gameTo, setGameTo] = useState("");
  const [status, setStatus] = useState(true);
  const [listCode, setListCode] = useState([]);

  // new
  const [gameAuto, setGameAuto] = useState([]);
  const [productsAuto, setProductsAuto] = useState([]);

  const [displayNavPill, setDisplayNavPill] = useState({
    detail: "none",
  });
  const [displayStatus, setDisplayStatus] = useState({
    detail: "none",
  });
  const [displayFormOtomatis, setDisplayFormOtomatis] = useState({
    detail: "none",
  });
  const [urlAuto, setUrlAuto] = useState(
    `${process.env.REACT_APP_BASE_URL}/api/product/bulk`
  );
  const [urlManual, setUrlManual] = useState(
    `${process.env.REACT_APP_BASE_URL}/api/product`
  );
  // const [url, setUrl] = useState(
  //   `${process.env.REACT_APP_BASE_URL}/api/product/`
  // );
  const [method, setMethod] = useState("POST");
  const [title, setTitle] = useState("Tambah Item");
  const [msg, setMsg] = useState("Item berhasil ditambahkan");

  const handleClose = () => setShow(false);
  const handleShow = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/asset`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAssets(data.data);
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
    setShow(true);
  };

  const assetsSelect = (url) => () => {
    setIcon(url);
    handleClose();
  };

  const handleClearItem = () => {
    setIcon("");
    setGameSelect("");
    setName("");
    setCode("");
    setCategory("");
    setVendorSelect("");
    setVendorPrice("");
    setTypePrice("");
    setGameTo("");
    setPrice("");
    setStatus(true);
  };

  const fetchVendorData = (selectedValue) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/vendor/get/games`, {
      method: "POST",
      body: JSON.stringify({
        id: selectedValue,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, data: data })))
      .then((data) => {
        if (data.status === 200) {
          setGameAuto(data.data.data);
        } else {
          swal({
            title: "Gagal",
            text: data.data.msg,
            icon: "error",
            buttons: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  };

  const btnAuto = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/vendor/get/products`, {
      method: "POST",
      body: JSON.stringify({
        id: vendorSelect,
        code: gameSelect,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, data: data })))
      .then((data) => {
        if (data.status === 200) {
          setDisplayFormOtomatis({ ...displayFormOtomatis, detail: "block" });
          setListCode(data.data.data);
        } else {
          swal({
            title: "Gagal",
            text: data.data.msg,
            icon: "error",
            buttons: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  };

  const handleDeleteRow = (index) => {
    const updatedList = listCode.filter((_, i) => i !== index);
    setListCode(updatedList);
  };

  const handleSubmitAuto = (e) => {
    e.preventDefault();
    const dataToSend = listCode.map((item) => ({
      icon,
      gameId: gameTo,
      name: item.name,
      code: item.code,
      category,
      vendorId: vendorSelect,
      vendorPrice: parseFloat(item.price),
      marginType: typePrice,
      margin: parseFloat(price),
      status,
    }));

    fetch(urlAuto, {
      method: method,
      body: JSON.stringify({
        arr: dataToSend,
        token: localStorage.getItem("token"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, data: data })))
      .then((data) => {
        if (data.status === 200) {
          swal({
            title: "Berhasil",
            text: msg,
            icon: "success",
            buttons: false,
            timer: 1000,
          });
          if (window.location.pathname === "/products/items/add-items") {
            history.goBack();
          } else {
            history.goBack();
          }
        } else {
          swal({
            title: "Gagal",
            text: data.data.msg,
            icon: "error",
            buttons: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  };

  const handleSubmitManual = (e) => {
    e.preventDefault();
    fetch(urlManual, {
      method: method,
      body: JSON.stringify({
        icon,
        gameId: gameSelect,
        name,
        code,
        category,
        vendorId: vendorSelect,
        vendorPrice: parseFloat(vendorPrice),
        marginType: typePrice,
        margin: parseFloat(price),
        status,
        token: localStorage.getItem("token"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, data: data })))
      .then((data) => {
        if (data.status === 200) {
          swal({
            title: "Berhasil",
            text: msg,
            icon: "success",
            buttons: false,
            timer: 1000,
          });
          if (window.location.pathname === "/products/items/add-items") {
            history.goBack();
          } else {
            history.goBack();
          }
        } else {
          swal({
            title: "Gagal",
            text: data.data.msg,
            icon: "error",
            buttons: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    SweetAlerMessage();
    $("[data-height]").each(function() {
      $(this).css({
        height: $(this).data("height"),
      });
    });

    fetch(`${process.env.REACT_APP_BASE_URL}/api/game`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json().then((d) => ({ status: r.status, data: d.data })))
      .then((d) => {
        setGame(d.data);
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });

    fetch(`${process.env.REACT_APP_BASE_URL}/api/vendor`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json().then((d) => ({ status: r.status, data: d.data })))
      .then((d) => {
        setVendor(d.data);
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });

    if (window.location.pathname === "/products/items/add-items") {
      setDisplayNavPill({ ...displayNavPill, detail: "flex" });
      setDisplayFormOtomatis({ ...displayFormOtomatis, detail: "none" });
      $("#pills-otomatis")
        .addClass("show active")
        .show();
      $("#pills-manual").removeClass("show active");
      setUrlAuto(`${process.env.REACT_APP_BASE_URL}/api/product/bulk`);
      setUrlManual(`${process.env.REACT_APP_BASE_URL}/api/product`);
      setMethod("POST");
    } else {
      setDisplayStatus({ ...displayStatus, detail: "block" });
      setDisplayNavPill({ ...displayNavPill, detail: "none" });
      $("#pills-otomatis")
        .removeClass("show active")
        .hide();
      $("#pills-manual").addClass("show active");
      setUrlManual(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/product/${window.location.pathname.split("/").pop()}`
      );
      setMethod("PUT");

      fetch(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/product?id=${window.location.pathname.split("/").pop()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((r) => r.json().then((d) => ({ status: r.status, data: d.data })))
        .then((d) => {
          setIcon(d.data.icon);
          setGameSelect(d.data.game._id);
          setName(d.data.name);
          setCode(d.data.code);
          setCategory(d.data.category);
          setVendorSelect(d.data.vendor._id);
          setVendorPrice(d.data.vendor_price);
          setTypePrice(d.data.margin_type);
          setPrice(d.data.margin);
          setStatus(d.data.status);
        })
        .catch((err) => {
          swal(err.message, {
            icon: "error",
            button: false,
          });
        });
      setTitle("Edit Item");
      setMsg("Item berhasil diubah");
    }
  }, [location.pathname]);

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charset="utf-8" />
        <meta name="description" content="Add Items - Admin" />
        <meta
          name="google-site-verification"
          content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34="
        />
        <title>Add Items - Admin</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Items</h1>
            <div className="section-header-breadcrumb">
              <div className="breadcrumb-item active">
                <Link to="/dashboard">Dashboard</Link>
              </div>
              <div className="breadcrumb-item">
                <Link to="/products/games">List Items</Link>
              </div>
              <div className="breadcrumb-item" id="title-game">
                Add Items
              </div>
            </div>
          </div>

          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div
                      className="cursor-pointer d-flex"
                      onClick={handleGoBack}
                    >
                      <span>
                        <i className="fas fa-arrow-left"></i>
                      </span>
                      <h4 className="ml-2">{title}</h4>
                    </div>
                  </div>
                  <div className="card-body">
                    <ul
                      className="nav nav-pills mb-4 gap"
                      id="pills-tab"
                      role="tablist"
                      style={{ display: displayNavPill.detail }}
                    >
                      <li className="nav-item mr-2" onClick={handleClearItem}>
                        <a
                          className="nav-link btn-sm active"
                          id="pills-otomatis-tab"
                          data-toggle="pill"
                          href="#pills-otomatis"
                          role="tab"
                          aria-controls="pills-otomatis"
                          aria-selected="true"
                        >
                          Otomatis
                        </a>
                      </li>
                      <li className="nav-item mr-2" onClick={handleClearItem}>
                        <a
                          className="nav-link btn-sm"
                          id="pills-manual-tab"
                          data-toggle="pill"
                          href="#pills-manual"
                          role="tab"
                          aria-controls="pills-manual"
                          aria-selected="false"
                        >
                          Manual
                        </a>
                      </li>
                    </ul>

                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-otomatis"
                        role="tabpanel"
                        aria-labelledby="pills-otomatis-tab"
                      >
                        <form onSubmit={handleSubmitAuto}>
                          <div className="otomatis">
                            <Row className="align-items-center">
                              <Col xs={12} lg={6}>
                                <div className="form-group">
                                  <label>Vendor</label>
                                  <select
                                    className="form-control"
                                    onChange={(e) => {
                                      setVendorSelect(e.target.value);
                                      fetchVendorData(e.target.value);
                                    }}
                                    value={vendorSelect}
                                  >
                                    <option value="">Pilih Vendor</option>
                                    {vendor &&
                                      vendor.length > 0 &&
                                      vendor.map((item) => (
                                        <option key={item._id} value={item._id}>
                                          {item.name}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </Col>
                              <Col xs={12} lg={5}>
                                <div className="form-group">
                                  <label>Game</label>
                                  <select
                                    className="form-control"
                                    onChange={(e) => {
                                      setGameSelect(e.target.value);
                                    }}
                                    value={gameSelect}
                                  >
                                    <option value="">Pilih Game</option>
                                    {gameAuto &&
                                      gameAuto.map((item) => {
                                        if (item.active) {
                                          return (
                                            <option
                                              key={item.code}
                                              value={item.code}
                                            >
                                              {item.name}
                                            </option>
                                          );
                                        }
                                        return null;
                                      })}
                                  </select>
                                </div>
                              </Col>
                              <Col
                                xs={12}
                                lg={1}
                                style={{ display: displayNavPill.detail }}
                              >
                                <Button
                                  variant="primary"
                                  className="mt-2"
                                  onClick={btnAuto}
                                >
                                  Cari
                                </Button>
                              </Col>
                            </Row>
                            <div
                              style={{ display: displayFormOtomatis.detail }}
                            >
                              <Row>
                                <Table responsive striped bordered>
                                  <thead>
                                    <tr>
                                      <th>No</th>
                                      <th>Kode</th>
                                      <th>Nama</th>
                                      <th>Harga dari Vendor</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {listCode.length > 0 &&
                                      listCode.map((item, index) => (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>
                                            <Form.Control
                                              size="sm"
                                              type="text"
                                              value={item.code}
                                              className="form-control"
                                              disabled
                                            />
                                          </td>
                                          <td>
                                            <Form.Control
                                              size="sm"
                                              type="text"
                                              onChange={(e) => {
                                                const updatedList = [
                                                  ...listCode,
                                                ];
                                                updatedList[index].name =
                                                  e.target.value;
                                                setListCode(updatedList);
                                              }}
                                              value={item.name || ""}
                                              className="form-control"
                                            />
                                          </td>
                                          <td>
                                            <Form.Control
                                              size="sm"
                                              type="text"
                                              value={item.price}
                                              className="form-control"
                                              disabled
                                            />
                                          </td>
                                          <td>
                                            <button
                                              type="button"
                                              className="btn btnDel"
                                              onClick={() =>
                                                handleDeleteRow(index)
                                              }
                                            >
                                              <i className="text-danger fas fa-trash m-2"></i>
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </Table>
                                <Col xs={12} lg={6}>
                                  <div className="form-group">
                                    <label>Margin</label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      onChange={(e) => setPrice(e.target.value)}
                                      value={price}
                                      placeholder="Masukkan Margin"
                                    />
                                  </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                  <div className="form-group">
                                    <label>Icon</label>
                                    <div className="input-group mb-3">
                                      <input
                                        type="text"
                                        className="form-control url"
                                        placeholder="Masukkan Link Icon"
                                        onChange={(e) =>
                                          setIcon(e.target.value)
                                        }
                                        value={icon}
                                        disabled
                                      />
                                      <div className="input-group-append">
                                        <button
                                          className="btn btn-primary"
                                          type="button"
                                          onClick={() => {
                                            handleShow();
                                            setSelect("icon");
                                          }}
                                        >
                                          Pilih File
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                  <div className="form-group">
                                    <label>Tipe Margin</label>
                                    <select
                                      className="form-control"
                                      onChange={(e) => {
                                        setTypePrice(e.target.value);
                                      }}
                                      value={typePrice}
                                    >
                                      <option value="">
                                        Pilih Tipe Margin
                                      </option>
                                      <option value="flat">Flat</option>
                                      <option value="percent">Percent</option>
                                    </select>
                                  </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                  <div className="form-group">
                                    <label>Kategori</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        setCategory(e.target.value)
                                      }
                                      value={category}
                                      placeholder="Masukkan Kategori"
                                    />
                                    <span className="text-danger">
                                      Cth: Event Neobeast / Special Items / Top
                                      Up Instant / Gift Kharisma / dll
                                    </span>
                                  </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                  <div className="form-group">
                                    <label>Tujuan Game</label>
                                    <select
                                      className="form-control"
                                      onChange={(e) =>
                                        setGameTo(e.target.value)
                                      }
                                      value={gameTo}
                                    >
                                      <option value="">Pilih Game</option>
                                      {game.map((item) => {
                                        return (
                                          <option
                                            key={item._id}
                                            value={item._id}
                                          >
                                            {item.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </Col>
                                <Col
                                  xs={12}
                                  lg={6}
                                  style={{ display: displayStatus.detail }}
                                >
                                  <div className="form-group">
                                    <label>Status</label>
                                    <select
                                      className="form-control"
                                      onChange={(e) =>
                                        setStatus(e.target.value)
                                      }
                                      value={status}
                                    >
                                      <option disabled>Pilih Status</option>
                                      <option value={true}>Aktif</option>
                                      <option value={false}>Nonaktif</option>
                                    </select>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                          <div className="card-footer text-right">
                            <button
                              className="btn btn-primary mr-1"
                              type="submit"
                            >
                              Submit
                            </button>
                            <Link
                              to="/products/items"
                              className="btn btn-secondary"
                              type="button"
                            >
                              Batal
                            </Link>
                          </div>
                        </form>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-manual"
                        role="tabpanel"
                        aria-labelledby="pills-manual-tab"
                      >
                        <form onSubmit={handleSubmitManual}>
                          <div className="manual">
                            <Row>
                              <Col xs={12} lg={6}>
                                <div className="form-group">
                                  <label>Vendor</label>
                                  <select
                                    className="form-control"
                                    onChange={(e) =>
                                      setVendorSelect(e.target.value)
                                    }
                                    value={vendorSelect}
                                  >
                                    <option value="">Pilih Vendor</option>
                                    {vendor.map((item) => {
                                      return (
                                        <option key={item._id} value={item._id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </Col>
                              <Col xs={12} lg={6}>
                                <div className="form-group">
                                  <label>Game</label>
                                  <select
                                    className="form-control"
                                    onChange={(e) =>
                                      setGameSelect(e.target.value)
                                    }
                                    value={gameSelect}
                                  >
                                    <option value="">Pilih Game</option>
                                    {game.map((item) => {
                                      return (
                                        <option key={item._id} value={item._id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </Col>
                              <Col xs={12} lg={6}>
                                <div className="form-group">
                                  <label>Nama</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                    placeholder="Masukkan Nama"
                                  />
                                </div>
                              </Col>
                              <Col xs={12} lg={6}>
                                <div className="form-group">
                                  <label>Kode</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => setCode(e.target.value)}
                                    value={code}
                                    placeholder="Masukkan Kode"
                                  />
                                </div>
                              </Col>
                              <Col xs={12} lg={6}>
                                <div className="form-group">
                                  <label>Kategori</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) =>
                                      setCategory(e.target.value)
                                    }
                                    value={category}
                                    placeholder="Masukkan Kategori"
                                  />
                                  <span className="text-danger">
                                    Cth: Event Neobeast / Special Items / Top Up
                                    Instant / Gift Kharisma / dll
                                  </span>
                                </div>
                              </Col>
                              <Col xs={12} lg={6}>
                                <div className="form-group">
                                  <label>Harga dari Vendor</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onChange={(e) =>
                                      setVendorPrice(e.target.value)
                                    }
                                    value={vendorPrice}
                                    placeholder="Masukkan Harga Vendor"
                                  />
                                </div>
                              </Col>
                              <Col xs={12} lg={6}>
                                <div className="form-group">
                                  <label>Tipe Margin</label>
                                  <select
                                    className="form-control"
                                    onChange={(e) =>
                                      setTypePrice(e.target.value)
                                    }
                                    value={typePrice}
                                  >
                                    <option value="">Pilih Tipe Margin</option>
                                    <option value="flat">Flat</option>
                                    <option value="percent">Percent</option>
                                  </select>
                                </div>
                              </Col>
                              <Col xs={12} lg={6}>
                                <div className="form-group">
                                  <label>Margin</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onChange={(e) => setPrice(e.target.value)}
                                    value={price}
                                    placeholder="Masukkan Margin"
                                  />
                                </div>
                              </Col>
                              <Col xs={12}>
                                <div className="form-group">
                                  <div className="d-flex">
                                    <label className="mr-2">Icon</label>
                                    <OverlayTrigger
                                      key={"top"}
                                      top={"top"}
                                      overlay={
                                        <Tooltip id={`tooltip-${"top"}`}>
                                          <img
                                            src={`${process.env.REACT_APP_STATIC_SRC}/prevIconProduct_1688384683113.webp`}
                                            alt="icon"
                                            width="400px"
                                          />
                                        </Tooltip>
                                      }
                                    >
                                      <div>
                                        <i className="fas fa-question-circle"></i>
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                  <div className="input-group mb-3">
                                    <input
                                      type="text"
                                      className="form-control url"
                                      placeholder="Masukkan Link Icon"
                                      onChange={(e) => setIcon(e.target.value)}
                                      value={icon}
                                      disabled
                                    />
                                    <div className="input-group-append">
                                      <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() => {
                                          handleShow();
                                          setSelect("icon");
                                        }}
                                      >
                                        Pilih File
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col
                                xs={12}
                                lg={6}
                                style={{ display: displayStatus.detail }}
                              >
                                <div className="form-group">
                                  <label>Status</label>
                                  <select
                                    className="form-control"
                                    onChange={(e) => setStatus(e.target.value)}
                                    value={status}
                                  >
                                    <option disabled>Pilih Status</option>
                                    <option value={true}>Aktif</option>
                                    <option value={false}>Nonaktif</option>
                                  </select>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div className="card-footer text-right">
                            <button
                              className="btn btn-primary mr-1"
                              type="submit"
                            >
                              Submit
                            </button>
                            <Link
                              to="/products/items"
                              className="btn btn-secondary"
                              type="button"
                            >
                              Batal
                            </Link>
                          </div>
                        </form>
                      </div>
                      <ModalIcons
                        show={show}
                        handleClose={handleClose}
                        assets={assets}
                        assetsSelect={assetsSelect}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </HelmetProvider>
  );
};

export default AddItems;
