import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import ModuleEvents from "../../js/ModuleEvents";
import { Link } from "react-router-dom";
import SweetAlerMessage from "../../js/sweetalert";
import $ from "jquery";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Helmet, HelmetProvider } from "react-helmet-async";

const ListEvent = () => {
  const history = useHistory();

  useEffect(() => {
    ModuleEvents();
    SweetAlerMessage();

    $("#events-table").on("click", ".btnEdit", function() {
      const id = $(this).data("id");
      history.push(`/events/add-events/${id}`);
    });
  }, [history]);

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charset="utf-8" />
        <meta name="description" content="Events - Admin" />
        <meta name="google-site-verification" content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34=" />
        <title>Event - Admin</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Events</h1>
            <div className="section-header-breadcrumb">
              <div className="breadcrumb-item active">
                <Link to="/dashboard">Dashboard</Link>
              </div>
              <div className="breadcrumb-item">List Event</div>
            </div>
          </div>

          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-between">
                    <h4>List Events</h4>
                    <Link to="/events/add-events">
                      <Button className="create-new">
                        <i className="fas fa-plus me-sm-1 text-light"></i> <span className="d-none d-sm-inline-block text-light">Tambah Event</span>
                      </Button>
                    </Link>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped" id="events-table">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>title</th>
                            <th>Deskripsi</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Diskon</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </HelmetProvider>
  );
};

export default ListEvent;
