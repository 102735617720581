import secureLocalStorage from "react-secure-storage";
import { decodeToken } from "react-jwt";

const token = secureLocalStorage.getItem("role");

let Data;
if (token) {
  const myDecodedToken = decodeToken(token);
  if (myDecodedToken.role === "owner") {
    Data = [
      {
        header: true,
        name: "Dashboard",
      },
      {
        name: "Dashboard",
        icon: "fas fa-fire",
        url: "/dashboard",
      },
      {
        header: true,
        name: "Menu",
      },
      {
        name: "Transactions",
        icon: "fas fa-chart-line",
        url: "/transactions",
      },
      {
        dropdown: true,

        name: "Products",
        icon: "fas fa-box",
        children: [
          {
            name: "Games",
            url: "/products/games",
          },
          {
            name: "Items",
            url: "/products/items",
          },
        ],
      },
      {
        name: "Payments",
        icon: "fas fa-wallet",
        url: "/payments",
      },
      {
        name: "Assets",
        icon: "fas fa-images",
        url: "/asset",
      },
      {
        name: "Vendors",
        icon: "fas fa-store",
        url: "/vendors",
      },
      {
        name: "Site Config",
        icon: "fas fa-wrench",
        url: "/site-config",
      },
      {
        name: "Log",
        icon: "fas fa-history",
        url: "/logs",
      },
    ];
  } else if (myDecodedToken.role === "super") {
    Data = [
      {
        header: true,
        name: "Dashboard",
      },
      {
        name: "Dashboard",
        icon: "fas fa-fire",
        url: "/dashboard",
      },
      {
        header: true,
        name: "Menu",
      },
      {
        name: "Transactions",
        icon: "fas fa-chart-line",
        url: "/transactions",
      },
      {
        dropdown: true,

        name: "Products",
        icon: "fas fa-box",
        children: [
          {
            name: "Games",
            url: "/products/games",
          },
          {
            name: "Items",
            url: "/products/items",
          },
        ],
      },
      {
        name: "Events",
        icon: "fas fa-fire",
        url: "/events",
      },
      {
        name: "Payments",
        icon: "fas fa-wallet",
        url: "/payments",
      },
      {
        name: "Vouchers",
        icon: "fas fa-ticket-alt",
        url: "/vouchers",
      },
      {
        name: "Assets",
        icon: "fas fa-images",
        url: "/asset",
      },
      {
        name: "Vendors",
        icon: "fas fa-store",
        url: "/vendors",
      },
      {
        name: "Site Config",
        icon: "fas fa-wrench",
        url: "/site-config",
      },
      {
        name: "Log",
        icon: "fas fa-history",
        url: "/logs",
      },
      {
        header: true,
        name: "Super Admin",
      },
      {
        name: "Tenant",
        icon: "fab fa-accusoft",
        url: "/tenants",
      },
      {
        name: "Db synchronization",
        icon: "fa fa-database",
        url: "/db-synchronization",
      },
      {
        name: "Global Rate",
        icon: "fa fa-star",
        url: "/global-rate",
      },
    ];
  } else if (myDecodedToken.role === "partner") {
    Data = [
      {
        header: true,
        name: "Dashboard",
      },
      {
        name: "Dashboard",
        icon: "fas fa-fire",
        url: "/dashboard",
      },
      {
        header: true,
        name: "Menu",
      },
      {
        name: "Transactions",
        icon: "fas fa-chart-line",
        url: "/transactions",
      },
    ];
  }
}
export default Data;
