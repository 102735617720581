import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { Header, Footer, Sidebar } from "./components/admin";
import swal from "sweetalert";
import secureLocalStorage from "react-secure-storage";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard/";
import ListTrx from "./pages/Trx/";
import DetailTrx from "./pages/Trx/DetailTrx";
import ListGames from "./pages/Products/Games/";
import AddGames from "./pages/Products/Games/AddGames";
import ListItems from "./pages/Products/Items";
import AddItems from "./pages/Products/Items/AddItems";
import EditBulk from "./pages/Products/Items/EditBulk";
import SyncPrice from "./pages/Products/Items/SyncPrice";
import SyncVendor from "./pages/Products/Items/SyncVendor";
import ListPayments from "./pages/Payments/";
import AddPayments from "./pages/Payments/AddPayments";
import ListAssets from "./pages/Assets/";
import AddAssets from "./pages/Assets/AddAssets";
import Vouchers from "./pages/Vouchers/";
import AddVouchers from "./pages/Vouchers/AddVouchers";
import Events from "./pages/Events/";
import AddEvents from "./pages/Events/AddEvents";
import Vendors from "./pages/Vendors/";
import AddVendors from "./pages/Vendors/AddVendors";
import SiteConfig from "./pages/SiteConfig/";
import Logs from "./pages/Logs/";
import Tenants from "./pages/Tenants/";
import AddTenants from "./pages/Tenants/AddTenants";
import Synchronization from "./pages/Synchronization";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { decodeToken } from "react-jwt";
import DetailTenants from "./pages/Tenants/DetailTenants";
import DashboardTenants from "./pages/Tenants/DashboardTenants";
import GlobalRate from "./pages/GlobalRate";

const Error404 = React.lazy(() => import("./pages/Error/404"));

function App() {
  const [favicon, setFavicon] = useState("");

  let history = useHistory();
  let location = useLocation().pathname;
  let WithoutRouter = ["/", "/login", "/api/login/callback"];

  if (location === "/api/login/callback") {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");

    fetch(`${process.env.REACT_APP_BASE_URL}/api/login/createtoken?code=${code}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, data: data })))
      .then((data) => {
        if (data.status === 200) {
          localStorage.setItem("token", data.data.token);
          secureLocalStorage.setItem("role", data.data.jwt);
          window.location.href = "/dashboard";
        } else {
          window.location.href = "/login";
        }
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  }

  const token = secureLocalStorage.getItem("role");
  const myDecodedToken = token !== null ? decodeToken(token) : null;

  if (!WithoutRouter.includes(location)) {
    if (localStorage.getItem("token") === null) {
      history.push("/login");
    }
  }

  useEffect(() => {
    if (myDecodedToken === null) {
      history.push("/login");
    }
    fetch(`${process.env.REACT_APP_BASE_URL}/api/setting`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, data: data.data })))
      .then((data) => {
        setFavicon(data.data[0].favicon);
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  }, [myDecodedToken, history]);

  return (
    <HelmetProvider>
      <Helmet>
        <link rel="icon" href={`${favicon}`} />
        <link rel="apple-touch-icon" href={`${favicon}`} />
      </Helmet>
      <div className="App">
        <>
          {!WithoutRouter.includes(location) ? (
            <>
              <Header />
              <Sidebar />
            </>
          ) : (
            ""
          )}
          <React.Suspense>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route path="/login" component={Login} />
              <Route path="/api/login/callback" />
              <Route path="/dashboard" component={Dashboard} />
              <Route exact path="/transactions" component={ListTrx} />
              {token !== null ? (
                myDecodedToken.role === "owner" ? (
                  <>
                    <Route path="/transactions/detail" component={DetailTrx} />
                    <Route exact path="/products/games" component={ListGames} />
                    <Route path="/products/games/add-games" component={AddGames} />
                    <Route exact path="/products/items" component={ListItems} />
                    <Route path="/products/items/add-items" component={AddItems} />
                    <Route path="/products/items/edit-bulk" component={EditBulk} />
                    <Route path="/products/items/sync-price" component={SyncPrice} />
                    <Route path="/products/items/sync-vendor" component={SyncVendor} />
                    <Route exact path="/payments" component={ListPayments} />
                    <Route path="/payments/add-payments" component={AddPayments} />
                    <Route exact path="/asset" component={ListAssets} />
                    <Route path="/asset/add-asset" component={AddAssets} />
                    <Route exact path="/vendors" component={Vendors} />
                    <Route path="/vendors/add-vendors" component={AddVendors} />
                    <Route exact path="/site-config" component={SiteConfig} />
                    <Route path="/logs" component={Logs} />
                  </>
                ) : myDecodedToken.role === "super" ? (
                  <>
                    <Route path="/transactions/detail" component={DetailTrx} />
                    <Route exact path="/products/games" component={ListGames} />
                    <Route path="/products/games/add-games" component={AddGames} />
                    <Route exact path="/products/items" component={ListItems} />
                    <Route path="/products/items/add-items" component={AddItems} />
                    <Route path="/products/items/edit-bulk" component={EditBulk} />
                    <Route path="/products/items/sync-price" component={SyncPrice} />
                    <Route path="/products/items/sync-vendor" component={SyncVendor} />
                    <Route exact path="/payments" component={ListPayments} />
                    <Route path="/payments/add-payments" component={AddPayments} />
                    <Route exact path="/asset" component={ListAssets} />
                    <Route path="/asset/add-asset" component={AddAssets} />
                    <Route exact path="/vouchers" component={Vouchers} />
                    <Route path="/vouchers/add-vouchers" component={AddVouchers} />
                    <Route exact path="/events" component={Events} />
                    <Route path="/events/add-events" component={AddEvents} />
                    <Route exact path="/vendors" component={Vendors} />
                    <Route path="/vendors/add-vendors" component={AddVendors} />
                    <Route exact path="/site-config" component={SiteConfig} />
                    <Route path="/logs" component={Logs} />
                    <Route exact path="/tenants" component={Tenants} />
                    <Route path="/tenants/add-tenants" component={AddTenants} />
                    <Route exact path="/tenants/dashboard" component={DetailTenants} />
                    <Route exact path="/tenants/dashboard/all" component={DashboardTenants} />
                    <Route path="/db-synchronization" component={Synchronization} />
                    <Route path="/global-rate" component={GlobalRate} />
                  </>
                ) : (
                  <></>
                )
              ) : (
                <> </>
              )}
              <Route path="*" component={Error404} />
            </Switch>
          </React.Suspense>
          {!WithoutRouter.includes(location) ? (
            <>
              <Footer />
            </>
          ) : (
            ""
          )}
        </>
      </div>
    </HelmetProvider>
  );
}

export default App;
