import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  Link,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom";
import ModalIcons from "./ModalIcons";
import SweetAlerMessage from "../../../js/sweetalert";
import swal from "sweetalert";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";

const EditBulk = () => {
  const history = useHistory();
  const location = useLocation();
  const handleGoBack = () => {
    if (history.location.pathname === location.pathname) {
      history.goBack();
    }
  };

  const [show, setShow] = useState(false);
  const [assets, setAssets] = useState("");
  const [gameSelect, setGameSelect] = useState("");
  const [listCode, setListCode] = useState([]);
  const [id, setId] = useState("");
  const [game, setGame] = useState([]);
  const [displayFormOtomatis, setDisplayFormOtomatis] = useState({
    detail: "none",
  });

  const handleClose = () => setShow(false);
  const handleShow = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/asset`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAssets(data.data);
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
    setShow(true);
  };

  const assetsSelect = (url) => () => {
    const updatedList = [...listCode];
    updatedList.find((item) => item._id === id).icon = url;
    setListCode(updatedList);
    handleClose();
  };

  const fetchProductsData = (selectedValue) => {
    setDisplayFormOtomatis({ ...displayFormOtomatis, detail: "block" });
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/product?game=${selectedValue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((r) => r.json().then((data) => ({ status: r.status, data: data })))
      .then((data) => {
        if (data.status === 200) {
          setListCode(data.data.data);
        } else {
          swal({
            title: "Gagal",
            text: data.data.msg,
            icon: "error",
            buttons: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  };

  const handleSubmitAuto = (e) => {
    e.preventDefault();
    const dataToSend = listCode.map((item) => ({
      id: item._id,
      icon: item.icon,
      game: gameSelect,
      name: item.name,
      code: item.code,
      category: item.category,
      vendor_price: parseFloat(item.vendor_price),
      margin_type: item.margin_type,
      margin: parseFloat(item.margin),
      status: item.status,
    }));

    fetch(`${process.env.REACT_APP_BASE_URL}/api/product/bulk`, {
      method: "PUT",
      body: JSON.stringify({
        arr: dataToSend,
        token: localStorage.getItem("token"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, data: data })))
      .then((data) => {
        if (data.status === 200) {
          swal({
            title: "Berhasil",
            text: "Item berhasil ditambahkan",
            icon: "success",
            buttons: false,
            timer: 1000,
          });
          history.goBack();
        } else {
          swal({
            title: "Gagal",
            text: data.data.msg,
            icon: "error",
            buttons: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  };

  const handleDelete = (id) => () => {
    fetch(
      `${
        process.env.REACT_APP_BASE_URL
      }/api/product/${id}?token=${localStorage.getItem("token")}`,
      {
        method: "DELETE",
        headers: {},
      }
    )
      .then((r) => r.json().then((data) => ({ status: r.status, data: data })))
      .then((data) => {
        if (data.status === 200) {
          swal({
            title: "Berhasil",
            text: data.data.msg,
            icon: "success",
            buttons: false,
            timer: 1000,
          });
          const updatedList = listCode.filter((item) => item._id !== id);
          setListCode(updatedList);
        } else {
          swal({
            title: "Gagal",
            text: data.data.msg,
            icon: "error",
            buttons: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  };

  useEffect(() => {
    SweetAlerMessage();
    $("[data-height]").each(function() {
      $(this).css({
        height: $(this).data("height"),
      });
    });

    fetch(`${process.env.REACT_APP_BASE_URL}/api/game`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json().then((d) => ({ status: r.status, data: d.data })))
      .then((d) => {
        setGame(d.data);
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  }, []);

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charset="utf-8" />
        <meta name="description" content="Edit Bulk - Admin" />
        <meta
          name="google-site-verification"
          content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34="
        />
        <title>Edit Bulk - Admin</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Items</h1>
            <div className="section-header-breadcrumb">
              <div className="breadcrumb-item active">
                <Link to="/dashboard">Dashboard</Link>
              </div>
              <div className="breadcrumb-item">
                <Link to="/products/games">List Items</Link>
              </div>
              <div className="breadcrumb-item" id="title-game">
                Edit Bulk
              </div>
            </div>
          </div>

          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div
                      className="cursor-pointer d-flex"
                      onClick={handleGoBack}
                    >
                      <span>
                        <i className="fas fa-arrow-left"></i>
                      </span>
                      <h4 className="ml-2">Edit Bulk</h4>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="tab-content" id="pills-tabContent">
                      <form onSubmit={handleSubmitAuto}>
                        <div className="otomatis">
                          <Row className="align-items-center">
                            <Col xs={12}>
                              <div className="form-group">
                                <label>Game</label>
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setGameSelect(e.target.value);
                                    fetchProductsData(e.target.value);
                                  }}
                                >
                                  <option value="">Pilih Game</option>
                                  {game &&
                                    game.length > 0 &&
                                    game.map((item) => (
                                      <option key={item._id} value={item._id}>
                                        {item.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </Col>
                          </Row>
                          <div style={{ display: displayFormOtomatis.detail }}>
                            <Row>
                              <Table
                                striped
                                bordered
                                responsive
                                className="w-100 table-sm mx-4"
                              >
                                <thead>
                                  <tr className="fz-12">
                                    <th>No</th>
                                    <th>Kode</th>
                                    <th>Nama</th>
                                    <th>Harga dari Vendor</th>
                                    <th>Margin</th>
                                    <th>Tipe Margin</th>
                                    <th>Icon</th>
                                    <th>Kategori</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {listCode.length > 0 &&
                                    listCode.map((item, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                          <Form.Control
                                            size="sm"
                                            type="text"
                                            value={item.code}
                                            className="form-control fz-12"
                                            disabled
                                          />
                                        </td>
                                        <td>
                                          <Form.Control
                                            size="sm"
                                            type="text"
                                            onChange={(e) => {
                                              const updatedList = [...listCode];
                                              updatedList[index].name =
                                                e.target.value;
                                              setListCode(updatedList);
                                            }}
                                            value={item.name || ""}
                                            className="form-control fz-12"
                                          />
                                        </td>
                                        <td>
                                          <Form.Control
                                            size="sm"
                                            type="text"
                                            value={item.vendor_price}
                                            className="form-control fz-12"
                                            disabled
                                          />
                                        </td>
                                        <td>
                                          <Form.Control
                                            size="sm"
                                            type="text"
                                            className="form-control fz-12"
                                            onChange={(e) => {
                                              const updatedList = [...listCode];
                                              updatedList[index].margin =
                                                e.target.value;
                                              setListCode(updatedList);
                                            }}
                                            value={item.margin || ""}
                                          />
                                        </td>
                                        <td>
                                          <div className="form-group fz-12">
                                            <select
                                              className="form-control fz-12 p-1"
                                              onChange={(e) => {
                                                const updatedList = [
                                                  ...listCode,
                                                ];
                                                updatedList[index].margin_type =
                                                  e.target.value;
                                                setListCode(updatedList);
                                              }}
                                              value={item.margin_type || ""}
                                            >
                                              <option value="">
                                                Pilih Tipe
                                              </option>
                                              <option value="flat">Flat</option>
                                              <option value="percent">
                                                Percent
                                              </option>
                                            </select>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group">
                                            <div className="input-group mb-3 d-flex flex-column align-items-center justify-content-center">
                                              <img
                                                src={item.icon || ""}
                                                alt="icon"
                                                onChange={(e) => {
                                                  const updatedList = [
                                                    ...listCode,
                                                  ];
                                                  updatedList[index].icon =
                                                    e.target.value;
                                                  setListCode(updatedList);
                                                }}
                                                className="img-products"
                                              />
                                              <div className="input-group-append mt-2">
                                                <button
                                                  className="btn btn-sm btn-primary"
                                                  type="button"
                                                  onClick={() => {
                                                    handleShow();
                                                    setId(item._id);
                                                  }}
                                                >
                                                  Pilih File
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              className="form-control fz-12"
                                              onChange={(e) => {
                                                const updatedList = [
                                                  ...listCode,
                                                ];
                                                updatedList[index].category =
                                                  e.target.value;
                                                setListCode(updatedList);
                                              }}
                                              value={item.category || ""}
                                            />
                                            <span className="text-danger fz-12">
                                              Cth: Event Neobeast / Special
                                              Items / Top Up Instant / Gift
                                              Kharisma / dll
                                            </span>
                                          </div>
                                        </td>
                                        <td>
                                          <select
                                            className="form-control"
                                            onChange={(e) => {
                                              const updatedList = [...listCode];
                                              updatedList[index].status =
                                                e.target.value;
                                              setListCode(updatedList);
                                            }}
                                            value={item.status}
                                          >
                                            <option disabled>Pilih S</option>
                                            <option value={true}>Aktif</option>
                                            <option value={false}>
                                              Nonaktif
                                            </option>
                                          </select>
                                        </td>
                                        <td>
                                          <button
                                            type="button"
                                            className="btn btn-sm"
                                            onClick={handleDelete(item._id)}
                                          >
                                            <i className="text-danger fas fa-trash m-2"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </Table>
                            </Row>
                          </div>
                        </div>
                        <div className="card-footer text-right">
                          <button
                            className="btn btn-primary mr-1"
                            type="submit"
                          >
                            Submit
                          </button>
                          <Link
                            to="/products/items"
                            className="btn btn-secondary"
                            type="button"
                          >
                            Batal
                          </Link>
                        </div>
                      </form>
                    </div>
                    <ModalIcons
                      show={show}
                      handleClose={handleClose}
                      assets={assets}
                      assetsSelect={assetsSelect}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </HelmetProvider>
  );
};

export default EditBulk;
