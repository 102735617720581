import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SweetAlerMessage from "../../js/sweetalert";
import $ from "jquery";
import swal from "sweetalert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Col, Row } from "react-bootstrap";

const GlobalRate = () => {
  const [rate, setRate] = useState([]);

  const history = useHistory({
    forceRefresh: true,
  });

  const handleChange = (index, value) => {
    const updatedRate = [...rate];
    updatedRate[index].rate = value;
    setRate(updatedRate);
  };

  const handleSubmit = (e, index, code) => {
    e.preventDefault();

    const rateValue = parseFloat(rate[index].rate);

    fetch(`${process.env.REACT_APP_BASE_URL}/api/vendor/rate`, {
      method: "POST",
      body: JSON.stringify({
        token: localStorage.getItem("token"),
        rate: rateValue,
        code,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, data: data })))
      .then((data) => {
        if (data.status === 200) {
          swal({
            title: "Berhasil",
            text: data.data.message,
            icon: "success",
            buttons: false,
            timer: 1000,
          });
        } else {
          swal({
            title: "Gagal",
            text: data.data.message,
            icon: "error",
            buttons: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/vendor/rate`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json().then((d) => ({ status: r.status, data: d.data })))
      .then((d) => {
        setRate(d.data);
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: false,
        });
      });

    SweetAlerMessage();
  }, [history]);

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charset="utf-8" />
        <meta name="description" content="Global Rate - Admin" />
        <meta
          name="google-site-verification"
          content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34="
        />
        <title>Global Rate - Admin</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Global Rate</h1>
            <div className="section-header-breadcrumb">
              <div className="breadcrumb-item active">
                <Link to="/dashboard">Dashboard</Link>
              </div>
              <div className="breadcrumb-item">Global Rate</div>
            </div>
          </div>

          <div className="section-body">
            <div className="row">
              {rate.map((item, index) => (
                <div key={index} className="col-4">
                  <form
                    className="card"
                    onSubmit={(e) => handleSubmit(e, index, item.code)}
                  >
                    <div className="card-body">
                      <Row>
                        <Col key={index} xs={12}>
                          <div className="form-group">
                            <label>Rate {item.code}</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Masukkan Rate"
                              value={item.rate}
                              onChange={(e) =>
                                handleChange(index, e.target.value)
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                      <button className="btn btn-primary mr-1" type="submit">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </HelmetProvider>
  );
};

export default GlobalRate;
