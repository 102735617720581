import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import SweetAlerMessage from "../../js/sweetalert";
import swal from "sweetalert";
import { Helmet, HelmetProvider } from "react-helmet-async";

const AddVouchers = () => {
  const history = useHistory();
  const location = useLocation();

  const handleGoBack = () => {
    if (window.location.pathname === "/voucher/add-voucher") {
      history.goBack();
    } else {
         history.goBack();
    }
  };

  const [isEditing, setIsEditing] = useState(false);
  const [code, setCode] = useState("");
  const [discount, setDisc] = useState(null);
  const [type, setType] = useState("");
  const [minTrx, setMinTrx] = useState(null);
  const [maxDiscount, setMaxDisc] = useState(null);
  const [maxUsage, setMaxUsage] = useState(null);
  const [createdAt, setCreatedAt] = useState("");
  const [expiredDate, setExpiredDate] = useState("");

  const [url, setUrl] = useState(`${process.env.REACT_APP_BASE_URL}/api/voucher/create`);
  const [method, setMethod] = useState("POST");
  const [title, setTitle] = useState("Tambah Voucher");
  const [msg, setMsg] = useState("Voucher berhasil ditambahkan");

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(d.getDate()).padStart(2, "0");
    const hours = String(d.getHours()).padStart(2, "0");
    const minutes = String(d.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const requestBody = {
      code,
      discount,
      type,
      minTrx,
      maxDiscount,
      maxUsage,
      createdAt,
      expiredDate,
      token: localStorage.getItem("token"),
    };

    fetch(url, {
      method: method,
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, data: data })))
      .then((data) => {
        if (data.status === 200) {
          swal({
            title: "Berhasil",
            text: msg,
            icon: "success",
            buttons: false,
            timer: 1000,
          });
          if (window.location.pathname === "/voucher/add-voucher") {
            history.goBack();
          } else {
            history.goBack();
            history.goBack();
          }
        } else {
          swal({
            title: "Gagal",
            text: data.data.msg,
            icon: "error",
            buttons: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    SweetAlerMessage();
    $("[data-height]").each(function() {
      $(this).css({
        height: $(this).data("height"),
      });
    });

    if (window.location.pathname === "/vouchers/add-vouchers") {
      setUrl(`${process.env.REACT_APP_BASE_URL}/api/voucher/create`);
      setMethod("POST");
    } else {
      setUrl(`${process.env.REACT_APP_BASE_URL}/api/voucher/update/${window.location.pathname.split("/").pop()}`);
      setMethod("PUT");
      setIsEditing(true);

      fetch(`${process.env.REACT_APP_BASE_URL}/api/voucher?id=${window.location.pathname.split("/").pop()}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((r) => r.json().then((d) => ({ status: r.status, data: d.data })))
        .then((d) => {
          setCode(d.data.code);
          setDisc(d.data.discount);
          setType(d.data.type);
          setMinTrx(d.data.minTrx);
          setMaxDisc(d.data.maxDiscount);
          setMaxUsage(d.data.maxUsage);
          setCreatedAt(formatDate(d.data.createdAt));
          setExpiredDate(formatDate(d.data.expiredDate));
        })
        .catch((err) => {
          swal(err.message, {
            icon: "error",
            button: false,
          });
        });
      setTitle("Edit Voucher");
      setMsg("Voucher berhasil diubah");
    }
  }, [location.pathname]);

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charset="utf-8" />
        <meta name="description" content="Add Voucher - Admin" />
        <meta name="google-site-verification" content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34=" />
        <title>Add Voucher - Admin</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Voucher</h1>
            <div className="section-header-breadcrumb">
              <div className="breadcrumb-item active">
                <Link to="/dashboard">Dashboard</Link>
              </div>
              <div className="breadcrumb-item">
                <Link to="/voucher">List Voucher</Link>
              </div>
              <div className="breadcrumb-item" id="title-voucher">
                Add Voucher
              </div>
            </div>
          </div>

          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <form className="card" onSubmit={handleSubmit}>
                  <div className="card-header">
                    <div className="cursor-pointer d-flex" onClick={handleGoBack}>
                      <span>
                        <i className="fas fa-arrow-left"></i>
                      </span>
                      <h4 className="ml-2">{title}</h4>
                    </div>
                  </div>
                  <div className="card-body">
                    <Row>
                      <Col xs={12} lg={6}>
                        <div className="form-group">
                          <label>Kode</label>
                          <input type="text" className="form-control" onChange={(e) => setCode(e.target.value)} value={code} placeholder="Masukkan Kode" disabled={isEditing} />
                        </div>
                      </Col>
                      <Col xs={12} lg={6}>
                        <div className="form-group">
                          <label>Diskon</label>
                          <input type="number" className="form-control" onChange={(e) => setDisc(e.target.value)} value={discount} placeholder="Masukkan Diskon" />
                        </div>
                      </Col>
                      <Col xs={12} lg={6}>
                        <div className="form-group">
                          <label>Tipe</label>
                          <select className="form-control" onChange={(e) => setType(e.target.value)} value={type}>
                            <option selected>Pilih Tipe</option>
                            <option value="flat">Flat</option>
                            <option value="percent">Percent</option>
                          </select>
                        </div>
                      </Col>
                      <Col xs={12} lg={6}>
                        <div className="form-group">
                          <label>Minimal Pembelian</label>
                          <input type="number" className="form-control" onChange={(e) => setMinTrx(e.target.value)} value={minTrx} placeholder="Masukkan Minimal Transaksi" />
                        </div>
                      </Col>
                      <Col xs={12} lg={6}>
                        <div className="form-group">
                          <label>Maximal Diskon</label>
                          <input type="number" className="form-control" onChange={(e) => setMaxDisc(e.target.value)} value={maxDiscount} placeholder="Masukkan Max.Diskon" />
                        </div>
                      </Col>
                      <Col xs={12} lg={6}>
                        <div className="form-group">
                          <label>Max.Usage</label>
                          <input type="text" className="form-control" onChange={(e) => setMaxUsage(e.target.value)} value={maxUsage} placeholder="Masukkan Max.Usage" />
                        </div>
                      </Col>
                      <Col xs={12} lg={6}>
                        <div className="form-group">
                          <label>Start date</label>
                          <input type="datetime-local" className="form-control" onChange={(e) => setCreatedAt(e.target.value)} value={createdAt} placeholder="Masukkan Created At" />
                        </div>
                      </Col>
                      <Col xs={12} lg={6}>
                        <div className="form-group">
                          <label>End date</label>
                          <input type="datetime-local" className="form-control" onChange={(e) => setExpiredDate(e.target.value)} value={expiredDate} placeholder="Masukkan expiredDate" />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="card-footer text-right">
                    <button className="btn btn-primary mr-1" type="submit">
                      Submit
                    </button>
                    <Link to="/voucher" className="btn btn-secondary" type="button">
                      Batal
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </HelmetProvider>
  );
};

export default AddVouchers;
