import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import SweetAlerMessage from "../../js/sweetalert";
import swal from "sweetalert";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "select2/dist/css/select2.min.css";
import "select2";

const AddEvents = () => {
  const history = useHistory();
  const location = useLocation();

  const handleGoBack = () => {
    if (window.location.pathname === "/event/add-event") {
      history.goBack();
    } else {
         history.goBack();
    }
  };

  const [gameTitle, setGameTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const selectRef = useRef(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [products, setProducts] = useState([]);

  const [url, setUrl] = useState(`${process.env.REACT_APP_BASE_URL}/api/event/create`);
  const [method, setMethod] = useState("POST");
  const [title, setTitle] = useState("Tambah Event");
  const [msg, setMsg] = useState("Event berhasil ditambahkan");
  const [formValues, setFormValues] = useState([{ discount: "", type: "", product: "" }]);

  const discRef = useRef([]);
  const typeRef = useRef([]);

  const formatDate = (date) => {
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, "0");
    const day = `${d.getDate()}`.padStart(2, "0");
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index][name] = value;
      return newValues;
    });
  };
  const addFormFields = () => {
    setFormValues((prevValues) => [...prevValues, { discount: "", type: "", product: "" }]);
  };

  const removeFormFields = (index) => {
    setFormValues((prevValues) => prevValues.filter((_, i) => i !== index));
  };

  const handleSelectChange = () => {
    const selectedOptions = $(selectRef.current).val();
    setSelectedProducts(selectedOptions);
  };

  useEffect(() => {
    const $select = $(selectRef.current);

    $select
      .select2({
        placeholder: "--Pilih--",
        dropdownAutoWidth: true,
        width: "100%",
        dropdownParent: $select.parent(),
        ajax: {
          delay: 500,
          url: `${process.env.REACT_APP_BASE_URL}/api/product/select`,
          dataType: "json",
          data: function(params) {
            return {
              search: params.term || "",
            };
          },
          processResults: function(d) {
            const final = d.data.map((t) => {
              return { id: t.code, text: `${t.name} (${t.game.name})` };
            });
            return { results: final };
          },
        },
      })
      .on("select2:select", handleSelectChange);
    setTimeout(() => {
      if (selectedProducts.length > 0) {
        $select.val(selectedProducts).trigger("change");
      }
    }, 1000);
    // Cleanup function to destroy select2 instance
    return () => {
      $select.select2("destroy");
    };
  }, [selectedProducts]);

  // useEffect(() => {
  //   // Assuming this is your API call to get the data
  //   fetch(`${process.env.REACT_APP_BASE_URL}/api/event?id=${window.location.pathname.split("/").pop()}`)
  //     .then((r) => r.json())
  //     .then((d) => {
  //       const productCodes = d.data.products.map((prod) => prod.code);
  //       setSelectedProducts(productCodes);
  //     })
  //     .catch((err) => {
  //       console.error("Error fetching data:", err);
  //     });
  // }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const requestBody = {
      title: gameTitle,
      description: desc,
      startDate,
      endDate,
      products: formValues.map((value, index) => {
        return {
          discount: discRef.current[index].value,
          type: typeRef.current[index].value,
          product: selectedProducts[index],
        };
      }),
      token: localStorage.getItem("token"),
    };

    fetch(url, {
      method: method,
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, data: data })))
      .then((data) => {
        if (data.status === 200) {
          swal({
            title: "Berhasil",
            text: msg,
            icon: "success",
            buttons: false,
            timer: 1000,
          });
          if (window.location.pathname === "/event/add-event") {
            history.goBack();
          } else {
            history.goBack();
            history.goBack();
          }
        } else {
          swal({
            title: "Gagal",
            text: data.data.msg,
            icon: "error",
            buttons: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    SweetAlerMessage();
    $("[data-height]").each(function() {
      $(this).css({
        height: $(this).data("height"),
      });
    });

    if (window.location.pathname === "/events/add-events") {
      setUrl(`${process.env.REACT_APP_BASE_URL}/api/event/create`);
      setMethod("POST");
    } else {
      setUrl(`${process.env.REACT_APP_BASE_URL}/api/event/update/${window.location.pathname.split("/").pop()}`);
      setMethod("PUT");

      fetch(`${process.env.REACT_APP_BASE_URL}/api/event?id=${window.location.pathname.split("/").pop()}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((r) => r.json().then((d) => ({ status: r.status, data: d.data })))
        .then((d) => {
          setGameTitle(d.data.title);
          setDesc(d.data.description);
          setStartDate(formatDate(d.data.startDate));
          setEndDate(formatDate(d.data.endDate));
          const productCodes = d.data.products.map((prod) => prod.code);
          setProducts(productCodes);
          setSelectedProducts(productCodes);
          setFormValues(
            d.data.products.map((prod) => ({
              discount: prod.discount,
              type: prod.type,
              product: prod.code,
            }))
          );
        })
        .catch((err) => {
          swal(err.message, {
            icon: "error",
            button: false,
          });
        });
      setTitle("Edit Event");
      setMsg("Event berhasil diubah");
    }
  }, [location.pathname]);

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charset="utf-8" />
        <meta name="description" content="Add Event - Admin" />
        <meta name="google-site-verification" content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34=" />
        <title>Add Event - Admin</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Event</h1>
            <div className="section-header-breadcrumb">
              <div className="breadcrumb-item active">
                <Link to="/dashboard">Dashboard</Link>
              </div>
              <div className="breadcrumb-item">
                <Link to="/event">List Event</Link>
              </div>
              <div className="breadcrumb-item" id="title-event">
                Add Event
              </div>
            </div>
          </div>

          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <form className="card" onSubmit={handleSubmit}>
                  <div className="card-header">
                    <div className="cursor-pointer d-flex" onClick={handleGoBack}>
                      <span>
                        <i className="fas fa-arrow-left"></i>
                      </span>
                      <h4 className="ml-2">{title}</h4>
                    </div>
                  </div>
                  <div className="card-body">
                    <Row className="mb-5">
                      <Col xs={12} lg={6}>
                        <div className="form-group">
                          <label>Title</label>
                          <input type="text" className="form-control" onChange={(e) => setGameTitle(e.target.value)} value={gameTitle} placeholder="Masukkan Title" />
                        </div>
                      </Col>
                      <Col xs={12} lg={6}>
                        <div className="form-group">
                          <label>Deskripsi</label>
                          <input type="text" className="form-control" onChange={(e) => setDesc(e.target.value)} value={desc} placeholder="Masukkan Deskripsi" />
                        </div>
                      </Col>
                      <Col xs={12} lg={6}>
                        <div className="form-group">
                          <label>Start Date</label>
                          <input type="date" className="form-control" onChange={(e) => setStartDate(e.target.value)} value={startDate} placeholder="Masukkan Start Date" />
                        </div>
                      </Col>
                      <Col xs={12} lg={6}>
                        <div className="form-group">
                          <label>End Date</label>
                          <input type="date" className="form-control" onChange={(e) => setEndDate(e.target.value)} value={endDate} placeholder="Masukkan End Date" />
                        </div>
                      </Col>
                      <Col xs={12} lg={12}>
                        <label>Pilih Produk</label>
                        <select className="select2 multiple" multiple="multiple" onChange={(e) => setProducts(e.target.value)} value={products} name="products" ref={selectRef}>
                          <option value=""></option>
                        </select>
                      </Col>
                    </Row>
                    {formValues.map((element, index) => (
                      <Row key={index} className="bg-light py-3 mb-3">
                        <Col md={3}>
                          <div className="form-group">
                            <label>Discount</label>
                            <input
                              ref={(ref) => {
                                discRef.current[index] = ref;
                              }}
                              name="discount"
                              type="number"
                              className="form-control"
                              onChange={(e) => handleChange(index, e)}
                              value={element.discount}
                              placeholder="Discount"
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="form-group">
                            <label>Type</label>
                            <select
                              className="form-control"
                              ref={(ref) => {
                                typeRef.current[index] = ref;
                              }}
                              name="type"
                              onChange={(e) => handleChange(index, e)}
                              value={element.type}
                              placeholder="Type"
                            >
                              <option selected>Pilih Tipe</option>
                              <option value="flat">Flat</option>
                              <option value="percent">Percent</option>
                            </select>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="form-group">
                            <label>Produk</label>
                            <select className="form-control" name="product" onChange={(e) => handleChange(index, e)} value={element.product}>
                              <option value="">Pilih Produk</option>
                              {selectedProducts.map((product) => (
                                <option key={product} value={product}>
                                  {product}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col md={2}>
                          {index ? (
                            <Button className="button add btnRemove" variant="danger" type="button" onClick={() => removeFormFields(index)}>
                              x
                            </Button>
                          ) : null}
                        </Col>
                      </Row>
                    ))}
                    <Button className="my-3 button add" variant="primary" type="button" onClick={() => addFormFields()}>
                      +
                    </Button>
                  </div>
                  <div className="card-footer text-right">
                    <button className="btn btn-primary mr-1" type="submit">
                      Submit
                    </button>
                    <Link to="/event" className="btn btn-secondary" type="button">
                      Batal
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </HelmetProvider>
  );
};

export default AddEvents;
