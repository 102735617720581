import React, { useEffect } from "react";
import ModuleLogAccess from "../../js/ModuleLogAccess";
import ModuleLogCallback from "../../js/ModuleLogCallback";
import ModuleLogError from "../../js/ModuleLogError";
import ModuleLogGeneral from "../../js/ModuleLogGeneral";
import ModuleLogLogin from "../../js/ModuleLogLogin";
import { Link } from "react-router-dom";
import SweetAlerMessage from "../../js/sweetalert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { LogsJs } from "./logs";

const Logs = () => {
  const history = useHistory();

  useEffect(() => {
    LogsJs();
    ModuleLogAccess();
    ModuleLogCallback();
    ModuleLogError();
    ModuleLogGeneral();
    ModuleLogLogin();
    SweetAlerMessage();
  }, [history]);

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charset="utf-8" />
        <meta name="description" content="Vendors - Admin" />
        <meta
          name="google-site-verification"
          content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34="
        />
        <title>Logs - Admin</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Logs</h1>
            <div className="section-header-breadcrumb">
              <div className="breadcrumb-item active">
                <Link to="/dashboard">Dashboard</Link>
              </div>
              <div className="breadcrumb-item">List Vendors</div>
            </div>
          </div>

          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item mr-3">
              <a
                className="nav-link active"
                id="pills-logs-access-tab"
                data-toggle="pill"
                href="#pills-logs-access"
                role="tab"
                aria-controls="pills-logs-access"
                aria-selected="true"
                data-idtable="#logs-access-table"
              >
                Logs Access
              </a>
            </li>
            <li className="nav-item mr-3">
              <a
                className="nav-link"
                id="pills-logs-callback-tab"
                data-toggle="pill"
                href="#pills-logs-callback"
                role="tab"
                aria-controls="pills-logs-callback"
                aria-selected="false"
              >
                Log Callback
              </a>
            </li>
            <li className="nav-item mr-3">
              <a
                className="nav-link"
                id="pills-logs-error-tab"
                data-toggle="pill"
                href="#pills-logs-error"
                role="tab"
                aria-controls="pills-logs-error"
                aria-selected="false"
              >
                Log Error
              </a>
            </li>
            <li className="nav-item mr-3">
              <a
                className="nav-link"
                id="pills-logs-general-tab"
                data-toggle="pill"
                href="#pills-logs-general"
                role="tab"
                aria-controls="pills-logs-general"
                aria-selected="false"
              >
                Log General
              </a>
            </li>
            <li className="nav-item mr-3">
              <a
                className="nav-link"
                id="pills-logs-login-tab"
                data-toggle="pill"
                href="#pills-logs-login"
                role="tab"
                aria-controls="pills-logs-login"
                aria-selected="false"
              >
                Log Login
              </a>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-logs-access"
              role="tabpanel"
              aria-labelledby="pills-logs-access-tab"
            >
              <div className="section-body">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header d-flex justify-content-between">
                        <h4>List Logs Access</h4>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="w-100 table table-striped"
                            id="logs-access-table"
                          >
                            <thead>
                              <tr>
                                <th>Waktu</th>
                                <th>Jenis</th>
                                <th>Pesan</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-logs-callback"
              role="tabpanel"
              aria-labelledby="pills-logs-callback-tab"
            >
              <div className="section-body">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header d-flex justify-content-between">
                        <h4>List Logs Callback</h4>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="w-100 table table-striped"
                            id="logs-callback-table"
                          >
                            <thead>
                              <tr>
                                <th>Waktu</th>
                                <th>Jenis</th>
                                <th>Pesan</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-logs-error"
              role="tabpanel"
              aria-labelledby="pills-logs-error-tab"
            >
              <div className="section-body">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header d-flex justify-content-between">
                        <h4>List Logs Error</h4>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="w-100 table table-striped"
                            id="logs-error-table"
                          >
                            <thead>
                              <tr>
                                <th>Waktu</th>
                                <th>Jenis</th>
                                <th>Pesan</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-logs-general"
              role="tabpanel"
              aria-labelledby="pills-logs-general-tab"
            >
              <div className="section-body">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header d-flex justify-content-between">
                        <h4>List Logs General</h4>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="w-100 table table-striped"
                            id="logs-general-table"
                          >
                            <thead>
                              <tr>
                                <th>Waktu</th>
                                <th>Jenis</th>
                                <th>Pesan</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-logs-login"
              role="tabpanel"
              aria-labelledby="pills-logs-login-tab"
            >
              <div className="section-body">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header d-flex justify-content-between">
                        <h4>List Logs Login</h4>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="w-100 table table-striped"
                            id="logs-login-table"
                          >
                            <thead>
                              <tr>
                                <th>Waktu</th>
                                <th>Jenis</th>
                                <th>Pesan</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </HelmetProvider>
  );
};

export default Logs;
