import React, { useEffect, useState } from "react";
import { ChartGeneral } from "../Dashboard/ChartGeneral";
import moment from "moment";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import swal from "sweetalert";

const DashboardTenants = () => {
  const history = useHistory();
  const location = useLocation();
  const handleGoBack = () => {
    if (window.location.pathname === "/tenants/add-tenants") {
      history.goBack();
    } else {
         history.goBack();
    }
  };

  const [state, setState] = useState({
    trx: {
      today: {
        success: 0,
        pending: 0,
        failed: 0,
        expired: 0,
        total: 0,
      },
      yesterday: {
        success: 0,
        pending: 0,
        failed: 0,
        expired: 0,
        total: 0,
      },
      thisMonth: {
        success: 0,
        pending: 0,
        failed: 0,
        expired: 0,
        total: 0,
      },
      lastMonth: {
        success: 0,
        pending: 0,
        failed: 0,
        expired: 0,
        total: 0,
      },
    },
    omset: {
      today: 0,
      week: 0,
      lastMonth: 0,
      thisMonth: 0,
      thisYear: 0,
    },
    profit: {
      today: 0,
      week: 0,
      lastMonth: 0,
      thisMonth: 0,
      thisYear: 0,
    },
    fetched: false,
  });

  function resetTrxState() {
    state.trx = {
      today: {
        success: 0,
        pending: 0,
        failed: 0,
        expired: 0,
        total: 0,
      },
      yesterday: {
        success: 0,
        pending: 0,
        failed: 0,
        expired: 0,
        total: 0,
      },
      thisMonth: {
        success: 0,
        pending: 0,
        failed: 0,
        expired: 0,
        total: 0,
      },
      lastMonth: {
        success: 0,
        pending: 0,
        failed: 0,
        expired: 0,
        total: 0,
      },
    };
    state.omset = {
      today: 0,
      week: 0,
      lastMonth: 0,
      thisMonth: 0,
      thisYear: 0,
    };
    state.profit = {
      today: 0,
      week: 0,
      lastMonth: 0,
      thisMonth: 0,
      thisYear: 0,
    };
    return state;
  }

  function formatRP(margin) {
    const formatted = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 2,
    }).format(Math.round(margin));

    return formatted.replace(",00", "");
  }

  function fetchDataTrx() {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/tenant/dashboard/super`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let weeks = [];
        let tempState = resetTrxState();
        data.data.forEach((t) => {
          weeks = weeks.concat(t.data.week);
          for (let d in t.data) {
            if (t.data[d].length !== 0) { 
              t.data[d].forEach((trx) => {
                if (tempState.trx[d] !== undefined) {
                  tempState.trx[d][trx.status.toLowerCase()]++;
                  tempState.trx[d].total++;
                }
                if (tempState.omset[d] !== undefined) {
                  if (trx.status.toLowerCase() == "success") {
                    tempState.omset[d] =
                      tempState.omset[d] + trx.gross_total_payment;
                  }
                }
                if (tempState.profit[d] !== undefined) {
                  if (trx.status.toLowerCase() === "success") {
                    tempState.profit[d] =
                      tempState.profit[d] +
                      parseInt(trx.margin - trx.vendor_price);
                  }
                }
              });
            }
          }
        });
        ChartGeneral(weeks);
        setState({ ...tempState, fetched: true });
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    fetchDataTrx();
  }, [location.pathname]);

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charset="utf-8" />
        <meta name="description" content="Dashboard - Admin" />
        <meta
          name="google-site-verification"
          content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34="
        />
        <title>Dashboard of All Tenants - Admin</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <div className="cursor-pointer d-flex" onClick={handleGoBack}>
              <span>
                <i className="fas fa-arrow-left"></i>
              </span>
            </div>
            <h1>Dashboard of All Tenants</h1>
          </div>
          <div className="row">
            <div className="col-xl-3 col-md-6 col-12">
              <div className="card card-statistic-1 d-flex p-2">
                <div className="card-icon bg-primary ml-4 mx-auto">
                  <i className="far fa-user"></i>
                </div>
                <div className="card-wrap">
                  <div className="card-header py-2 text-center">
                    <h4>Transaksi kemarin</h4>
                  </div>
                  <div className="text-center">
                    <h6 className="text-center">
                      Total: {state.trx.yesterday.total}
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <div>
                        <div>
                          <span>{state.trx.yesterday.success} </span>
                          <span className="fz-13 text-success">sukses</span>
                        </div>
                        <div>
                          <span>{state.trx.yesterday.pending} </span>
                          <span className="fz-13 text-danger">pending</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <span>{state.trx.yesterday.failed} </span>
                          <span className="fz-13 text-danger">failed</span>
                        </div>
                        <div>
                          <span>{state.trx.yesterday.expired} </span>
                          <span className="fz-13 text-danger">expired</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-12">
              <div className="card card-statistic-1 d-flex p-2">
                <div className="card-icon bg-danger ml-4 mx-auto">
                  <i className="far fa-newspaper"></i>
                </div>
                <div className="card-wrap">
                  <div className="card-header py-2 text-center">
                    <h4>Transaksi hari ini</h4>
                  </div>
                  <div className="text-center">
                    <h6 className="text-center">
                      Total: {state.trx.today.total}
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <div>
                        <div>
                          <span>{state.trx.today.success} </span>
                          <span className="fz-13 text-success">sukses</span>
                        </div>
                        <div>
                          <span>{state.trx.today.pending} </span>
                          <span className="fz-13 text-danger">pending</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <span>{state.trx.today.failed} </span>
                          <span className="fz-13 text-danger">failed</span>
                        </div>
                        <div>
                          <span>{state.trx.today.expired} </span>
                          <span className="fz-13 text-danger">expired</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-12">
              <div className="card card-statistic-1 d-flex p-2">
                <div className="card-icon bg-warning ml-4 mx-auto">
                  <i className="far fa-file"></i>
                </div>
                <div className="card-wrap">
                  <div className="card-header py-2 text-center">
                    <h4>Transaksi bulan kemarin</h4>
                  </div>
                  <div className="text-center">
                    <h6 className="text-center">
                      Total: {state.trx.lastMonth.total}
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <div>
                        <div>
                          <span>{state.trx.lastMonth.success} </span>
                          <span className="fz-13 text-success">sukses</span>
                        </div>
                        <div>
                          <span>{state.trx.lastMonth.pending} </span>
                          <span className="fz-13 text-danger">pending</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <span>{state.trx.lastMonth.failed} </span>
                          <span className="fz-13 text-danger">failed</span>
                        </div>
                        <div>
                          <span>{state.trx.lastMonth.expired} </span>
                          <span className="fz-13 text-danger">expired</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-12">
              <div className="card card-statistic-1 d-flex p-2">
                <div className="card-icon bg-success ml-4 mx-auto">
                  <i className="fas fa-circle"></i>
                </div>
                <div className="card-wrap">
                  <div className="card-header py-2 text-center">
                    <h4>Transaksi bulan ini</h4>
                  </div>
                  <div className="text-center">
                    <h6 className="text-center">
                      Total: {state.trx.thisMonth.total}
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <div>
                        <div>
                          <span>{state.trx.thisMonth.success} </span>
                          <span className="fz-13 text-success">sukses</span>
                        </div>
                        <div>
                          <span>{state.trx.thisMonth.pending} </span>
                          <span className="fz-13 text-danger">pending</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <span>{state.trx.thisMonth.failed} </span>
                          <span className="fz-13 text-danger">failed</span>
                        </div>
                        <div>
                          <span>{state.trx.thisMonth.expired} </span>
                          <span className="fz-13 text-danger">expired</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12 col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h4>Statistics</h4>
                </div>
                <div className="card-body">
                  <canvas id="myChartTrx" height="150"></canvas>
                  <div className="statistic-details mt-sm-4">
                    <div className="statistic-details-item">
                      <div className="detail-value">
                        {formatRP(state.omset.today)}
                      </div>
                      <div className="detail-name">Omset Hari Ini</div>
                    </div>
                    <div className="statistic-details-item">
                      <div className="detail-value">
                        {formatRP(state.omset.week)}
                      </div>
                      <div className="detail-name">Omset Minggu Ini</div>
                    </div>
                    <div className="statistic-details-item">
                      <div className="detail-value">
                        {formatRP(state.omset.lastMonth)}
                      </div>
                      <div className="detail-name">Omset Bulan Kemarin</div>
                    </div>
                    <div className="statistic-details-item">
                      <div className="detail-value">
                        {formatRP(state.omset.thisMonth)}
                      </div>
                      <div className="detail-name">Omset Bulan Ini</div>
                    </div>
                    <div className="statistic-details-item">
                      <div className="detail-value">
                        {formatRP(state.omset.thisYear)}
                      </div>
                      <div className="detail-name">Omset Tahun Ini</div>
                    </div>
                  </div>
                  <div className="statistic-details mt-sm-4">
                    <div className="statistic-details-item">
                      <div className="detail-value">
                        {formatRP(state.profit.today)}
                      </div>
                      <div className="detail-name">Profit Hari Ini</div>
                    </div>
                    <div className="statistic-details-item">
                      <div className="detail-value">
                        {formatRP(state.profit.week)}
                      </div>
                      <div className="detail-name">Profit Minggu Ini</div>
                    </div>
                    <div className="statistic-details-item">
                      <div className="detail-value">
                        {formatRP(state.profit.lastMonth)}
                      </div>
                      <div className="detail-name">Profit Bulan Kemarin</div>
                    </div>
                    <div className="statistic-details-item">
                      <div className="detail-value">
                        {formatRP(state.profit.thisMonth)}
                      </div>
                      <div className="detail-name">Profit Bulan Ini</div>
                    </div>
                    <div className="statistic-details-item">
                      <div className="detail-value">
                        {formatRP(state.profit.thisYear)}
                      </div>
                      <div className="detail-name">Profit Tahun Ini</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-12 col-sm-12 d-none">
              <div className="card">
                <div className="card-header">
                  <h4>Recent Activities</h4>
                </div>
                <div className="card-body">
                  <ul className="list-unstyled list-unstyled-border">
                    {[].slice(0, 5).map((item, index) => (
                      <li
                        key={index}
                        className="media d-flex align-items-center"
                      >
                        <img
                          className="mr-3 rounded-circle"
                          width="50"
                          src="https://s3-id-jkt-1.kilatstorage.id/assets.mobacash.com/profile_1688469098092.webp"
                          alt="avatar"
                        />
                        <div className="media-body">
                          <div className="float-right text-primary">
                            {item.status}
                          </div>
                          <div className="media-title">
                            {item.customer.phone}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div className="text-center pt-1 pb-1">
                    <Link
                      to="/transactions"
                      className="btn btn-primary btn-lg btn-round"
                    >
                      View All
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </HelmetProvider>
  );
};

export default DashboardTenants;
