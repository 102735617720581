import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import ModuleTenants from "../../js/ModuleTenants";
import { Link } from "react-router-dom";
import SweetAlerMessage from "../../js/sweetalert";
import $ from "jquery";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Tenants = () => {
  const history = useHistory();

  useEffect(() => {
    ModuleTenants();
    SweetAlerMessage();

    $("#tenants-table").on("click", ".btnDetail", function() {
      const database = $(this).data("database");
      history.push(`/tenants/dashboard?db=${database}`);
    });

    $("#tenants-table").on("click", ".btnEdit", function() {
      const id = $(this).data("id");
      history.push(`/tenants/add-tenants/${id}`);
    });
  }, [history]);

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charset="utf-8" />
        <meta name="description" content="Vendors - Admin" />
        <meta
          name="google-site-verification"
          content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34="
        />
        <title>Tenants - Admin</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Tenants</h1>
            <div className="section-header-breadcrumb">
              <div className="breadcrumb-item active">
                <Link to="/dashboard">Dashboard</Link>
              </div>
              <div className="breadcrumb-item">List Tenants</div>
            </div>
          </div>

          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-between">
                    <h4>List Tenants</h4>
                    <div className="text-end">
                      <Link to="/tenants/dashboard/all">
                        <Button className="create-new">
                          <i className="fas fa-plus me-sm-1 text-light"></i>{" "}
                          <span className="d-none d-sm-inline-block text-light">
                            Dashboard Semua Tenant
                          </span>
                        </Button>
                      </Link>
                      <Link to="/tenants/add-tenants">
                        <Button className="create-new">
                          <i className="fas fa-plus me-sm-1 text-light"></i>{" "}
                          <span className="d-none d-sm-inline-block text-light">
                            Tambah Tenant
                          </span>
                        </Button>
                      </Link>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped" id="tenants-table">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Nama</th>
                            <th>Domain</th>
                            <th>Opsi</th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </HelmetProvider>
  );
};

export default Tenants;
