import $ from "jquery";
import swal from "sweetalert";

function setUrl(key, value) {
  const url = new URLSearchParams(window.location.search);
  url.set(key, value);
  return url.toString();
}

export default function ModuleTenants() {
  if (!$.fn.DataTable.isDataTable("#tenants-table")) {
    let url = new URLSearchParams(window.location.search);
    let page = url.get("page") || 1;
    let length = url.get("length") || 10;
    let query = url.get("query") || "";
    $("#tenants-table").dataTable({
      processing: true,
      serverSide: true,
      searchDelay: 500,
      search: {
        search: query,
      },
      ajax: {
        type: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/api/tenant/datatable`,
        dataSrc: "data",
      },
      columns: [
        { data: "_id" },
        { data: "name" },
        { data: "domain" },
        { data: "" },
      ],
      columnDefs: [
        { className: "text-center", targets: "_all" },
        { targets: [1, 2, 3, -1], orderable: false },
        {
          targets: 0,
          render: function(data, type, row, meta) {
            return meta.row + meta.settings._iDisplayStart + 1;
          },
        },
        {
          targets: -1,
          title: "Actions",
          render: function(data, type, row, meta) {
            return `<a href="javascript:;" class="btnDetail" data-database="${row["database"]}"><i class="text-primary fas fa-eye m-2"></i></a><a href="javascript:;" class="btnEdit" data-id="${row["_id"]}"><i class="text-primary fas fa-edit m-2"></i></a>`;
          },
        },
      ],
      displayLength: length,
      displayStart: parseInt(page) * length - length,
      lengthMenu: [5, 10, 25, 50, 100, 1000],
    });
  }

  $("#tenants-table tbody").on("click", ".btnDel", function() {
    let id = $(this).data("id");
    let currentPage = $("#tenants-table")
      .DataTable()
      .page.info().page;

    swal({
      title: "Are you sure?",
      text: "Setelah dihapus, Anda tidak akan dapat memulihkan file ini!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("File Anda telah dihapus!", {
          icon: "success",
          timer: 1000,
          button: false,
        });
        $.ajax({
          method: "DELETE",
          url: `${
            process.env.REACT_APP_BASE_URL
          }/api/tenant/${id}?token=${localStorage.getItem("token")}`,
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
          success: function(d, x, r) {
            if (r.status === 200) {
              let url = setUrl("page", currentPage + 1);
              window.history.pushState(
                { path: `/tenants/?${url}` },
                "",
                `/tenants/?${url}`
              );

              $("#tenants-table")
                .DataTable()
                .ajax.reload(null, false);
            }
          },
        });
      }
    });
  });

  $(document).on("click", ".paginate_button", function() {
    let p = $(this).text();
    if (!parseInt(p)) return;
    let url = setUrl("page", p);
    window.history.pushState(
      { path: `/tenants/?${url}` },
      "",
      `/tenants/?${url}`
    );
  });

  $(document).on("change", "select[name=tenants-table_length]", function() {
    let l = $(this).val();
    let url = setUrl("length", l);
    window.history.pushState(
      { path: `/tenants/?${url}` },
      "",
      `/tenants/?${url}`
    );
  });

  $(document).on("keyup", "input[type=search]", function() {
    let q = $(this).val();
    let url = setUrl("query", q);
    window.history.pushState(
      { path: `/tenants/?${url}` },
      "",
      `/tenants/?${url}`
    );
  });
}
