import React from "react";

export default function Footer() {
  return (
    <footer className="main-footer">
      <div className="footer-left">
        Copyright & copy; 2023 <div className="bullet" /> Design By{" "}
        <a href="/"> egdev.id </a>{" "}
      </div>{" "}
      {/* <div className="footer-right"> 2.3 .0 </div>{" "} */}
    </footer>
  );
}
