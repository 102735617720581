import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import ModuleVouchers from "../../js/ModuleVouchers";
import { Link } from "react-router-dom";
import SweetAlerMessage from "../../js/sweetalert";
import $ from "jquery";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Helmet, HelmetProvider } from "react-helmet-async";

const ListVouchers = () => {
  const history = useHistory();

  useEffect(() => {
    ModuleVouchers();
    SweetAlerMessage();

    $("#vouchers-table").on("click", ".btnEdit", function() {
      const id = $(this).data("id");
      history.push(`/vouchers/add-vouchers/${id}`);
    });
  }, [history]);

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charset="utf-8" />
        <meta name="description" content="Vouchers - Admin" />
        <meta name="google-site-verification" content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34=" />
        <title>Voucher - Admin</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Vouchers</h1>
            <div className="section-header-breadcrumb">
              <div className="breadcrumb-item active">
                <Link to="/dashboard">Dashboard</Link>
              </div>
              <div className="breadcrumb-item">List Vouchers</div>
            </div>
          </div>

          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-between">
                    <h4>List Vouchers</h4>
                    <Link to="/vouchers/add-vouchers">
                      <Button className="create-new">
                        <i className="fas fa-plus me-sm-1 text-light"></i> <span className="d-none d-sm-inline-block text-light">Tambah Voucher</span>
                      </Button>
                    </Link>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped" id="vouchers-table">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Kode</th>
                            <th>Diskon</th>
                            <th>Minimal</th>
                            <th>maxDiscount</th>
                            <th>Usage</th>
                            <th>Max.Usage</th>
                            <th>Start</th>
                            <th>End</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </HelmetProvider>
  );
};

export default ListVouchers;
