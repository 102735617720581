import React, { useEffect } from "react";
import $ from "jquery";
import swal from "sweetalert";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Login = () => {
  let history = useHistory();

  const redirect = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/login`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) =>
        r.json().then((data) => ({ status: r.status, url: data.url }))
      )
      .then((data) => {
        if (data.status === 200) {
          window.location.href = data.url;
        } else {
          swal({
            title: "Gagal",
            text: "Gagal Login",
            icon: "error",
            buttons: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  };

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      history.push("/dashboard");
    }
  }, [history]);

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charset="utf-8" />
        <meta name="description" content="Login - Admin" />
        <meta
          name="google-site-verification"
          content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34="
        />
        <title>Login - Admin</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div id="app">
        <section className="section mt-5 pt-5">
          <div className="container mt-5">
            <div className="row">
              <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                <div className="card card-primary">
                  <div className="card-header">
                    <h4>Login</h4>
                  </div>

                  <div className="card-body">
                    <form
                      method="POST"
                      action="#"
                      noValidate
                      className="needs-validation"
                    >
                      <div className="form-group">
                        <button
                          type="button"
                          className="btn btn-light btn-lg btn-block"
                          tabIndex="4"
                          onClick={redirect}
                        >
                          <img
                            src={`${process.env.REACT_APP_STATIC_SRC}/icon-google_1688374124119.webp`}
                            alt="google"
                            width="20"
                          />{" "}
                          Login with google
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </HelmetProvider>
  );
};

export default Login;
