import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "../../../js/js/scripts";
import swal from "sweetalert";
import $ from "jquery";
import Data from "./data";

export default function SideBar() {
  const [logo, setLogo] = useState("");

  $(".list-single").on("click", function() {
    $(".list-dropdown").removeClass("active");
    $(".dropdown-menu").css("display", "none");
  });

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/setting`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) =>
        r.json().then((data) => ({ status: r.status, data: data.data }))
      )
      .then((data) => {
        setLogo(data.data[0].logo);
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  }, []);
  return (
    <div className="main-sidebar">
      <aside id="sidebar-wrapper">
        <div className="sidebar-brand">
          <img className="logo-custom" src={logo} alt="logo" />
        </div>
        <div className="sidebar-brand sidebar-brand-sm">
          <img className="logo-collapse" src={logo} alt="logo" />
        </div>
        <ul className="sidebar-menu mb-5">
          {Data
            ? Data.map((menu, iMenu) => {
                let comp;
                if (menu.dropdown) {
                  comp = (
                    <li key={iMenu} className="list-dropdown">
                      <a href="#" className="nav-link has-dropdown">
                        <i className={menu.icon} /> <span> {menu.name} </span>
                      </a>
                      <ul className="dropdown-menu">
                        {menu.children.map((submenu, iSubmenu) => {
                          let subComp;
                          subComp = (
                            <li key={iSubmenu}>
                              <NavLink
                                activeStyle={{
                                  color: " #6777ef",
                                  fontWeight: "600",
                                }}
                                exact
                                to={submenu.url}
                              >
                                {submenu.name}
                              </NavLink>
                            </li>
                          );

                          return subComp;
                        })}
                      </ul>
                    </li>
                  );
                } else if (menu.header) {
                  comp = (
                    <li key={iMenu} className="menu-header">
                      {menu.name}
                    </li>
                  );
                } else {
                  comp = (
                    <li className="list-single" key={iMenu}>
                      <NavLink
                        activeStyle={{
                          color: " #6777ef",
                          fontWeight: "600",
                        }}
                        exact
                        to={menu.url}
                      >
                        <i className={menu.icon} /> <span> {menu.name} </span>
                      </NavLink>
                    </li>
                  );
                }

                return comp;
              })
            : []}
        </ul>
      </aside>
    </div>
  );
}
