import $ from "jquery";
import swal from "sweetalert";
import moment from "moment";

function setUrl(key, value) {
  const url = new URLSearchParams(window.location.search);
  url.set(key, value);
  return url.toString();
}
function formatRP(margin) {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 2,
  }).format(Math.round(margin));
}

export default function ModuleEvents() {
  if (!$.fn.DataTable.isDataTable("#events-table")) {
    let url = new URLSearchParams(window.location.search);
    let page = url.get("page") || 1;
    let length = url.get("length") || 10;
    let query = url.get("query") || "";
    $("#events-table").dataTable({
      processing: true,
      serverSide: true,
      searchDelay: 500,
      search: {
        search: query,
      },
      ajax: {
        type: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/api/event/datatable`,
        dataSrc: "data",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      },
      columns: [{ data: "_id" }, { data: "title" }, { data: "description" }, { data: "startDate" }, { data: "endDate" }, { data: "discount" }, { data: "" }],
      columnDefs: [
        { className: "text-center", targets: "_all" },
        { targets: [1, 2, 3, -1], orderable: false },
        {
          targets: 0,
          render: function(data, type, row, meta) {
            return meta.row + meta.settings._iDisplayStart + 1;
          },
        },
        {
          targets: -1,
          title: "Actions",
          render: function(data, type, row, meta) {
            return `<a href="javascript:;" data-id="${row["_id"]}" class="btnEdit"><i class="text-primary fas fa-edit m-2"></i></a>
            <a href="javascript:;" class="btnDel" data-id="${row["_id"]}"><i class="text-danger fas fa-trash m-2"></i></a>`;
          },
        },
        {
          targets: 3,
          render: function(data, type, row, meta) {
            return moment(row["startDate"]).format("DD-MM-YYYY HH:mm:ss"); 
          },
        },
        {
          targets: 4,
          render: function(data, type, row, meta) {
            return moment(row["endDate"]).format("DD-MM-YYYY HH:mm:ss"); 
          },
        },
        {
          targets: 5,
          render: function(data, type, row, meta) {
            var $prod = row["products"];
            var discount = $prod[0].discount;
            var discountType = $prod[0].type;

            if (discountType === "percent") {
              return discount + "%"; 
            } else if (discountType === "flat") {
              return formatRP(discount); 
            } else {
              return discount; 
            }
          },
        },

        {
          targets: 6,
          render: function(data, type, row, meta) {
            var $prod = row["products"];
            return $prod[0].type;
          },
        },
      ],
      displayLength: length,
      displayStart: parseInt(page) * length - length,
      lengthMenu: [5, 10, 25, 50, 100, 1000],
    });
  }

  $("#events-table tbody").on("click", ".btnDel", function() {
    let id = $(this).data("id");
    let currentPage = $("#events-table")
      .DataTable()
      .page.info().page;

    swal({
      title: "Are you sure?",
      text: "Setelah dihapus, Anda tidak akan dapat memulihkan file ini!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("File Anda telah dihapus!", {
          icon: "success",
          timer: 1000,
          button: false,
        });
        $.ajax({
          method: "DELETE",
          url: `${process.env.REACT_APP_BASE_URL}/api/event/delete/${id}`,
          success: function(d, x, r) {
            if (r.status === 200) {
              let url = setUrl("page", currentPage + 1);
              window.history.pushState({ path: `/events/?${url}` }, "", `/events/?${url}`);

              $("#events-table")
                .DataTable()
                .ajax.reload(null, false);
            }
          },
        });
      }
    });
  });

  $(document).on("click", ".paginate_button", function() {
    let p = $(this).text();
    if (!parseInt(p)) return;
    let url = setUrl("page", p);
    window.history.pushState({ path: `/events/?${url}` }, "", `/events/?${url}`);
  });

  $(document).on("change", "select[name=events-table_length]", function() {
    let l = $(this).val();
    let url = setUrl("length", l);
    window.history.pushState({ path: `/events/?${url}` }, "", `/events/?${url}`);
  });

  $(document).on("keyup", "input[type=search]", function() {
    let q = $(this).val();
    let url = setUrl("query", q);
    window.history.pushState({ path: `/products/games/?${url}` }, "", `/products/games/?${url}`);
  });
}
