import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import ModalIcons from "./ModalIcons";
import SweetAlerMessage from "../../js/sweetalert";
import swal from "sweetalert";
import { Helmet, HelmetProvider } from "react-helmet-async";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const AddPayments = () => {
  const history = useHistory();
  const location = useLocation();

  const handleGoBack = () => {
    if (window.location.pathname === "/payments/add-payments") {
      history.goBack();
    } else {
         history.goBack();
    }
  };

  const [show, setShow] = useState(false);
  const [icon, setIcon] = useState("");
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [category, setCategory] = useState("");
  const [vendor, setVendor] = useState("");
  const [minTrx, setMinTrx] = useState("");
  const [maxTrx, setMaxTrx] = useState("");
  const [flat, setFlat] = useState("");
  const [percent, setPercent] = useState("");
  const [status, setStatus] = useState(true);
  const [assets, setAssets] = useState("");
  const [select, setSelect] = useState("");
  const [displayStatus, setDisplayStatus] = useState({
    detail: "none",
  });
  const [url, setUrl] = useState(`${process.env.REACT_APP_BASE_URL}/api/payment/`);
  const [method, setMethod] = useState("POST");
  const [title, setTitle] = useState("Tambah Payment");
  const [msg, setMsg] = useState("Payment berhasil ditambahkan");

  const handleClose = () => setShow(false);
  const handleShow = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/asset`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAssets(data.data);
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
    setShow(true);
  };

  const assetsSelect = (url) => () => {
    setIcon(url);
    handleClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(url, {
      method: method,
      body: JSON.stringify({
        image: icon,
        name,
        code,
        category,
        vendor,
        minTrx: parseFloat(minTrx),
        maxTrx: parseFloat(maxTrx),
        flat: parseFloat(flat),
        percent: parseFloat(percent),
        status,
        token: localStorage.getItem("token"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, data: data })))
      .then((data) => {
        if (data.status === 200) {
          swal({
            title: "Berhasil",
            text: msg,
            icon: "success",
            buttons: false,
            timer: 1000,
          });
          if (window.location.pathname === "/payments/add-payments") {
            history.goBack();
          } else {
            history.goBack();
            history.goBack();
          }
        } else {
          swal({
            title: "Gagal",
            text: data.data.msg,
            icon: "error",
            buttons: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    SweetAlerMessage();
    $("[data-height]").each(function() {
      $(this).css({
        height: $(this).data("height"),
      });
    });

    if (window.location.pathname === "/payments/add-payments") {
      setUrl(`${process.env.REACT_APP_BASE_URL}/api/payment/`);
      setMethod("POST");
    } else {
      setDisplayStatus({ ...displayStatus, detail: "block" });
      setUrl(`${process.env.REACT_APP_BASE_URL}/api/payment/${window.location.pathname.split("/").pop()}`);
      setMethod("PUT");

      fetch(`${process.env.REACT_APP_BASE_URL}/api/payment?id=${window.location.pathname.split("/").pop()}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((r) => r.json().then((data) => ({ status: r.status, data: data.data })))
        .then((data) => {
          setIcon(data.data.image);
          setName(data.data.name);
          setCode(data.data.code);
          setCategory(data.data.category);
          setVendor(data.data.vendor);
          setMinTrx(data.data.min_trx);
          setMaxTrx(data.data.max_trx);
          setFlat(data.data.flat);
          setPercent(data.data.percent);
          setStatus(data.data.status);
        })
        .catch((err) => {
          swal(err.message, {
            icon: "error",
            button: false,
          });
        });
      setTitle("Edit Payment");
      setMsg("Payment berhasil diubah");
    }
  }, [location.pathname]);

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charset="utf-8" />
        <meta name="description" content="Add Payments - Admin" />
        <meta name="google-site-verification" content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34=" />
        <title>Add Payments - Admin</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Payments</h1>
            <div className="section-header-breadcrumb">
              <div className="breadcrumb-item active">
                <Link to="/dashboard">Dashboard</Link>
              </div>
              <div className="breadcrumb-item">
                <Link to="/payments">List Payments</Link>
              </div>
              <div className="breadcrumb-item" id="title-game">
                Add Payments
              </div>
            </div>
          </div>

          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <form className="card" onSubmit={handleSubmit}>
                  <div className="card-header">
                    <div className="cursor-pointer d-flex" onClick={handleGoBack}>
                      <span>
                        <i className="fas fa-arrow-left"></i>
                      </span>
                      <h4 className="ml-2">{title}</h4>
                    </div>
                  </div>
                  <div className="card-body">
                    <Row>
                      <Col xs={12}>
                        <div className="form-group">
                          <div className="d-flex">
                            <label className="mr-2">Icon</label>
                            <OverlayTrigger
                              key={"top"}
                              top={"top"}
                              overlay={
                                <Tooltip id={`tooltip-${"top"}`}>
                                  <img src={`${process.env.REACT_APP_STATIC_SRC}/prevIconPayment_1688384669466.webp`} alt="icon" width="400px" />
                                </Tooltip>
                              }
                            >
                              <div>
                                <i className="fas fa-question-circle"></i>
                              </div>
                            </OverlayTrigger>
                          </div>
                          <div className="input-group mb-3">
                            <input type="text" className="form-control url" placeholder="Masukkan Link Icon" onChange={(e) => setIcon(e.target.value)} value={icon} disabled />
                            <div className="input-group-append">
                              <button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => {
                                  handleShow();
                                  setSelect("icon");
                                }}
                              >
                                Pilih File
                              </button>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} lg={6}>
                        <div className="form-group">
                          <label>Kode</label>
                          <input type="text" className="form-control" onChange={(e) => setCode(e.target.value)} value={code} placeholder="Contoh: BCAVA" />
                        </div>
                      </Col>
                      <Col xs={12} lg={6}>
                        <div className="form-group">
                          <label>Nama</label>
                          <input type="text" className="form-control" onChange={(e) => setName(e.target.value)} value={name} placeholder="Contoh: BCA" />
                        </div>
                      </Col>
                      <Col xs={12} lg={6}>
                        <div className="form-group">
                          <label>Kategori</label>
                          <select className="form-control" onChange={(e) => setCategory(e.target.value)} value={category}>
                            <option value="">Pilih Kategori</option>
                            <option value="Virtual Account">Virtual Account</option>
                            <option value="E-Wallet">E-Wallet</option>
                            <option value="Convenience Store">Convenience Store</option>
                          </select>
                        </div>
                      </Col>
                      <Col xs={12} lg={6}>
                        <div className="form-group">
                          <label>Vendor</label>
                          <select className="form-control" onChange={(e) => setVendor(e.target.value)} value={vendor}>
                            <option value="">Pilih Vendor</option>
                            <option value="duitku">Duitku</option>
                            <option value="xendit">Xendit</option>
                            <option value="tripay">Tripay</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} lg={6}>
                        <div className="form-group">
                          <label>Minimal Transaksi</label>
                          <input type="number" className="form-control" onChange={(e) => setMinTrx(e.target.value)} value={minTrx} placeholder="Masukkan Min. Transaksi" />
                        </div>
                      </Col>
                      <Col xs={12} lg={6}>
                        <div className="form-group">
                          <label>Maksimal Transaksi</label>
                          <input type="number" className="form-control" onChange={(e) => setMaxTrx(e.target.value)} value={maxTrx} placeholder="Masukkan Max. Transaksi" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} lg={6}>
                        <div className="form-group">
                          <label>Flat</label>
                          <input type="number" className="form-control" onChange={(e) => setFlat(e.target.value)} value={flat} placeholder="Masukkan Flat" />
                        </div>
                      </Col>
                      <Col xs={12} lg={6}>
                        <div className="form-group">
                          <label>Percent</label>
                          <input type="number" className="form-control" onChange={(e) => setPercent(e.target.value)} value={percent} placeholder="Masukkan Percent" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} lg={6} style={{ display: displayStatus.detail }}>
                        <div className="form-group">
                          <label>Status</label>
                          <select className="form-control" onChange={(e) => setStatus(e.target.value)} value={status}>
                            <option disabled>Pilih Status</option>
                            <option value={true}>Aktif</option>
                            <option value={false}>Nonaktif</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                    <ModalIcons show={show} handleClose={handleClose} assets={assets} assetsSelect={assetsSelect} />
                  </div>
                  <div className="card-footer text-right">
                    <button className="btn btn-primary mr-1" type="submit">
                      Submit
                    </button>
                    <Link to="/payments" className="btn btn-secondary" type="button">
                      Batal
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </HelmetProvider>
  );
};

export default AddPayments;
