import $ from "jquery";
import React, { useEffect, useState } from "react";
import {
  Link,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom";
import SweetAlerMessage from "../../js/sweetalert";
import swal from "sweetalert";
import { ThreeDots } from "react-loader-spinner";
import { Helmet, HelmetProvider } from "react-helmet-async";
import moment from "moment";

const AddGames = () => {
  const history = useHistory();
  const location = useLocation();

  const handleGoBack = () => {
    if (history.location.pathname === location.pathname) {
         history.goBack();
    }
  };

  const [loadingModal, setLoadingModal] = useState(false);
  const [reff, setReff] = useState("");
  const [trxId, setTrxId] = useState("");
  const [customer, setCustomer] = useState("");
  const [total, setTotal] = useState("");
  const [metode, setMetode] = useState("");
  const [created_at, setCreated_at] = useState("");
  const [updated_at, setUpdated_at] = useState("");
  const [status_payment, setStatus_payment] = useState("-");
  const [status_vendor, setStatus_vendor] = useState("-");

  const [produk, setProduk] = useState("");
  const [vendor, setVendor] = useState({});
  const [hargaModal, setHargaModal] = useState("");
  const [hargaJual, setHargaJual] = useState("");

  function formatRP(margin) {
    const formatted = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 2,
    }).format(Math.round(margin));

    return formatted.replace(",00", "");
  }

  const handleResubmit = (e, type) => {
    e.preventDefault();
    swal({
      title: "Apakah kamu yakin?",
      text: "Kamu akan resubmit transaksi ini!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((e) => {
      if (e) {
        setLoadingModal(true);
        fetch(
          `${
            process.env.REACT_APP_BASE_URL
          }/api/trx/${window.location.pathname.split("/").pop()}`,
          {
            method: "PUT",
            body: JSON.stringify({
              ref: reff,
              type,
              token: localStorage.getItem("token"),
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
          .then((r) =>
            r.json().then((data) => ({ status: r.status, data: data }))
          )
          .then((data) => {
            if (data.status === 200) {
              setLoadingModal(false);
              swal({
                title: "Berhasil",
                text: data.data.msg,
                icon: "success",
                buttons: false,
                timer: 1000,
              });
              window.location.reload();
            } else {
              setLoadingModal(false);
              swal({
                title: "Gagal!",
                text: data.data.msg,
                icon: "error",
                buttons: false,
                timer: 2000,
              });
            }
          })
          .catch((err) => {
            swal(err.message, {
              icon: "error",
              button: false,
            });
          });
      }
    });
  };

  useEffect(() => {
    SweetAlerMessage();
    $("[data-height]").each(function() {
      $(this).css({
        height: $(this).data("height"),
      });
    });

    fetch(
      `${
        process.env.REACT_APP_BASE_URL
      }/api/trx?ref=${window.location.pathname.split("/").pop()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setReff(data.data.ref);
        setTrxId(data.data._id);
        setCustomer(data.data.customer);
        setTotal(data.data.gross_total_payment);
        setMetode(data.data.payment);
        setCreated_at(data.data.createdAt);
        setUpdated_at(data.data.updatedAt);
        if (data.data.callback) {
          if (data.data.callback.payment) {
            setStatus_payment(data.data.callback.payment.status);
          }
          if (data.data.callback.vendor) {
            setStatus_vendor(data.data.callback.vendor.status);
          }
        }
        setProduk(data.data.product);
        setVendor(data.data.product.vendor);
        setHargaModal(data.data.product.vendor_price);
        setHargaJual(data.data.clean_total_payment);
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  }, [location.pathname]);

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charset="utf-8" />
        <meta name="description" content="Detail Transactions - Admin" />
        <meta
          name="google-site-verification"
          content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34="
        />
        <title>Detail Transactions - Admin</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Transactions</h1>
            <div className="section-header-breadcrumb">
              <div className="breadcrumb-item active">
                <Link to="/dashboard">Dashboard</Link>
              </div>
              <div className="breadcrumb-item">
                <Link to="/transactions">List Transactions</Link>
              </div>
              <div className="breadcrumb-item" id="title-game">
                Detail
              </div>
            </div>
          </div>

          <div className="section-body">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="card">
                  <div className="card-header">
                    <div className="cursor-pointer" onClick={handleGoBack}>
                      <i className="fas fa-arrow-left"></i>
                    </div>
                  </div>
                  <h6 className="ml-2 text-center">Informasi Transaksi</h6>
                  <div className="card-body">
                    <div className="d-flex flex-column border-bottom mb-3">
                      <span className="fz-12 fw-400">Ref</span>
                      <span className="fz-13 fw-bold">{reff}</span>
                    </div>
                    <div className="d-flex flex-column border-bottom mb-3">
                      <span className="fz-12 fw-400">Trx Id</span>
                      <span className="fz-13 fw-bold">{trxId}</span>
                    </div>
                    <div className="d-flex flex-column border-bottom mb-3">
                      <span className="fz-12 fw-400">Customer</span>
                      <span className="fz-13 fw-bold">{customer.phone}</span>
                    </div>
                    <div className="d-flex flex-column border-bottom mb-3">
                      <span className="fz-12 fw-400">Tujuan</span>
                      <div className="d-flex">
                        <span className="fz-13 fw-bold">
                          {customer.id}{" "}
                          {customer.server ? `(${customer.server})` : ""}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex flex-column border-bottom mb-3">
                      <span className="fz-12 fw-400">Total</span>
                      <span className="fz-13 fw-bold">{formatRP(total)}</span>
                    </div>
                    <div className="d-flex flex-column border-bottom mb-3">
                      <span className="fz-12 fw-400">Metode</span>
                      <span className="fz-13 fw-bold">{metode.name}</span>
                    </div>
                    <div className="d-flex flex-column border-bottom mb-3">
                      <span className="fz-12 fw-400">Tanggal dibuat</span>
                      <span className="fz-13 fw-bold">
                        {moment(created_at).format("DD MMMM YYYY, HH:mm:ss")}
                      </span>
                    </div>
                    <div className="d-flex flex-column border-bottom mb-3">
                      <span className="fz-12 fw-400">Tanggal diubah</span>
                      <span className="fz-13 fw-bold">
                        {moment(updated_at).format("DD MMMM YYYY, HH:mm:ss")}
                      </span>
                    </div>
                    <div className="d-flex flex-column border-bottom mb-3">
                      <span className="fz-12 fw-400">Status Pembayaran</span>
                      <span className="fz-13 fw-bold">{status_payment}</span>
                    </div>
                    <div className="d-flex flex-column border-bottom mb-3">
                      <span className="fz-12 fw-400">Status Vendor</span>
                      <span className="fz-13 fw-bold">{status_vendor}</span>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <button
                    className="btn btn-success mt-3 w-auto mr-3"
                    onClick={(e) => {
                      handleResubmit(e, "payment");
                    }}
                  >
                    <i className="fas fa-check"></i> Resubmit Payment
                  </button>
                  <button
                    className="btn btn-success mt-3 w-auto mr-3"
                    onClick={(e) => {
                      handleResubmit(e, "vendor");
                    }}
                  >
                    <i className="fas fa-check"></i> Resubmit Vendor
                  </button>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="card">
                  <div className="card-header"></div>
                  <h6 className="ml-2 text-center">Informasi Produk</h6>
                  <div className="card-body">
                    <div className="d-flex flex-column border-bottom mb-3">
                      <span className="fz-12 fw-400">Kode Produk</span>
                      <span className="fz-13 fw-bold">{produk.code}</span>
                    </div>
                    <div className="d-flex flex-column border-bottom mb-3">
                      <span className="fz-12 fw-400">Vendor</span>
                      <span className="fz-13 fw-bold">{vendor.name}</span>
                    </div>
                    <div className="d-flex flex-column border-bottom mb-3">
                      <span className="fz-12 fw-400">Nama Produk</span>
                      <span className="fz-13 fw-bold">{produk.name}</span>
                    </div>
                    <div className="d-flex flex-column border-bottom mb-3">
                      <span className="fz-12 fw-400">Harga Modal</span>
                      <span className="fz-13 fw-bold">
                        {formatRP(hargaModal)}
                      </span>
                    </div>
                    <div className="d-flex flex-column border-bottom mb-3">
                      <span className="fz-12 fw-400">Harga Jual</span>
                      <span className="fz-13 fw-bold">
                        {formatRP(hargaJual)}
                      </span>
                    </div>
                    <div className="d-flex flex-column border-bottom mb-3">
                      <span className="fz-12 fw-400">Fee</span>
                      <span className="fz-13 fw-bold">
                        {formatRP(total - hargaJual)}
                      </span>
                    </div>
                    <div className="d-flex flex-column border-bottom mb-3">
                      <span className="fz-12 fw-400">Profit</span>
                      <span className="fz-13 fw-bold">
                        {formatRP(total - hargaModal - (total - hargaJual))}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {loadingModal ? (
          <div className="overlay">
            <span className="custom-loader">
              <ThreeDots
                height="150"
                width="150"
                radius="9"
                color="#fff"
                ariaLabel="three-dots-loading"
                visible={true}
              />
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    </HelmetProvider>
  );
};

export default AddGames;
