import $ from "jquery";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";
import { decodeToken } from "react-jwt";

const token = secureLocalStorage.getItem("role");
const myDecodedToken = token !== null ? decodeToken(token) : null;

function setUrl(key, value) {
  const url = new URLSearchParams(window.location.search);
  url.set(key, value);
  return url.toString();
}

export default function ModuleTrx() {
  if (!$.fn.DataTable.isDataTable("#trx-table")) {
    let url = new URLSearchParams(window.location.search);
    let page = url.get("page") || 1;
    let length = url.get("length") || 10;
    let query = url.get("query") || "";
    $("#trx-table").dataTable({
      processing: true,
      serverSide: true,
      searchDelay: 500,
      search: {
        search: query,
      },
      ajax: {
        type: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/api/trx/datatable`,
        dataSrc: "data",
      },
      columns: [
        { data: "_id" },
        { data: "updatedAt" },
        { data: "ref" },
        { data: "" },
        { data: "" },
        { data: "" },
        { data: "createdAt" },
        { data: "status" },
        { data: "" },
      ],
      columnDefs: [
        { className: "text-center", targets: "_all" },
        { targets: [3, 4, 5, 6, -1], orderable: false },
        {
          targets: 0,
          visible: false,
        },
        {
          targets: 1,
          render: function(data, type, row, meta) {
            return meta.row + meta.settings._iDisplayStart + 1;
          },
        },
        {
          targets: 3,
          render: function(data, type, row, meta) {
            if (!row.customer.username) {
              return "<span>-</span>";
            } else {
              return row.customer.username;
            }
          },
        },
        {
          targets: 4,
          render: function(data, type, row, meta) {
            if (row.product.name) {
              return `<div class="d-flex flex-column">
            <span>${row.product.game.name}</span>
            <span>${row.product.name}</span>
            <span>(${row.product.code})</span>
            </div>`;
            } else {
              return "<span>-</span>";
            }
          },
        },
        {
          targets: 5,
          render: function(data, type, row, meta) {
            if (!row.payment.name) {
              return "<span>-</span>";
            } else {
              return row.payment.name;
            }
          },
        },
        {
          targets: 6,
          render: function(data, type, row, meta) {
            return moment(row.createdAt).format("DD MMMM YYYY, HH:mm:ss");
          },
        },
        {
          targets: -2,
          render: function(data, type, row, meta) {
            let badge = "";
            if (row.status === "SUCCESS") {
              badge = `<button class="btn btn-sm btn-success">Sukses</button>`;
            } else if (row.status === "PENDING") {
              badge = `<button class="btn btn-sm btn-danger">Pending</button>`;
            } else if (row.status === "EXPIRED") {
              badge = `<button class="btn btn-sm btn-danger">Expired</button>`;
            } else if (row.status === "FAILED") {
              badge = `<button class="btn btn-sm btn-danger">Failed</button>`;
            }
            return badge;
          },
        },
        {
          targets: -1,
          visible:
            myDecodedToken.role === "owner" || myDecodedToken.role === "super"
              ? true
              : false,
          render: function(data, type, row, meta) {
            return `<button class="btn btn-sm btn-primary btnDetail" data-id="${row["ref"]}"><i class="fas fa-eye text-light"></i></button>`;
          },
        },
      ],
      order: [[0, "desc"]],
      displayLength: length,
      displayStart: parseInt(page) * length - length,
      lengthMenu: [5, 10, 25, 50, 100, 1000],
    });

    $(document).on("click", ".paginate_button", function() {
      let p = $(this).text();
      if (!parseInt(p)) return;
      let url = setUrl("page", p);
      window.history.pushState(
        { path: `/transactions/?${url}` },
        "",
        `/transactions/?${url}`
      );
    });

    $(document).on("change", "select[name=trx-table_length]", function() {
      let l = $(this).val();
      let url = setUrl("length", l);
      window.history.pushState(
        { path: `/transactions/?${url}` },
        "",
        `/transactions/?${url}`
      );
    });

    $(document).on("keyup", "input[type=search]", function() {
      let q = $(this).val();
      let url = setUrl("query", q);
      window.history.pushState(
        { path: `/products/games/?${url}` },
        "",
        `/products/games/?${url}`
      );
    });
  }
}
