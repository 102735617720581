import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import ModulePayment from "../../js/ModulePayment";
import { Link } from "react-router-dom";
import SweetAlerMessage from "../../js/sweetalert";
import $ from "jquery";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Helmet, HelmetProvider } from "react-helmet-async";

const ListPayments = () => {
  const history = useHistory();

  useEffect(() => {
    ModulePayment();
    SweetAlerMessage();

    $("#payment-table").on("click", ".btnEdit", function() {
      const id = $(this).data("id");
      history.push(`/payments/add-payments/${id}`);
    });
  }, [history]);

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charset="utf-8" />
        <meta name="description" content="Payments - Admin" />
        <meta
          name="google-site-verification"
          content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34="
        />
        <title>Payments - Admin</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Payments</h1>
            <div className="section-header-breadcrumb">
              <div className="breadcrumb-item active">
                <Link to="/dashboard">Dashboard</Link>
              </div>
              <div className="breadcrumb-item">List Payments</div>
            </div>
          </div>

          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-between">
                    <h4>List Payments</h4>
                    <Link to="/payments/add-payments">
                      <Button className="create-new">
                        <i className="fas fa-plus me-sm-1 text-light"></i>{" "}
                        <span className="d-none d-sm-inline-block text-light">
                          Tambah Payment
                        </span>
                      </Button>
                    </Link>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped" id="payment-table">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Icon</th>
                            <th>Kode</th>
                            <th>Kategori</th>
                            <th>Fee</th>
                            <th>Status</th>
                            <th>Opsi</th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </HelmetProvider>
  );
};

export default ListPayments;
