import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SweetAlerMessage from "../../js/sweetalert";
import $ from "jquery";
import swal from "sweetalert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Col, Row } from "react-bootstrap";

const Synchronization = () => {
  const [from, setFrom] = useState([]);
  const [fromSelect, setFromSelect] = useState("");
  const [to, setTo] = useState([]);
  const [toSelect, setToSelect] = useState("");

  const history = useHistory({
    forceRefresh: true,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_BASE_URL}/api/tenant/syncdb`, {
      method: "POST",
      body: JSON.stringify({
        from: fromSelect,
        to: toSelect,
        token: localStorage.getItem("token"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, data: data })))
      .then((data) => {
        if (data.status === 200) {
          swal({
            title: "Berhasil",
            text: data.data.msg,
            icon: "success",
            buttons: false,
            timer: 1000,
          });
        } else {
          swal({
            title: "Gagal",
            text: data.data.msg,
            icon: "error",
            buttons: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/tenant?q=database`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json().then((d) => ({ status: r.status, data: d.data })))
      .then((d) => {
        setFrom(d.data);
        setTo(d.data);
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: false,
        });
      });

    SweetAlerMessage();
  }, [history]);

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charset="utf-8" />
        <meta name="description" content="Database Synchronization - Admin" />
        <meta
          name="google-site-verification"
          content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34="
        />
        <title>Database Synchronization - Admin</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Database Synchronization</h1>
            <div className="section-header-breadcrumb">
              <div className="breadcrumb-item active">
                <Link to="/dashboard">Dashboard</Link>
              </div>
              <div className="breadcrumb-item">Database Synchronization</div>
            </div>
          </div>

          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <form className="card" onSubmit={handleSubmit}>
                  <div className="card-header">
                    <Link to="/payments" className="d-flex ">
                      <h4 className="ml-2">Form Database Synchronization </h4>
                    </Link>
                  </div>
                  <div className="card-body">
                    <Row>
                      <Col xs={12} lg={6}>
                        <div className="form-group">
                          <label>Dari database</label>
                          <select
                            className="form-control"
                            onChange={(e) => setFromSelect(e.target.value)}
                            value={fromSelect}
                          >
                            <option value="">Pilih Database</option>
                            {from.map((item) => {
                              return (
                                <option key={item._id} value={item.database}>
                                  {item.database}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </Col>
                      <Col xs={12} lg={6}>
                        <div className="form-group">
                          <label>Ke Database</label>
                          <select
                            className="form-control"
                            onChange={(e) => setToSelect(e.target.value)}
                            value={toSelect}
                          >
                            <option value="">Pilih Database</option>
                            <option value="all">Semua</option>
                            {to.map((item) => {
                              return (
                                <option key={item._id} value={item.database}>
                                  {item.database}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="card-footer text-right">
                    <button className="btn btn-primary mr-1" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </HelmetProvider>
  );
};

export default Synchronization;
