import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import * as jQuery from "jquery";
import "./assets/css/style.css";
import "@fortawesome/fontawesome-free/css/all.css";
import * as serviceWorker from "./serviceWorker";
import "flag-icon-css/css/flag-icon.css";
import "./assets/css/components.css";

window.jQuery = jQuery;
window.$ = jQuery;

require("sweetalert/dist/sweetalert.min.js");

require("popper.js/dist/popper.min");
require("jquery/dist/jquery.min");
require("jquery-ui-dist/jquery-ui.min.js");

var moment = require("moment/min/moment.min.js");

require("select2/dist/js/select2.full");

require("bootstrap/dist/js/bootstrap.min");
require("bootstrap-select/dist/js/bootstrap-select");
require("jquery.nicescroll/dist/jquery.nicescroll.js");

window.moment = moment;
window.JSZip = require("jszip");
require("datatables.net-bs4/css/dataTables.bootstrap4.min.css");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-responsive");
require("datatables.net-responsive-bs4");
require("datatables.net-select");
require("datatables.net-select-bs4");
require("weathericons/css/weather-icons.min.css");
require("weathericons/css/weather-icons-wind.min.css");
window.Chart = require("chart.js/dist/Chart.min");
require("./js/js/scripts");

createRoot(document.getElementById("root")).render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>
);

serviceWorker.unregister();
