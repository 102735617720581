import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { userDetail } from "../../SettingDetailJs";
import swal from "sweetalert";

const UserDropdown = () => {
  const history = useHistory();
  const [img, setImg] = useState("");
  const [name, setName] = useState("");
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    fetch(
      `${
        process.env.REACT_APP_BASE_URL
      }/api/login/verifyToken?token=${localStorage.getItem("token")}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((r) => r.json().then((data) => ({ status: r.status, data: data })))
      .then((data) => {
        if (data.status !== 200) {
          localStorage.removeItem("token");
          window.location.href = "/login";
        } else {
          setImg(data.data.picture);
          setName(data.data.name);
          setLoader(false);
        }
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  }, []);

  return (
    <>
      {loader ? (
        <div className="overlay-full">
          <span className="custom-loader-first"></span>
        </div>
      ) : (
        <li className="dropdown">
          <button
            data-toggle="dropdown"
            className="link-custom nav-link dropdown-toggle nav-link-lg nav-link-user"
          >
            <img alt="photos" src={img} className="rounded-circle mr-1" />
            <div className="d-sm-none d-lg-inline-block">{name}</div>
          </button>
          <div className="dropdown-menu dropdown-menu-right">
            {userDetail.datas
              ? userDetail.datas.map((data, idata) => {
                  return (
                    <NavLink
                      key={idata}
                      to={data.link}
                      activeStyle={{
                        color: "#6777ef",
                      }}
                      exact
                      className="dropdown-item has-icon"
                    >
                      <i className={data.icode} /> {data.title}
                    </NavLink>
                  );
                })
              : []}

            <button
              className="d-flex align-items-center dropdown-item has-icon text-danger"
              onClick={() => {
                swal({
                  title: "Are you sure?",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    localStorage.removeItem("token");
                    history.push("/login");
                  }
                });
              }}
            >
              <i className={userDetail.logoutIcon} /> {userDetail.logoutTitle}
            </button>
          </div>
        </li>
      )}
    </>
  );
};

export default UserDropdown;
