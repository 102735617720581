import React, { useEffect } from "react";
import ModuleTrx from "../../js/ModuleTrx";
import { Link } from "react-router-dom";
import SweetAlerMessage from "../../js/sweetalert";
import $ from "jquery";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Helmet, HelmetProvider } from "react-helmet-async";

const ListTrx = () => {
  const history = useHistory();

  useEffect(() => {
    ModuleTrx();
    SweetAlerMessage();

    $("#trx-table").on("click", ".btnDetail", function() {
      const id = $(this).data("id");
      history.push(`/transactions/detail/${id}`);
    });
  }, [history]);

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charset="utf-8" />
        <meta name="description" content="Transactions - Admin" />
        <meta
          name="google-site-verification"
          content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34="
        />
        <title>Transactions - Admin</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Transactions</h1>
            <div className="section-header-breadcrumb">
              <div className="breadcrumb-item active">
                <Link to="/dashboard">Dashboard</Link>
              </div>
              <div className="breadcrumb-item">List Transactions</div>
            </div>
          </div>

          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>List Transaksi</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table
                        className="table table-striped w-100"
                        id="trx-table"
                      >
                        <thead>
                          <tr>
                            <th></th>
                            <th>No</th>
                            <th scope="col">Ref</th>
                            <th scope="col">Customer</th>
                            <th scope="col">Produk</th>
                            <th scope="col">Jenis Payment</th>
                            <th scope="col">Tanggal</th>
                            <th scope="col">Status</th>
                            <th scope="col" id="act">
                              Action
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </HelmetProvider>
  );
};

export default ListTrx;
