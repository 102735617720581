import $ from "jquery";
import moment from "moment";

function setUrl(key, value) {
  const url = new URLSearchParams(window.location.search);
  url.set(key, value);
  return url.toString();
}

export default function ModuleLogError() {
  if (!$.fn.DataTable.isDataTable("#logs-error-table")) {
    let url = new URLSearchParams(window.location.search);
    let page = url.get("page") || 1;
    let length = url.get("length") || 10;
    let query = url.get("query") || "";
    $("#logs-error-table").dataTable({
      processing: true,
      serverSide: true,
      searchDelay: 500,
      search: {
        search: query,
      },
      responsive: true,
      ajax: {
        type: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/api/log?log=error`,
      },
      columns: [{ data: "createdAt" }, { data: "event" }, { data: "message" }],
      columnDefs: [
        {
          className: "text-left ml-5",
          targets: "_all",
          orderable: false,
        },
        {
          targets: 0,
          width: "20%",
          render: function(data, type, row, meta) {
            return moment(row.createdAt).format("DD MMMM YYYY, HH:mm:ss");
          },
        },
        {
          targets: 1,
          width: "10%",
        },
        {
          targets: 2,
          width: "70%",
        },
      ],
      displayLength: length,
      displayStart: parseInt(page) * length - length,
      lengthMenu: [5, 10, 25, 50, 100, 1000],
    });
  }
  $(document).on("click", ".paginate_button", function() {
    let p = $(this).text();
    if (!parseInt(p)) return;
    let url = setUrl("page", p);
    window.history.pushState({ path: `/logs/?${url}` }, "", `/logs/?${url}`);
  });

  $(document).on("change", "select[name=logs-error-table_length]", function() {
    let l = $(this).val();
    let url = setUrl("length", l);
    window.history.pushState({ path: `/logs/?${url}` }, "", `/logs/?${url}`);
  });

  $(document).on("keyup", "input[type=search]", function() {
    let q = $(this).val();
    let url = setUrl("query", q);
    window.history.pushState(
      { path: `/products/games/?${url}` },
      "",
      `/products/games/?${url}`
    );
  });
}
