import React, { useEffect, useState } from "react";
import ModalIcons from "./ModalIcons";
import { useHistory, Link } from "react-router-dom";
import swal from "sweetalert";
import { Helmet, HelmetProvider } from "react-helmet-async";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { ThreeDots } from "react-loader-spinner";

const SiteConfig = () => {
  const [show, setShow] = useState(false);
  const [logo, setLogo] = useState("");
  const [favicon, setFavicon] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [slider, setSlider] = useState([]);
  const [assets, setAssets] = useState("");
  const [select, setSelect] = useState("");
  const [codeApigames, setCodeApigames] = useState("");
  const [timeApigames, setTimeApigames] = useState("");
  const [codeDatabase, setCodeDatabase] = useState("");
  const [timeDatabase, setTimeDatabase] = useState("");
  const [codeSmile, setCodeSmile] = useState("");
  const [timeSmile, setTimeSmile] = useState("");
  const [codeTokovoucher, setCodeTokovoucher] = useState("");
  const [timeTokovoucher, setTimeTokovoucher] = useState("");
  const [loadApigames, setLoadApigames] = useState(false);
  const [loadDatabase, setLoadDatabase] = useState(false);
  const [loadSmile, setLoadSmile] = useState(false);
  const [loadTokovoucher, setLoadTokovoucher] = useState(false);

  const [contacts, setContacts] = useState([]);

  const [idx, setIdx] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/asset`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAssets(data.data);
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
    setShow(true);
  };

  let assetsSelect = (url) => () => {
    if (select === "logo") {
      setLogo(url);
    } else if (select === "favicon") {
      setFavicon(url);
    } else if (select === "slider") {
      let newSlider = [...slider];
      if (newSlider === undefined) newSlider = [];
      newSlider.push(url);
      setSlider(newSlider);
    } else if (select === "icon") {
      let newContacts = [...contacts];
      newContacts[idx].icon = url;
      setContacts(newContacts);
    }
    handleClose();
  };

  let closeSlide = (url) => {
    let slide = slider.filter((element) => element !== url);
    setSlider(slide);
  };

  let editContact = (value, index, name) => {
    let newContacts = [...contacts];
    newContacts[index][name] = value;
    setContacts(newContacts);
  };

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_BASE_URL}/api/setting`, {
      method: "PUT",
      body: JSON.stringify({
        logo,
        favicon,
        slider,
        title,
        description,
        token: localStorage.getItem("token"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, data: data })))
      .then((data) => {
        if (data.status === 200) {
          swal({
            title: "Berhasil",
            text: "Data berhasil diubah",
            icon: "success",
            buttons: false,
            timer: 1000,
          });
          history.push("/site-config");
        } else {
          swal({
            title: "Gagal",
            text: data.data.msg,
            icon: "error",
            buttons: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  };
  const handleSubmitContact = (e) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_BASE_URL}/api/setting`, {
      method: "PUT",
      body: JSON.stringify({
        contacts,
        token: localStorage.getItem("token"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, data: data })))
      .then((data) => {
        if (data.status === 200) {
          swal({
            title: "Berhasil",
            text: "Data berhasil diubah",
            icon: "success",
            buttons: false,
            timer: 1000,
          });
          history.push("/site-config");
        } else {
          swal({
            title: "Gagal",
            text: data.data.msg,
            icon: "error",
            buttons: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  };

  const handleClick = () => {
    setLoadApigames(true);
    setLoadDatabase(true);
    setLoadSmile(true);
    setLoadTokovoucher(true);
    fetch(`${process.env.REACT_APP_BASE_URL}/api/healthcheck`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setLoadApigames(false);
        setLoadDatabase(false);
        setLoadSmile(false);
        setLoadTokovoucher(false);
        setCodeApigames(data.data.apigames.code);
        setTimeApigames(data.data.apigames.time);
        setCodeDatabase(data.data.apigames.code);
        setTimeDatabase(data.data.apigames.time);
        setCodeSmile(data.data.apigames.code);
        setTimeSmile(data.data.apigames.time);
        setCodeTokovoucher(data.data.apigames.code);
        setTimeTokovoucher(data.data.apigames.time);
      });
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/setting`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) =>
        r.json().then((data) => ({ status: r.status, data: data.data }))
      )
      .then((data) => {
        setLogo(data.data[0].logo);
        setFavicon(data.data[0].favicon);
        setTitle(data.data[0].title);
        setDescription(data.data[0].description);
        setSlider(data.data[0].slider);
        setContacts(data.data[0].contacts);
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  }, []);
  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charset="utf-8" />
        <meta name="description" content="Site Config - Admin" />
        <meta
          name="google-site-verification"
          content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34="
        />
        <title>Site Config - Admin</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Settings</h1>
            <div className="section-header-breadcrumb">
              <div className="breadcrumb-item active">
                <Link to="/dashboard">Dashboard</Link>
              </div>
              <div className="breadcrumb-item">Settings</div>
            </div>
          </div>

          <ul className="nav nav-pills mb-3 gap" id="pills-tab" role="tablist">
            <li className="nav-item mr-2">
              <a
                className="nav-link btn-sm active"
                id="pills-webConfig-tab"
                data-toggle="pill"
                href="#pills-webConfig"
                role="tab"
                aria-controls="pills-webConfig"
                aria-selected="true"
              >
                Site Config
              </a>
            </li>
            <li className="nav-item mr-2">
              <a
                className="nav-link btn-sm"
                id="pills-contact-tab"
                data-toggle="pill"
                href="#pills-contact"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
              >
                Contact
              </a>
            </li>
            <li className="nav-item mr-2">
              <a
                className="nav-link btn-sm"
                id="pills-healthcheck-tab"
                data-toggle="pill"
                href="#pills-healthcheck"
                role="tab"
                aria-controls="pills-healthcheck"
                aria-selected="false"
              >
                Health Check
              </a>
            </li>
          </ul>

          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-webConfig"
              role="tabpanel"
              aria-labelledby="pills-webConfig-tab"
            >
              <div className="section-body">
                <div className="row">
                  <div className="col-12">
                    <form className="card" onSubmit={handleSubmit}>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12 col-lg-6">
                            <div className="form-group">
                              <div className="d-flex">
                                <label className="mr-2">Icon</label>
                                <OverlayTrigger
                                  key={"top"}
                                  top={"top"}
                                  overlay={
                                    <Tooltip id={`tooltip-${"top"}`}>
                                      <img
                                        src={`${process.env.REACT_APP_STATIC_SRC}/prevLogo_1688384700088.webp`}
                                        alt="icon"
                                        width="400px"
                                      />
                                    </Tooltip>
                                  }
                                >
                                  <div>
                                    <i className="fas fa-question-circle"></i>
                                  </div>
                                </OverlayTrigger>
                              </div>
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  className="form-control url"
                                  placeholder="Masukkan Link Logo"
                                  onChange={(e) => setLogo(e.target.value)}
                                  value={logo}
                                  disabled
                                />
                                <div className="input-group-append">
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={() => {
                                      handleShow();
                                      setSelect("logo");
                                    }}
                                  >
                                    Pilih File
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-lg-6">
                            <div className="form-group">
                              <div className="d-flex">
                                <label className="mr-2">Favicon</label>
                                <OverlayTrigger
                                  key={"top"}
                                  top={"top"}
                                  overlay={
                                    <Tooltip id={`tooltip-${"top"}`}>
                                      <img
                                        src={`${process.env.REACT_APP_STATIC_SRC}/prevFavicon_1688384648910.webp`}
                                        alt="icon"
                                        width="400px"
                                      />
                                    </Tooltip>
                                  }
                                >
                                  <div>
                                    <i className="fas fa-question-circle"></i>
                                  </div>
                                </OverlayTrigger>
                              </div>
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  className="form-control url"
                                  placeholder="Masukkan Link Favicon"
                                  onChange={(e) => setFavicon(e.target.value)}
                                  value={favicon}
                                  disabled
                                />
                                <div className="input-group-append">
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={() => {
                                      handleShow();
                                      setSelect("favicon");
                                    }}
                                  >
                                    Pilih File
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <div className="d-flex">
                                <label className="mr-2">Slider</label>
                                <OverlayTrigger
                                  key={"top"}
                                  top={"top"}
                                  overlay={
                                    <Tooltip id={`tooltip-${"top"}`}>
                                      <img
                                        src={`${process.env.REACT_APP_STATIC_SRC}/prevSlider_1688384707856.webp`}
                                        alt="slider"
                                        width="400px"
                                      />
                                    </Tooltip>
                                  }
                                >
                                  <div>
                                    <i className="fas fa-question-circle"></i>
                                  </div>
                                </OverlayTrigger>
                              </div>
                              <div className="input-group mb-3">
                                <div className="input-group-append">
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={() => {
                                      handleShow();
                                      setSelect("slider");
                                    }}
                                  >
                                    Pilih File
                                  </button>
                                </div>
                              </div>
                              <div className="row">
                                {slider
                                  ? slider.map((element, index) => (
                                      <div
                                        key={index}
                                        className="d-flex flex-wrap"
                                      >
                                        <div className="cursor-pointer d-flex flex-column mr-3 mb-3 badge-assets fz-12">
                                          <img
                                            src={element}
                                            alt="Preview Slider"
                                            className="img-preview"
                                          />
                                          <button
                                            type="button"
                                            className="close-slider btnDel"
                                            onClick={() => closeSlide(element)}
                                          >
                                            ×
                                          </button>
                                        </div>
                                      </div>
                                    ))
                                  : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <label>Nama</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Masukkan Judul Website"
                                onChange={(e) => setTitle(e.target.value)}
                                value={title || ""}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <label>Deskripsi Website</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Masukkan Deskripsi Website"
                                onChange={(e) => setDescription(e.target.value)}
                                value={description || ""}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-7">
                            <button className="btn btn-primary" type="submit">
                              Simpan Perubahan
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-contact"
              role="tabpanel"
              aria-labelledby="pills-contact-tab"
            >
              {contacts.map((element, index) => (
                <div key={index} className="section-body">
                  <div className="row">
                    <div className="col-12">
                      <form className="card" onSubmit={handleSubmitContact}>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <label>Nama</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Masukkan Nama"
                                  onChange={(e) => {
                                    editContact(e.target.value, index, "name");
                                  }}
                                  value={element.name || ""}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-lg-6">
                              <div className="form-group">
                                <div className="d-flex">
                                  <label className="mr-2">Icon</label>
                                  <OverlayTrigger
                                    key={"top"}
                                    top={"top"}
                                    overlay={
                                      <Tooltip id={`tooltip-${"top"}`}>
                                        <img
                                          src={`${process.env.REACT_APP_STATIC_SRC}/prevIconContact_1688384659249.webp`}
                                          alt="icon"
                                          width="400px"
                                        />
                                      </Tooltip>
                                    }
                                  >
                                    <div>
                                      <i className="fas fa-question-circle"></i>
                                    </div>
                                  </OverlayTrigger>
                                </div>
                                <div className="input-group mb-3">
                                  <input
                                    type="text"
                                    className="form-control url"
                                    placeholder="Masukkan Link Icon Contact"
                                    onChange={(e) => {
                                      editContact(
                                        e.target.value,
                                        index,
                                        `icon`
                                      );
                                    }}
                                    value={element.icon || ""}
                                    disabled
                                  />
                                  <div className="input-group-append">
                                    <button
                                      className="btn btn-primary"
                                      type="button"
                                      onClick={() => {
                                        handleShow();
                                        setIdx(index);
                                        setSelect(`icon`);
                                      }}
                                    >
                                      Pilih File
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <label>Username</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Masukkan Username"
                                  onChange={(e) =>
                                    editContact(
                                      e.target.value,
                                      index,
                                      "username"
                                    )
                                  }
                                  value={element.username || ""}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <label>URL</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Masukkan URL"
                                  onChange={(e) =>
                                    editContact(e.target.value, index, "url")
                                  }
                                  value={element.url || ""}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 col-md-7">
                              <button className="btn btn-primary" type="submit">
                                Simpan Perubahan
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div
              className="tab-pane fade"
              id="pills-healthcheck"
              role="tabpanel"
              aria-labelledby="pills-healthcheck-tab"
            >
              <div className="section-body">
                <button className="btn btn-primary" onClick={handleClick}>
                  Cek Koneksi
                </button>
                <div className="row mt-4">
                  <div className="col-12 col-sm-6 col-xl-3">
                    <div className="card">
                      <div className="card-header">
                        <h4>Apigames</h4>
                      </div>
                      <div className="card-body">
                        {loadApigames ? (
                          <ThreeDots
                            height="30"
                            width="30"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        ) : (
                          <div>
                            <div className="row">
                              <div className="col-3 mr-0 pr-0">
                                <span>Code:</span>
                              </div>
                              <div className="col-8 ml-0 pl-0">
                                <span>{codeApigames}</span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-3 mr-0 pr-0">
                                <span>Time:</span>
                              </div>
                              <div className="col-8 ml-0 pl-0">
                                <span>{timeApigames}</span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-3">
                    <div className="card">
                      <div className="card-header">
                        <h4>Database</h4>
                      </div>
                      <div className="card-body">
                        {loadApigames ? (
                          <ThreeDots
                            height="30"
                            width="30"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        ) : (
                          <div>
                            <div className="row">
                              <div className="col-3 mr-0 pr-0">
                                <span>Code:</span>
                              </div>
                              <div className="col-8 ml-0 pl-0">
                                <span>{codeDatabase}</span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-3 mr-0 pr-0">
                                <span>Time:</span>
                              </div>
                              <div className="col-8 ml-0 pl-0">
                                <span>{timeDatabase}</span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-3">
                    <div className="card">
                      <div className="card-header d-flex justify-content-between">
                        <h4>Smile</h4>
                      </div>
                      <div className="card-body">
                        {loadApigames ? (
                          <ThreeDots
                            height="30"
                            width="30"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        ) : (
                          <div>
                            <div className="row">
                              <div className="col-3 mr-0 pr-0">
                                <span>Code:</span>
                              </div>
                              <div className="col-8 ml-0 pl-0">
                                <span>{codeSmile}</span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-3 mr-0 pr-0">
                                <span>Time:</span>
                              </div>
                              <div className="col-8 ml-0 pl-0">
                                <span>{timeSmile}</span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-3">
                    <div className="card">
                      <div className="card-header d-flex justify-content-between">
                        <h4>Toko Voucher</h4>
                      </div>
                      <div className="card-body">
                        {loadApigames ? (
                          <ThreeDots
                            height="30"
                            width="30"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        ) : (
                          <div>
                            <div className="row">
                              <div className="col-3 mr-0 pr-0">
                                <span>Code:</span>
                              </div>
                              <div className="col-8 ml-0 pl-0">
                                <span>{codeTokovoucher}</span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-3 mr-0 pr-0">
                                <span>Time:</span>
                              </div>
                              <div className="col-8 ml-0 pl-0">
                                <span>{timeTokovoucher}</span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ModalIcons
            show={show}
            handleClose={handleClose}
            assets={assets}
            assetsSelect={assetsSelect}
          />
        </section>
      </div>
    </HelmetProvider>
  );
};

export default SiteConfig;
