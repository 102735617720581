import $ from "jquery";
import swal from "sweetalert";

function setUrl(key, value) {
  const url = new URLSearchParams(window.location.search);
  url.set(key, value);
  return url.toString();
}

export default function ModulePayment() {
  if (!$.fn.DataTable.isDataTable("#payment-table")) {
    let url = new URLSearchParams(window.location.search);
    let page = url.get("page") || 1;
    let length = url.get("length") || 10;
    let query = url.get("query") || "";
    $("#payment-table").dataTable({
      processing: true,
      serverSide: true,
      searchDelay: 500,
      search: {
        search: query,
      },
      ajax: {
        type: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/api/payment/datatable`,
        dataSrc: "data",
      },
      columns: [
        { data: "_id" },
        { data: "" },
        { data: "name" },
        { data: "category" },
        { data: "" },
        { data: "status" },
        { data: "" },
      ],
      columnDefs: [
        { className: "text-center", targets: "_all" },
        { targets: [1, 2, -1], orderable: false },
        {
          targets: 0,
          render: function(data, type, row, meta) {
            return meta.row + meta.settings._iDisplayStart + 1;
          },
        },
        {
          targets: 1,
          render: function(data, type, row, meta) {
            return `<img src=${row.image} alt="${row.name}" width="50" />`;
          },
        },
        {
          targets: 4,
          render: function(data, type, row, meta) {
            return `<div class="d-flex flex-column">
              <span>Flat: ${row.flat}</span>
              <span>Percent: ${row.percent}</span>
            </div>`;
          },
        },
        {
          targets: -2,
          render: function(data, type, row, meta) {
            let badge = "";
            if (row.status === true) {
              badge = `<div class="badge badge-success">Active</div>`;
            } else if (row.status === false) {
              badge = `<div class="badge badge-danger">Inactive</div>`;
            }
            return badge;
          },
        },
        {
          targets: -1,
          title: "Actions",
          render: function(data, type, row, meta) {
            return `<a href="javascript:;" data-id="${row["_id"]}" class="btnEdit"><i class="text-primary fas fa-edit m-2"></i></a>
            <a href="javascript:;" class="btnDel" data-id="${row["_id"]}"><i class="text-danger fas fa-trash m-2"></i></a>`;
          },
        },
      ],
      displayLength: length,
      displayStart: parseInt(page) * length - length,
      lengthMenu: [5, 10, 25, 50, 100, 1000],
    });
  }

  $("#payment-table tbody").on("click", ".btnDel", function() {
    let id = $(this).data("id");
    let currentPage = $("#payment-table")
      .DataTable()
      .page.info().page;

    swal({
      title: "Are you sure?",
      text: "Setelah dihapus, Anda tidak akan dapat memulihkan file ini!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("File Anda telah dihapus!", {
          icon: "success",
          timer: 1000,
          button: false,
        });
        $.ajax({
          method: "DELETE",
          url: `${
            process.env.REACT_APP_BASE_URL
          }/api/payment/${id}?token=${localStorage.getItem("token")}`,

          success: function(d, x, r) {
            if (r.status === 200) {
              let url = setUrl("page", currentPage + 1);
              window.history.pushState(
                { path: `/payments/?${url}` },
                "",
                `/payments/?${url}`
              );

              $("#payment-table")
                .DataTable()
                .ajax.reload(null, false);
            }
          },
        });
      }
    });
  });

  $(document).on("click", ".paginate_button", function() {
    let p = $(this).text();
    if (!parseInt(p)) return;
    let url = setUrl("page", p);
    window.history.pushState(
      { path: `/payments/?${url}` },
      "",
      `/payments/?${url}`
    );
  });

  $(document).on("change", "select[name=payment-table_length]", function() {
    let l = $(this).val();
    let url = setUrl("length", l);
    window.history.pushState(
      { path: `/payments/?${url}` },
      "",
      `/payments/?${url}`
    );
  });

  $(document).on("keyup", "input[type=search]", function() {
    let q = $(this).val();
    let url = setUrl("query", q);
    window.history.pushState(
      { path: `/payments/?${url}` },
      "",
      `/payments/?${url}`
    );
  });
}
