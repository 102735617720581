import React, { useEffect, useState } from "react";
import { ChartGeneral } from "./ChartGeneral";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Dashboard = () => {
  const [trxYesterday, setTrxYesterday] = useState(0);
  const [trxYesterdaySuccess, setTrxYesterdaySuccess] = useState(0);
  const [trxYesterdayPending, setTrxYesterdayPending] = useState(0);
  const [trxYesterdayFailed, setTrxYesterdayFailed] = useState(0);
  const [trxYesterdayExpired, setTrxYesterdayExpired] = useState(0);
  const [trxToday, setTrxToday] = useState(0);
  const [trxTodaySuccess, setTrxTodaySuccess] = useState(0);
  const [trxTodayPending, setTrxTodayPending] = useState(0);
  const [trxTodayFailed, setTrxTodayFailed] = useState(0);
  const [trxTodayExpired, setTrxTodayExpired] = useState(0);
  const [trxLastMonth, setTrxLastMonth] = useState(0);
  const [trxLastMonthSuccess, setTrxLastMonthSuccess] = useState(0);
  const [trxLastMonthPending, setTrxLastMonthPending] = useState(0);
  const [trxLastMonthFailed, setTrxLastMonthFailed] = useState(0);
  const [trxLastMonthExpired, setTrxLastMonthExpired] = useState(0);
  const [trxThisMonth, setTrxThisMonth] = useState(0);
  const [trxThisMonthSuccess, setTrxThisMonthSuccess] = useState(0);
  const [trxThisMonthPending, setTrxThisMonthPending] = useState(0);
  const [trxThisMonthFailed, setTrxThisMonthFailed] = useState(0);
  const [trxThisMonthExpired, setTrxThisMonthExpired] = useState(0);

  const [todaySales, setTodaySales] = useState(0);
  const [weekSales, setWeekSales] = useState(0);
  const [lastMonthSales, setLastMonthSales] = useState(0);
  const [monthSales, setMonthSales] = useState(0);
  const [yearSales, setYearSales] = useState(0);

  const [todayProfit, setTodayProfit] = useState(0);
  const [weekProfit, setWeekProfit] = useState(0);
  const [lastMonthProfit, setLastMonthProfit] = useState(0);
  const [monthProfit, setMonthProfit] = useState(0);
  const [yearProfit, setYearProfit] = useState(0);

  const [dataTrx, setDataTrx] = useState([]);

  function formatRP(margin) {
    const formatted = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 2,
    }).format(Math.round(margin));

    return formatted.replace(",00", "");
  }

  function fetchChart() {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/trx/dashboard`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setDataTrx(data.data.all);
        ChartGeneral(data.data.thisWeek);
        console.log(data.data.thisWeek);

        let yesterdayVar = data.data.yesterday;
        setTrxYesterday(yesterdayVar ? yesterdayVar.length : 0);
        setTrxYesterdaySuccess(
          yesterdayVar
            ? yesterdayVar.filter((item) => item.status === "SUCCESS").length
            : 0
        );
        setTrxYesterdayPending(
          yesterdayVar
            ? yesterdayVar.filter((item) => item.status === "PENDING").length
            : 0
        );
        setTrxYesterdayFailed(
          yesterdayVar
            ? yesterdayVar.filter((item) => item.status === "FAILED").length
            : 0
        );
        setTrxYesterdayExpired(
          yesterdayVar
            ? yesterdayVar.filter((item) => item.status === "EXPIRED").length
            : 0
        );

        let todayVar = data.data.today;
        setTrxToday(todayVar ? todayVar.length : 0);
        setTrxTodaySuccess(
          todayVar
            ? todayVar.filter((item) => item.status === "SUCCESS").length
            : 0
        );
        setTrxTodayPending(
          todayVar
            ? todayVar.filter((item) => item.status === "PENDING").length
            : 0
        );
        setTrxTodayFailed(
          todayVar
            ? todayVar.filter((item) => item.status === "FAILED").length
            : 0
        );
        setTrxTodayExpired(
          todayVar
            ? todayVar.filter((item) => item.status === "EXPIRED").length
            : 0
        );

        let lastMonthVar = data.data.lastMonth;
        setTrxLastMonth(lastMonthVar ? lastMonthVar.length : 0);
        setTrxLastMonthSuccess(
          lastMonthVar
            ? lastMonthVar.filter((v) => v.status === "SUCCESS").length
            : 0
        );
        setTrxLastMonthPending(
          lastMonthVar
            ? lastMonthVar.filter((v) => v.status === "PENDING").length
            : 0
        );
        setTrxLastMonthExpired(
          lastMonthVar
            ? lastMonthVar.filter((v) => v.status === "EXPIRED").length
            : 0
        );
        setTrxLastMonthFailed(
          lastMonthVar
            ? lastMonthVar.filter((v) => v.status === "FAILED").length
            : 0
        );

        let thisMonthVar = data.data.thisMonth;
        setTrxThisMonth(thisMonthVar ? thisMonthVar.length : 0);
        setTrxThisMonthSuccess(
          thisMonthVar
            ? thisMonthVar.filter((v) => v.status === "SUCCESS").length
            : 0
        );
        setTrxThisMonthPending(
          thisMonthVar
            ? thisMonthVar.filter((v) => v.status === "PENDING").length
            : 0
        );
        setTrxThisMonthExpired(
          thisMonthVar
            ? thisMonthVar.filter((v) => v.status === "EXPIRED").length
            : 0
        );
        setTrxThisMonthFailed(
          thisMonthVar
            ? thisMonthVar.filter((v) => v.status === "FAILED").length
            : 0
        );

        let tdSales = 0;
        data.data.today
          .filter((v) => v.status === "SUCCESS")
          .forEach((v) => {
            tdSales += v.gross_total_payment;
          });
        setTodaySales(tdSales);

        let wSales = 0;
        data.data.thisWeek
          .filter((v) => v.status === "SUCCESS")
          .forEach((v) => {
            wSales += v.gross_total_payment;
          });
        setWeekSales(wSales);

        let lmSales = 0;
        data.data.lastMonth
          .filter((v) => v.status === "SUCCESS")
          .forEach((v) => {
            lmSales += v.gross_total_payment;
          });
        setLastMonthSales(lmSales);

        let mSales = 0;
        data.data.thisMonth
          .filter((v) => v.status === "SUCCESS")
          .forEach((v) => {
            mSales += v.gross_total_payment;
          });
        setMonthSales(mSales);

        let ySales = 0;
        data.data.thisYear
          .filter((v) => v.status === "SUCCESS")
          .forEach((v) => {
            ySales += v.gross_total_payment;
          });
        setYearSales(ySales);

        let tdProfit = 0;
        data.data.today
          .filter((v) => v.status === "SUCCESS")
          .forEach((v) => {
            tdProfit += v.product.margin - v.product.vendor_price;
          });
        setTodayProfit(tdProfit);

        let wProfit = 0;
        data.data.thisWeek
          .filter((v) => v.status === "SUCCESS")
          .forEach((v) => {
            wProfit += v.product.margin - v.product.vendor_price;
          });
        setWeekProfit(wProfit);

        let lmProfit = 0;
        data.data.lastMonth
          .filter((v) => v.status === "SUCCESS")
          .forEach((v) => {
            lmProfit += v.product.margin - v.product.vendor_price;
          });
        setLastMonthProfit(lmProfit);

        let mProfit = 0;
        data.data.thisMonth
          .filter((v) => v.status === "SUCCESS")
          .forEach((v) => {
            mProfit += v.product.margin - v.product.vendor_price;
          });
        setMonthProfit(mProfit);

        let yProfit = 0;
        data.data.thisYear
          .filter((v) => v.status === "SUCCESS")
          .forEach((v) => {
            yProfit += v.product.margin - v.product.vendor_price;
          });
        setYearProfit(yProfit);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    fetchChart();
  }, []);

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charset="utf-8" />
        <meta name="description" content="Dashboard - Admin" />
        <meta
          name="google-site-verification"
          content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34="
        />
        <title>Dashboard - Admin</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Dashboard</h1>
          </div>
          <div className="row">
            <div className="col-xl-3 col-md-6 col-12">
              <div className="card card-statistic-1 d-flex p-2">
                <div className="card-icon bg-primary ml-4 mx-auto">
                  <i className="far fa-user"></i>
                </div>
                <div className="card-wrap">
                  <div className="card-header py-2 text-center">
                    <h4>Transaksi kemarin</h4>
                  </div>
                  <div className="text-center">
                    <h6 className="text-center">Total: {trxYesterday}</h6>
                  </div>
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <div>
                        <div>
                          <span>{trxYesterdaySuccess} </span>
                          <span className="fz-13 text-success">sukses</span>
                        </div>
                        <div>
                          <span>{trxYesterdayPending} </span>
                          <span className="fz-13 text-danger">pending</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <span>{trxYesterdayFailed} </span>
                          <span className="fz-13 text-danger">failed</span>
                        </div>
                        <div>
                          <span>{trxYesterdayExpired} </span>
                          <span className="fz-13 text-danger">expired</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-12">
              <div className="card card-statistic-1 d-flex p-2">
                <div className="card-icon bg-danger ml-4 mx-auto">
                  <i className="far fa-newspaper"></i>
                </div>
                <div className="card-wrap">
                  <div className="card-header py-2 text-center">
                    <h4>Transaksi hari ini</h4>
                  </div>
                  <div className="text-center">
                    <h6 className="text-center">Total: {trxToday}</h6>
                  </div>
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <div>
                        <div>
                          <span>{trxTodaySuccess} </span>
                          <span className="fz-13 text-success">sukses</span>
                        </div>
                        <div>
                          <span>{trxTodayPending} </span>
                          <span className="fz-13 text-danger">pending</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <span>{trxTodayFailed} </span>
                          <span className="fz-13 text-danger">failed</span>
                        </div>
                        <div>
                          <span>{trxTodayExpired} </span>
                          <span className="fz-13 text-danger">expired</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-12">
              <div className="card card-statistic-1 d-flex p-2">
                <div className="card-icon bg-warning ml-4 mx-auto">
                  <i className="far fa-file"></i>
                </div>
                <div className="card-wrap">
                  <div className="card-header py-2 text-center">
                    <h4>Transaksi bulan kemarin</h4>
                  </div>
                  <div className="text-center">
                    <h6 className="text-center">Total: {trxLastMonth}</h6>
                  </div>
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <div>
                        <div>
                          <span>{trxLastMonthSuccess} </span>
                          <span className="fz-13 text-success">sukses</span>
                        </div>
                        <div>
                          <span>{trxLastMonthPending} </span>
                          <span className="fz-13 text-danger">pending</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <span>{trxLastMonthFailed} </span>
                          <span className="fz-13 text-danger">failed</span>
                        </div>
                        <div>
                          <span>{trxLastMonthExpired} </span>
                          <span className="fz-13 text-danger">expired</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-12">
              <div className="card card-statistic-1 d-flex p-2">
                <div className="card-icon bg-success ml-4 mx-auto">
                  <i className="fas fa-circle"></i>
                </div>
                <div className="card-wrap">
                  <div className="card-header py-2 text-center">
                    <h4>Transaksi bulan ini</h4>
                  </div>
                  <div className="text-center">
                    <h6 className="text-center">Total: {trxThisMonth}</h6>
                  </div>
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <div>
                        <div>
                          <span>{trxThisMonthSuccess} </span>
                          <span className="fz-13 text-success">sukses</span>
                        </div>
                        <div>
                          <span>{trxThisMonthPending} </span>
                          <span className="fz-13 text-danger">pending</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <span>{trxThisMonthFailed} </span>
                          <span className="fz-13 text-danger">failed</span>
                        </div>
                        <div>
                          <span>{trxThisMonthExpired} </span>
                          <span className="fz-13 text-danger">expired</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-12 col-12 col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h4>Statistics</h4>
                </div>
                <div className="card-body">
                  <canvas id="myChartTrx" height="182"></canvas>
                  <div className="statistic-details mt-sm-4">
                    <div className="statistic-details-item">
                      <div className="detail-value">{formatRP(todaySales)}</div>
                      <div className="detail-name">Omset Hari Ini</div>
                    </div>
                    <div className="statistic-details-item">
                      <div className="detail-value">{formatRP(weekSales)}</div>
                      <div className="detail-name">Omset Minggu Ini</div>
                    </div>
                    <div className="statistic-details-item">
                      <div className="detail-value">
                        {formatRP(lastMonthSales)}
                      </div>
                      <div className="detail-name">Omset Bulan Kemarin</div>
                    </div>
                    <div className="statistic-details-item">
                      <div className="detail-value">{formatRP(monthSales)}</div>
                      <div className="detail-name">Omset Bulan Ini</div>
                    </div>
                    <div className="statistic-details-item">
                      <div className="detail-value">{formatRP(yearSales)}</div>
                      <div className="detail-name">Omset Tahun Ini</div>
                    </div>
                  </div>
                  <div className="statistic-details mt-sm-4">
                    <div className="statistic-details-item">
                      <div className="detail-value">
                        {formatRP(todayProfit)}
                      </div>
                      <div className="detail-name">Profit Hari Ini</div>
                    </div>
                    <div className="statistic-details-item">
                      <div className="detail-value">{formatRP(weekProfit)}</div>
                      <div className="detail-name">Profit Minggu Ini</div>
                    </div>
                    <div className="statistic-details-item">
                      <div className="detail-value">
                        {formatRP(lastMonthProfit)}
                      </div>
                      <div className="detail-name">Profit Bulan Kemarin</div>
                    </div>
                    <div className="statistic-details-item">
                      <div className="detail-value">
                        {formatRP(monthProfit)}
                      </div>
                      <div className="detail-name">Profit Bulan Ini</div>
                    </div>
                    <div className="statistic-details-item">
                      <div className="detail-value">{formatRP(yearProfit)}</div>
                      <div className="detail-name">Profit Tahun Ini</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-12 col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h4>Recent Activities</h4>
                </div>
                <div className="card-body">
                  <ul className="list-unstyled list-unstyled-border">
                    {dataTrx.slice(0, 5).map((item, index) => (
                      <li
                        key={index}
                        className="media d-flex align-items-center"
                      >
                        <div className="media-body">
                          <div className={`float-right ${item.status === 'PENDING' ? 'text-warning' : item.status === 'SUCCESS' ? 'text-success' : item.status === 'FAILED' ? 'text-danger' : item.status === 'REFUND' ? 'text-secondary' :  'text-primary'}`}>
                            {item.status}
                          </div>
                          <div className="media-title">
                            {item.customer.phone}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div className="text-center pt-1 pb-1">
                    <Link
                      to="/transactions"
                      className="btn btn-primary btn-lg btn-round"
                    >
                      View All
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </HelmetProvider>
  );
};

export default Dashboard;
