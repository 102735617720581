import $ from "jquery";
import swal from "sweetalert";

export default function SweetAlerMessage() {
  $("#swal-1").click(function() {
    swal("Hello");
  });

  $("#swal-2").click(function() {
    swal("Good Job", "You clicked the button!", "success");
  });

  $("#swal-3").click(function() {
    swal("Good Job", "You clicked the button!", "warning");
  });

  $("#swal-4").click(function() {
    swal("Good Job", "You clicked the button!", "info");
  });

  $("#swal-5").click(function() {
    swal("Good Job", "You clicked the button!", "error");
  });

  $("#swal-6").click(function() {
    swal({
      title: "Are you sure?",
      text: "Setelah dihapus, Anda tidak akan dapat memulihkan file ini!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("File Anda telah dihapus!", {
          icon: "success",
        });
      } else {
        swal("Your imaginary file is safe!");
      }
    });
  });

  $("#swal-7").click(function() {
    swal({
      title: "What is your name?",
      content: {
        element: "input",
        attributes: {
          placeholder: "Type your name",
          type: "text",
        },
      },
    }).then((data) => {
      swal("Hello, " + data + "!");
    });
  });

  $("#swal-8").click(function() {
    swal("This modal will disappear soon!", {
      buttons: false,
      timer: 3000,
    });
  });
}
