import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import {
  Link,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom";
import SweetAlerMessage from "../../../js/sweetalert";
import swal from "sweetalert";
import { Helmet, HelmetProvider } from "react-helmet-async";

const SyncPrice = () => {
  const history = useHistory();
  const location = useLocation();

  const handleGoBack = () => {
    if (history.location.pathname === location.pathname) {
      history.goBack();
    }
  };

  const [vendor, setVendor] = useState([]);
  const [gameAuto, setGameAuto] = useState([]);
  const [vendorSelect, setVendorSelect] = useState("");
  const [gameSelect, setGameSelect] = useState("");

  const fetchVendorData = (selectedValue) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/vendor/get/games`, {
      method: "POST",
      body: JSON.stringify({
        id: selectedValue,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, data: data })))
      .then((data) => {
        if (data.status === 200) {
          setGameAuto(data.data.data);
        } else {
          swal({
            title: "Gagal",
            text: data.data.msg,
            icon: "error",
            buttons: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch(`${process.env.REACT_APP_BASE_URL}/api/vendor/sync/products`, {
      method: "POST",
      body: JSON.stringify({
        id: vendorSelect,
        code: gameSelect,
        token: localStorage.getItem("token"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, data: data })))
      .then((data) => {
        if (data.status === 200) {
          swal({
            title: "Berhasil",
            text: "Sync harga berhasil",
            icon: "success",
            buttons: false,
            timer: 1000,
          });
          history.goBack();
        } else {
          swal({
            title: "Gagal",
            text: data.data.msg,
            icon: "error",
            buttons: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    SweetAlerMessage();
    $("[data-height]").each(function() {
      $(this).css({
        height: $(this).data("height"),
      });
    });

    fetch(`${process.env.REACT_APP_BASE_URL}/api/vendor`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json().then((d) => ({ status: r.status, data: d.data })))
      .then((d) => {
        setVendor(d.data);
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  }, [location.pathname]);

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charset="utf-8" />
        <meta name="description" content="Sync Price - Admin" />
        <meta
          name="google-site-verification"
          content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34="
        />
        <title>Sync Price - Admin</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Items</h1>
            <div className="section-header-breadcrumb">
              <div className="breadcrumb-item active">
                <Link to="/dashboard">Dashboard</Link>
              </div>
              <div className="breadcrumb-item">
                <Link to="/products/games">List Items</Link>
              </div>
              <div className="breadcrumb-item" id="title-game">
                Sync Price
              </div>
            </div>
          </div>

          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div
                      className="cursor-pointer d-flex"
                      onClick={handleGoBack}
                    >
                      <span>
                        <i className="fas fa-arrow-left"></i>
                      </span>
                      <h4 className="ml-2">Sync Harga</h4>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="tab-content" id="pills-tabContent">
                      <form onSubmit={handleSubmit}>
                        <div className="otomatis">
                          <Row className="align-items-center">
                            <Col xs={12} lg={6}>
                              <div className="form-group">
                                <label>Vendor</label>
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setVendorSelect(e.target.value);
                                    fetchVendorData(e.target.value);
                                  }}
                                  value={vendorSelect}
                                >
                                  <option value="">Pilih Vendor</option>
                                  {vendor &&
                                    vendor.length > 0 &&
                                    vendor.map((item) => (
                                      <option key={item._id} value={item._id}>
                                        {item.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </Col>
                            <Col xs={12} lg={6}>
                              <div className="form-group">
                                <label>Game</label>
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setGameSelect(e.target.value);
                                  }}
                                  value={gameSelect}
                                >
                                  <option value="">Pilih Game</option>
                                  {gameAuto &&
                                    gameAuto.map((item) => {
                                      if (item.active) {
                                        return (
                                          <option
                                            key={item.code}
                                            value={item.code}
                                          >
                                            {item.name}
                                          </option>
                                        );
                                      }
                                      return null;
                                    })}
                                </select>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="card-footer text-right">
                          <button
                            className="btn btn-primary mr-1"
                            type="submit"
                          >
                            Submit
                          </button>
                          <Link
                            to="/products/items"
                            className="btn btn-secondary"
                            type="button"
                          >
                            Batal
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </HelmetProvider>
  );
};

export default SyncPrice;
