import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import SweetAlerMessage from "../../js/sweetalert";
import $ from "jquery";
import swal from "sweetalert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ThreeDots } from "react-loader-spinner";
import { Helmet, HelmetProvider } from "react-helmet-async";

const ListAssets = () => {
  const [assets, setAssets] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory({
    forceRefresh: true,
  });

  async function fetchData() {
    await fetch(`${process.env.REACT_APP_BASE_URL}/api/asset`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAssets(data.data);
        setLoading(false);
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: false,
        });
      });
  }

  const btnDel = (idAssets) => {
    let id = idAssets;
    swal({
      title: "Are you sure?",
      text: "Setelah dihapus, Anda tidak akan dapat memulihkan file ini!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        $.ajax({
          method: "DELETE",
          url: `${
            process.env.REACT_APP_BASE_URL
          }/api/asset/${id}?token=${localStorage.getItem("token")}`,
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
          success: function(d, x, r) {
            if (r.status === 200) {
              swal("File Anda telah dihapus!", {
                icon: "success",
                timer: 1000,
                button: false,
              });
              fetchData();
            }
          },
          error: function(xhr, status, error) {
            swal(error, {
              icon: "error",
              button: false,
            });
          },
        });
      }
    });
  };

  useEffect(() => {
    fetchData();
    SweetAlerMessage();
    setLoading(true);
  }, [history]);

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charset="utf-8" />
        <meta name="description" content="Assets - Admin" />
        <meta
          name="google-site-verification"
          content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34="
        />
        <title>Assets - Admin</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Assets</h1>
            <div className="section-header-breadcrumb">
              <div className="breadcrumb-item active">
                <Link to="/dashboard">Dashboard</Link>
              </div>
              <div className="breadcrumb-item">List Assets</div>
            </div>
          </div>

          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-between">
                    <h4>List Assets</h4>
                    <Link to="/asset/add-asset">
                      <Button className="create-new">
                        <i className="fas fa-plus me-sm-1 text-light"></i>{" "}
                        <span className="d-none d-sm-inline-block text-light">
                          Tambah Assets
                        </span>
                      </Button>
                    </Link>
                  </div>
                  <div className="card-body">
                    {loading ? (
                      <div className="d-flex justify-content-center">
                        <ThreeDots
                          height="60"
                          width="60"
                          radius="9"
                          color="#4fa94d"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          visible={true}
                        />
                      </div>
                    ) : (
                      <div className="d-flex flex-wrap">
                        {assets &&
                          assets
                            .sort(
                              (a, b) =>
                                new Date(b.updatedAt) - new Date(a.updatedAt)
                            )
                            .map((item, index) => (
                              <div
                                key={index}
                                className="cursor-pointer d-flex flex-column mr-3 mb-3 badge-assets fz-12"
                              >
                                <img
                                  src={item.url}
                                  alt={item.name}
                                  className="assets-img img-thumbnail"
                                />
                                <button
                                  type="button"
                                  className="close-assets btnDel"
                                  data-id={item._id}
                                  onClick={() => btnDel(item._id)}
                                >
                                  ×
                                </button>
                                <span className="fz-13 ellipsis">
                                  {item.name}
                                </span>
                              </div>
                            ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </HelmetProvider>
  );
};

export default ListAssets;
