import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import ModuleItem from "../../../js/ModuleItem";
import { Link } from "react-router-dom";
import SweetAlerMessage from "../../../js/sweetalert";
import $ from "jquery";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Helmet, HelmetProvider } from "react-helmet-async";

const ListItems = () => {
  const history = useHistory();

  useEffect(() => {
    ModuleItem();
    SweetAlerMessage();

    $("#item-table").on("click", ".btnEdit", function() {
      const id = $(this).data("id");
      history.push(`/products/items/add-items/${id}`);
    });
  }, [history]);

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charset="utf-8" />
        <meta name="description" content="Items - Admin" />
        <meta
          name="google-site-verification"
          content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34="
        />
        <title>Items - Admin</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Items</h1>
            <div className="section-header-breadcrumb">
              <div className="breadcrumb-item active">
                <Link to="/dashboard">Dashboard</Link>
              </div>
              <div className="breadcrumb-item">List Items</div>
            </div>
          </div>

          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-between">
                    <h4>List Items</h4>
                    <div>
                      <Link to="/products/items/sync-price">
                        <Button className="create-new">
                          <i className="fas fa-edit me-sm-2 text-light"></i>
                          <span className="d-none d-sm-inline-block text-light">
                            Sync Harga
                          </span>
                        </Button>
                      </Link>
                      <Link to="/products/items/sync-vendor">
                        <Button className="create-new">
                          <i className="fas fa-edit me-sm-2 text-light"></i>
                          <span className="d-none d-sm-inline-block text-light">
                            Sync Vendor
                          </span>
                        </Button>
                      </Link>
                      <Link to="/products/items/edit-bulk">
                        <Button className="create-new">
                          <i className="fas fa-edit me-sm-2 text-light"></i>
                          <span className="d-none d-sm-inline-block text-light">
                            Edit Bulk
                          </span>
                        </Button>
                      </Link>
                      <Link to="/products/items/add-items">
                        <Button className="create-new">
                          <i className="fas fa-plus me-sm-2 text-light"></i>{" "}
                          <span className="d-none d-sm-inline-block text-light">
                            Tambah Item
                          </span>
                        </Button>
                      </Link>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped" id="item-table">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th className="d-flex flex-column">
                              <span>Game</span>
                              <span>Nama Produk</span>
                            </th>
                            <th>Kode</th>
                            <th className="d-flex flex-column">
                              <span>Harga Jual</span>
                              <span>Harga Modal</span>
                            </th>
                            <th>Vendor</th>
                            <th>Status</th>
                            <th>Opsi</th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </HelmetProvider>
  );
};

export default ListItems;
