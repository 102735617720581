import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import SweetAlerMessage from "../../js/sweetalert";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import { Helmet, HelmetProvider } from "react-helmet-async";

const AddAssets = () => {
  const { register, handleSubmit } = useForm();
  const [name, setName] = useState("");

  const history = useHistory();

  const onSubmit = (data) => {
    const formData = new FormData();
    Array.from(data.file).forEach ((v,i) =>{
      formData.append("image",v);

    } )  
    formData.append("name", name);
    // formData.append("token", localStorage.getItem("token"));

    fetch(`${process.env.REACT_APP_BASE_URL}/api/asset/`, {
      method: "POST",
      body: formData,
      headers: {
        session: localStorage.getItem("token"),
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, data: data })))
      .then((data) => {
        if (data.status === 200) {
          swal({
            title: "Berhasil",
            text: "Asset Berhasil Ditambahkan",
            icon: "success",
            buttons: false,
            timer: 1000,
          });
          history.push("/asset");
        } else {
          swal({
            title: "Gagal",
            text: data.data.msg,
            icon: "error",
            buttons: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  };

  useEffect(() => {
    SweetAlerMessage();
    $("[data-height]").each(function() {
      $(this).css({
        height: $(this).data("height"),
      });
    });
  }, []);

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charset="utf-8" />
        <meta name="description" content="Add Assets - Admin" />
        <meta
          name="google-site-verification"
          content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34="
        />
        <title>Add Assets - Admin</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Assets</h1>
            <div className="section-header-breadcrumb">
              <div className="breadcrumb-item active">
                <Link to="/dashboard">Dashboard</Link>
              </div>
              <div className="breadcrumb-item">
                <Link to="/payments">List Assets</Link>
              </div>
              <div className="breadcrumb-item" id="title-game">
                Add Assets
              </div>
            </div>
          </div>

          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <form className="card" onSubmit={handleSubmit(onSubmit)}>
                  <div className="card-header">
                    <Link to="/asset" className="d-flex ">
                      <span>
                        <i className="fas fa-arrow-left"></i>
                      </span>
                      <h4 className="ml-2">Assets</h4>
                    </Link>
                  </div>
                  <div className="card-body">
                    <Row>
                      <Col xs={12}>
                        <div className="form-group">
                          <label>Image</label>
                          <div className="input-group mb-3">
                            <input
                              type="file"
                              accept="image/png, image/jpg, image/jpeg"
                              className="form-control"
                              placeholder="Upload Image"
                              multiple
                              {...register("file")}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <div className="form-group">
                          <label>Nama</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            placeholder="Masukkan Nama"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="card-footer text-right">
                    <button className="btn btn-primary mr-1" type="submit">
                      Submit
                    </button>
                    <Link
                      to="/asset"
                      className="btn btn-secondary"
                      type="button"
                    >
                      Batal
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </HelmetProvider>
  );
};

export default AddAssets;
