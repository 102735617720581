import $ from "jquery";
import Chart from "chart.js";
import moment from "moment";

export const ChartGeneral = (data) => {
  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let total = [0, 0, 0, 0, 0, 0, 0];

  data.forEach((element) => {
    if (element.status === "SUCCESS") {
      let dayIndex = moment(element.createdAt).day();
      total[dayIndex]++;
    }
  });

  var statistics_chart = document.getElementById("myChartTrx").getContext("2d");

  new Chart(statistics_chart, {
    type: "line",
    data: {
      labels: days,
      datasets: [
        {
          label: "Statistics",
          data: total,
          borderWidth: 5,
          borderColor: "#6777ef",
          backgroundColor: "transparent",
          pointBackgroundColor: "#fff",
          pointBorderColor: "#6777ef",
          pointRadius: 4,
        },
      ],
    },
    options: {
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              stepSize: 150,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              color: "#fbfbfb",
              lineWidth: 2,
            },
          },
        ],
      },
    },
  });

  $("[data-width]").each(function() {
    $(this).css({
      width: $(this).data("width"),
    });
  });

  $("[data-height]").each(function() {
    $(this).css({
      height: $(this).data("height"),
    });
  });
};
