import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import {
  Link,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom";
import ModalIcons from "./ModalIcons";
import SweetAlerMessage from "../../../js/sweetalert";
import swal from "sweetalert";
import { Helmet, HelmetProvider } from "react-helmet-async";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";

const AddGames = () => {
  const history = useHistory();
  const location = useLocation();

  const handleGoBack = () => {
    if (window.location.pathname === "/products/games/add-games") {
      history.goBack();
    } else {
         history.goBack();
    }
  };

  const [loading, setLoading] = useState(true);

  const [show, setShow] = useState(false);
  const [vendor, setVendor] = useState([]);
  const [game, setGame] = useState("");
  const [vendorSelect, setVendorSelect] = useState("");
  const [gameSelect, setGameSelect] = useState("");
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [developer, setDeveloper] = useState("");
  const [typeProduct, setTypeProduct] = useState("");
  const [urlManual, SetUrlManual] = useState("");
  const [category, setCategory] = useState("");
  const [icon, setIcon] = useState("");
  const [cover, setCover] = useState("");
  const [banner, setBanner] = useState("");
  const { quill: quillOtomatis, quillRef: quillRefOtomatis } = useQuill();
  const { quill: quillManual, quillRef: quillRefManual } = useQuill();
  const [description, setDescription] = useState("");
  const [formValues, setFormValues] = useState({
    fields: [
      {
        name: "",
        placeholder: "",
        type: "",
        options: [],
      },
    ],
    check: "",
  });
  const [checkGame, setCheckGame] = useState("");
  const [status, setStatus] = useState(true);
  const [trending, setTrending] = useState(false);
  const [assets, setAssets] = useState("");
  const [select, setSelect] = useState("");
  const [input1Value, setInput1Value] = useState({});
  const [input2Value, setInput2Value] = useState({});
  const [data, setData] = useState({});
  const [display, setDisplay] = useState({
    detail: "none",
  });
  const [displayStatus, setDisplayStatus] = useState({
    detail: "none",
  });

  const [urlAuto, setUrlAuto] = useState(
    `${process.env.REACT_APP_BASE_URL}/api/game/bulk`
  );
  const [url, setUrl] = useState(`${process.env.REACT_APP_BASE_URL}/api/game/`);
  const [method, setMethod] = useState("POST");
  const [title, setTitle] = useState("Tambah Game");
  const [msg, setMsg] = useState("Game berhasil ditambahkan");

  const nameRef = useRef([]);
  const phRef = useRef([]);
  const tipeRef = useRef([]);
  const optValRef = useRef([]);
  const optNameRef = useRef([]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/asset`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAssets(data.data);
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
    setShow(true);
  };

  const assetsSelect = (url) => () => {
    if (select === "icon") {
      setIcon(url);
    } else if (select === "cover") {
      setCover(url);
    } else if (select === "banner") {
      setBanner(url);
    }
    handleClose();
  };

  const [optState, setOptState] = useState({});
  const [dummyState, setDummyState] = useState("");

  let renderOpt = (index) => {
    let opt = [];
    let fullData = data;
    fullData[`form${index}`].forEach((element, i) => {
      opt.push(
        <span key={i} className="badge-custom fz-12">
          {element.text || "blank"}
          <button
            type="button"
            className="close-custom d-flex align-items-center justify-content-center"
            onClick={() => closeOpt(index, i)}
          >
            ×
          </button>
        </span>
      );
    });
    let opts = optState;
    opts[`form${index}`] = opt;
    setOptState(opts);
  };

  let closeOpt = (index, i) => {
    let fullData = data;
    fullData[`form${index}`].splice(i, 1);
    setData(fullData);
    renderOpt(index);
    setDummyState(new Date());
  };

  let addOpt = (i) => {
    setDisplay({ ...display, detail: "block" });
    let value = input1Value[`form${i}`];
    let text = input2Value[`form${i}`];
    let fullData = data;
    if (fullData[`form${i}`] === undefined) {
      fullData[`form${i}`] = [];
    }
    fullData[`form${i}`].push({
      value: value,
      text: text,
    });
    setData(fullData);
    setInput1Value((val) => ({
      ...val,
      [`form${i}`]: "",
    }));
    setInput2Value((val) => ({
      ...val,
      [`form${i}`]: "",
    }));
    renderOpt(i);
  };

  let handleChange = (i, e) => {
    let newFormValues = [...formValues.fields];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues({
      ...formValues,
      fields: newFormValues,
    });
    setInput1Value((val) => ({
      ...val,
      [`form${i}`]: "",
    }));
    setInput2Value((val) => ({
      ...val,
      [`form${i}`]: "",
    }));
    setData((val) => ({ ...val, [`form${i}`]: [] }));
  };

  let addFormFields = () => {
    let newFormValues = [...formValues.fields];
    newFormValues.push({
      name: "",
      placeholder: "",
      type: "",
      options: [],
    });
    setFormValues({
      ...formValues,
      fields: newFormValues,
    });
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues.fields];
    newFormValues.splice(i, 1);
    setFormValues({
      ...formValues,
      fields: newFormValues,
    });
  };

  const handleClearItem = () => {
    // setIcon("");
    // setGameSelect("");
    // setName("");
    // setCode("");
    // setCategory("");
    // setVendorSelect("");
    // setVendorPrice("");
    // setTypePrice("");
    // setGameTo("");
    // setPrice("");
    // setStatus(true);
  };

  const fetchVendorData = (selectedValue) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/vendor/get/games`, {
      method: "POST",
      body: JSON.stringify({
        id: selectedValue,
        token: localStorage.getItem("token"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, data: data })))
      .then((data) => {
        if (data.status === 200) {
          setGame(data.data.data);
        } else {
          swal({
            title: "Gagal",
            text: data.data.msg,
            icon: "error",
            buttons: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  };

  const btnAuto = () => {
    let datas = game.find((item) => item.code === gameSelect);
    if (datas) {
      setName(datas.name);
      setCode(datas.code);
      let options = [];
      for (let i = 0; i < datas.inputs.length; i++) {
        if (datas.inputs[i].type === "select") {
          let fullData = data;
          if (fullData[`form${i}`] === undefined) {
            fullData[`form${i}`] = [];
          }
          options.push({ index: i, options: [] });
          datas.inputs[i].options.forEach((v) => {
            fullData[`form${i}`].push({
              value: v.value,
              text: v.title,
            });
            options
              .find((v) => (v.index = i))
              .options.push({
                value: v.value,
                text: v.title,
              });
          });
          setData(fullData);
          setInput1Value((val) => ({
            ...val,
            [`form${i}`]: "",
          }));
          setInput2Value((val) => ({
            ...val,
            [`form${i}`]: "",
          }));
          renderOpt(i);
        }
      }
      setFormValues({
        fields: datas.inputs.map((value, index) => {
          return {
            name: value.name,
            placeholder: value.name || "",
            type: value.type,
            options: options.find((v) => v.index == index)?.options || null,
          };
        }),
      });
    }
  };

  const handleSubmitAuto = (e) => {
    e.preventDefault();
    fetch(url, {
      method: method,
      body: JSON.stringify({
        name,
        code,
        developer,
        type: typeProduct,
        url: urlManual,
        category,
        icon,
        cover,
        banner,
        description,
        trending,
        status,
        form: {
          fields: formValues.fields.map((element, index) => {
            return {
              name: nameRef.current[index].value,
              placeholder: phRef.current[index].value,
              type: tipeRef.current[index].value,
              options: data[`form${index}`],
            };
          }),
          check: checkGame,
        },
        token: localStorage.getItem("token"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          data: data,
        }))
      )
      .then((data) => {
        if (data.status === 200) {
          swal({
            title: "Berhasil",
            text: msg,
            icon: "success",
            buttons: false,
            timer: 1000,
          });
          if (window.location.pathname === "/products/games/add-games") {
            history.goBack();
          } else {
            history.goBack();
            history.goBack();
          }
        } else {
          swal({
            title: "Gagal",
            text: data.data.msg,
            icon: "error",
            buttons: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  };

  const handleSubmitManual = (e) => {
    e.preventDefault();
    fetch(url, {
      method: method,
      body: JSON.stringify({
        name,
        code,
        developer,
        type: typeProduct,
        url: urlManual,
        category,
        icon,
        cover,
        banner,
        description,
        trending,
        status,
        form: {
          fields: formValues.fields.map((element, index) => {
            return {
              name: nameRef.current[index].value,
              placeholder: phRef.current[index].value,
              type: tipeRef.current[index].value,
              options: data[`form${index}`],
            };
          }),
          check: checkGame,
        },
        token: localStorage.getItem("token"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          data: data,
        }))
      )
      .then((data) => {
        if (data.status === 200) {
          swal({
            title: "Berhasil",
            text: msg,
            icon: "success",
            buttons: false,
            timer: 1000,
          });
          if (window.location.pathname === "/products/games/add-games") {
            history.goBack();
          } else {
            history.goBack();
            history.goBack();
          }
        } else {
          swal({
            title: "Gagal",
            text: data.data.msg,
            icon: "error",
            buttons: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    SweetAlerMessage();
    $("[data-height]").each(function() {
      $(this).css({
        height: $(this).data("height"),
      });
    });

    if (quillOtomatis) {
      quillOtomatis.on("text-change", (delta, oldDelta, source) => {
        if (source === "user") {
          setDescription(quillOtomatis.root.innerHTML);
        }
      });
    }

    if (quillManual) {
      quillManual.on("text-change", (delta, oldDelta, source) => {
        if (source === "user") {
          setDescription(quillManual.root.innerHTML);
        }
      });
    }

    fetch(`${process.env.REACT_APP_BASE_URL}/api/vendor`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json().then((d) => ({ status: r.status, data: d.data })))
      .then((d) => {
        setVendor(d.data);
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });

    if (window.location.pathname === "/products/games/add-games") {
      setUrlAuto(`${process.env.REACT_APP_BASE_URL}/api/game/bulk`);
      setUrl(`${process.env.REACT_APP_BASE_URL}/api/game/`);
      setMethod("POST");
      setLoading(false);
      $(".component-add").show();
      $(".form-otomatis").hide();
    } else {
      $(".component-add").hide();
      setDisplayStatus({
        ...displayStatus,
        detail: "block",
      });
      setUrl(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/game/${window.location.pathname.split("/").pop()}`
      );
      setMethod("PUT");

      fetch(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/game?id=${window.location.pathname.split("/").pop()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((r) =>
          r.json().then((d) => ({
            status: r.status,
            data: d.data,
          }))
        )
        .then((d) => {
          setName(d.data.name);
          setCode(d.data.code);
          setDeveloper(d.data.developer);
          setTypeProduct(d.data.type);
          SetUrlManual(d.data.url);
          setCategory(d.data.category);
          setIcon(d.data.icon);
          setCover(d.data.cover);
          setBanner(d.data.banner);
          setDescription(d.data.description);
          setLoading(false);
          setCheckGame(d.data.form.check);
          setTrending(d.data.trending);
          setStatus(d.data.status);
          for (let i = 0; i < d.data.form.fields.length; i++) {
            if (d.data.form.fields[i].type === "select") {
              let opt = data;
              opt[`form${i}`] = d.data.form.fields[i].options;
              setData(opt);
              renderOpt(i);
            }
          }
          setFormValues({
            fields: d.data.form.fields.map((element) => {
              return {
                name: element.name,
                placeholder: element.placeholder,
                type: element.type,
                options: element.options || null,
              };
            }),
            check: d.data.form.check,
          });
          if (quillOtomatis) {
            quillOtomatis.clipboard.dangerouslyPasteHTML(d.data.description);
          }
          if (quillManual) {
            quillManual.clipboard.dangerouslyPasteHTML(d.data.description);
          }
        })
        .catch((err) => {
          swal(err.message, {
            icon: "error",
            button: false,
          });
        });
      setLoading(false);
      setTitle("Edit Game");
      setMsg("Game berhasil diubah");
    }
  }, [quillOtomatis, quillManual, location.pathname]);

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charset="utf-8" />
        <meta name="description" content="Add Games - Admin" />
        <meta
          name="google-site-verification"
          content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34="
        />
        <title>Add Games - Admin</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Games</h1>
            <div className="section-header-breadcrumb">
              <div className="breadcrumb-item active">
                <Link to="/dashboard">Dashboard</Link>
              </div>
              <div className="breadcrumb-item">
                <Link to="/products/games">List Games</Link>
              </div>
              <div className="breadcrumb-item" id="title-game">
                Add Games
              </div>
            </div>
          </div>

          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div
                      className="cursor-pointer d-flex"
                      onClick={handleGoBack}
                    >
                      <span>
                        <i className="fas fa-arrow-left"></i>
                      </span>
                      <h4 className="ml-2">{title}</h4>
                    </div>
                  </div>
                  <div className="card-body">
                    <ul
                      className="nav nav-pills mb-4 gap component-add"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item mr-2" onClick={handleClearItem}>
                        <a
                          className="nav-link btn-sm active"
                          id="pills-otomatis-tab"
                          data-toggle="pill"
                          href="#pills-otomatis"
                          role="tab"
                          aria-controls="pills-otomatis"
                          aria-selected="true"
                        >
                          Otomatis
                        </a>
                      </li>
                      <li className="nav-item mr-2" onClick={handleClearItem}>
                        <a
                          className="nav-link btn-sm"
                          id="pills-manual-tab"
                          data-toggle="pill"
                          href="#pills-manual"
                          role="tab"
                          aria-controls="pills-manual"
                          aria-selected="false"
                        >
                          Manual
                        </a>
                      </li>
                    </ul>

                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-otomatis"
                        role="tabpanel"
                        aria-labelledby="pills-otomatis-tab"
                      >
                        <form onSubmit={handleSubmitAuto}>
                          <div className="otomatis">
                            <Row className="align-items-center">
                              <Col xs={12} lg={6}>
                                <div className="form-group">
                                  <label>Vendor</label>
                                  <select
                                    className="form-control"
                                    onChange={(e) => {
                                      setVendorSelect(e.target.value);
                                      fetchVendorData(e.target.value);
                                    }}
                                    value={vendorSelect}
                                  >
                                    <option value="">Pilih Vendor</option>
                                    {vendor &&
                                      vendor.length > 0 &&
                                      vendor.map((item) => (
                                        <option key={item._id} value={item._id}>
                                          {item.name}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </Col>
                              <Col xs={12} lg={5}>
                                <div className="form-group">
                                  <label>Game</label>
                                  <select
                                    className="form-control"
                                    onChange={(e) => {
                                      setGameSelect(e.target.value);
                                    }}
                                    value={gameSelect}
                                  >
                                    <option value="">Pilih Game</option>
                                    {game &&
                                      game.map((item) => {
                                        if (item.active) {
                                          return (
                                            <option
                                              key={item.code}
                                              value={item.code}
                                            >
                                              {item.name}
                                            </option>
                                          );
                                        }
                                        return null;
                                      })}
                                  </select>
                                </div>
                              </Col>
                              <Col xs={12} lg={1}>
                                <Button
                                  variant="primary"
                                  className="mt-2"
                                  onClick={btnAuto}
                                >
                                  Cari
                                </Button>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} lg={4}>
                                <div className="form-group">
                                  <label>Nama</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                    placeholder="Masukkan Nama"
                                  />
                                </div>
                              </Col>
                              <Col xs={12} lg={4}>
                                <div className="form-group">
                                  <label>Kode</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => setCode(e.target.value)}
                                    value={code}
                                    placeholder="Masukkan Kode"
                                  />
                                </div>
                              </Col>
                              <Col xs={12} lg={4}>
                                <div className="form-group">
                                  <label>Developer</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) =>
                                      setDeveloper(e.target.value)
                                    }
                                    value={developer}
                                    placeholder="Masukkan Developer"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} lg={6}>
                                <div className="form-group">
                                  <label>Tipe Produk</label>
                                  <select
                                    className="form-control"
                                    onChange={(e) =>
                                      setTypeProduct(e.target.value)
                                    }
                                    value={typeProduct}
                                  >
                                    <option value="">Pilih Tipe</option>
                                    <option value="otomatis">Otomatis</option>
                                    <option value="manual">Manual</option>
                                  </select>
                                </div>
                              </Col>
                              <Col xs={12} lg={6}>
                                <div className="form-group">
                                  <label>Kategori</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) =>
                                      setCategory(e.target.value)
                                    }
                                    value={category}
                                    placeholder="Masukkan Kategori"
                                  />
                                </div>
                              </Col>
                              <Col xs={12} lg={12}>
                                <div
                                  style={{
                                    display:
                                      typeProduct === "manual"
                                        ? "block"
                                        : "none",
                                  }}
                                  className="form-group"
                                >
                                  <label>Url WA (Manual)</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) =>
                                      SetUrlManual(e.target.value)
                                    }
                                    value={urlManual}
                                    placeholder="Masukkan Url WA Tipe Manual"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row
                              style={{
                                display:
                                  typeProduct === "manual" ? "none" : "block",
                              }}
                            >
                              <Col>
                                <div className="form-group">
                                  <label>Deskripsi</label>
                                  <div ref={quillRefOtomatis} />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12}>
                                <div className="form-group">
                                  <div className="d-flex">
                                    <label className="mr-2">Icon</label>
                                    <OverlayTrigger
                                      key={"top"}
                                      top={"top"}
                                      overlay={
                                        <Tooltip id={`tooltip-${"top"}`}>
                                          <img
                                            src={`${process.env.REACT_APP_STATIC_SRC}/prev-Icon_1688384691622.webp`}
                                            alt="icon"
                                            width="400px"
                                          />
                                        </Tooltip>
                                      }
                                    >
                                      <div>
                                        <i className="fas fa-question-circle"></i>
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                  <div className="input-group mb-3">
                                    <input
                                      type="text"
                                      className="form-control url"
                                      placeholder="Masukkan Link Icon"
                                      onChange={(e) => setIcon(e.target.value)}
                                      value={icon}
                                      disabled
                                    />
                                    <div className="input-group-append">
                                      <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() => {
                                          handleShow();
                                          setSelect("icon");
                                        }}
                                      >
                                        Pilih File
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col
                                xs={12}
                                style={{
                                  display:
                                    typeProduct === "manual" ? "none" : "block",
                                }}
                              >
                                <div className="form-group">
                                  <div className="d-flex">
                                    <label className="mr-2">Cover</label>
                                    <OverlayTrigger
                                      key={"top"}
                                      top={"top"}
                                      overlay={
                                        <Tooltip id={`tooltip-${"top"}`}>
                                          <img
                                            src={`${process.env.REACT_APP_STATIC_SRC}/prevCover_1688384640029.webp`}
                                            alt="cover"
                                            width="400px"
                                          />
                                        </Tooltip>
                                      }
                                    >
                                      <div>
                                        <i className="fas fa-question-circle"></i>
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                  <div className="input-group mb-3">
                                    <input
                                      type="text"
                                      className="form-control url"
                                      placeholder="Masukkan Link Cover"
                                      onChange={(e) => setCover(e.target.value)}
                                      value={cover}
                                      disabled
                                    />
                                    <div className="input-group-append">
                                      <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() => {
                                          handleShow();
                                          setSelect("cover");
                                        }}
                                      >
                                        Pilih File
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col
                                xs={12}
                                style={{
                                  display:
                                    typeProduct === "manual" ? "none" : "block",
                                }}
                              >
                                <div className="form-group">
                                  <div className="d-flex">
                                    <label className="mr-2">Banner</label>
                                    <OverlayTrigger
                                      key={"top"}
                                      top={"top"}
                                      overlay={
                                        <Tooltip id={`tooltip-${"top"}`}>
                                          <img
                                            src={`${process.env.REACT_APP_STATIC_SRC}/prevBanner_1688384630309.webp`}
                                            alt="banner"
                                            width="400px"
                                          />
                                        </Tooltip>
                                      }
                                    >
                                      <div>
                                        <i className="fas fa-question-circle"></i>
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                  <div className="input-group mb-3">
                                    <input
                                      type="text"
                                      className="form-control url"
                                      placeholder="Masukkan Link Icon"
                                      onChange={(e) =>
                                        setBanner(e.target.value)
                                      }
                                      value={banner}
                                      disabled
                                    />
                                    <div className="input-group-append">
                                      <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() => {
                                          handleShow();
                                          setSelect("banner");
                                        }}
                                      >
                                        Pilih File
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <div
                              style={{
                                display:
                                  typeProduct === "manual" ? "none" : "block",
                              }}
                            >
                              {formValues.fields.map((element, index) => (
                                <Row key={index} className="bg-light py-3 mb-3">
                                  <Col md={3}>
                                    <div className="form-group">
                                      <label>Nama</label>
                                      <input
                                        ref={(ref) => {
                                          nameRef.current[index] = ref;
                                        }}
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => handleChange(index, e)}
                                        value={element.name}
                                        placeholder="Nama"
                                      />
                                    </div>
                                  </Col>
                                  <Col md={3}>
                                    <div className="form-group">
                                      <label>Placeholder</label>
                                      <input
                                        ref={(ref) => {
                                          phRef.current[index] = ref;
                                        }}
                                        name="placeholder"
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => handleChange(index, e)}
                                        value={element.placeholder}
                                        placeholder="Placeholder"
                                      />
                                    </div>
                                  </Col>
                                  <Col md={2}>
                                    <div className="form-group">
                                      <label>Tipe</label>
                                      <select
                                        ref={(ref) => {
                                          tipeRef.current[index] = ref;
                                        }}
                                        name="type"
                                        className="form-control"
                                        onChange={(e) => handleChange(index, e)}
                                        value={element.type}
                                      >
                                        <option value="">Pilih Tipe</option>
                                        <option value="number">Number</option>
                                        <option value="text">Text</option>
                                        <option value="email">Email</option>
                                        <option value="password">
                                          Password
                                        </option>
                                        <option value="select">Select</option>
                                      </select>
                                    </div>
                                  </Col>
                                  {element.type === "select" ? (
                                    <Col md={6}>
                                      <div className="form-group">
                                        <label>Opsi</label>
                                        <div className="form-group d-flex align-items-center">
                                          <input
                                            ref={(ref) => {
                                              optValRef.current[index] = ref;
                                            }}
                                            name="value"
                                            type="text"
                                            className="form-control mr-2"
                                            onChange={(e) => {
                                              setInput1Value((val) => ({
                                                ...val,
                                                [`form${index}`]: e.target
                                                  .value,
                                              }));
                                            }}
                                            value={
                                              input1Value[`form${index}`] || ""
                                            }
                                            placeholder="Value"
                                          />
                                          <input
                                            ref={(ref) => {
                                              optNameRef.current[index] = ref;
                                            }}
                                            name="valueName"
                                            type="text"
                                            className="form-control mr-2"
                                            onChange={(e) => {
                                              setInput2Value((val) => ({
                                                ...val,
                                                [`form${index}`]: e.target
                                                  .value,
                                              }));
                                            }}
                                            value={
                                              input2Value[`form${index}`] || ""
                                            }
                                            placeholder="Nama"
                                          />
                                          {/* <span onClick={(e) => addOpt(index)}>
                                  <i className="fas fa-plus"></i>
                                </span> */}
                                          <Button
                                            className="button add"
                                            variant="success"
                                            type="button"
                                            onClick={(e) => addOpt(index)}
                                          >
                                            Add
                                          </Button>
                                        </div>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  <Col md={1}>
                                    {index ? (
                                      <Button
                                        className="button add btnRemove"
                                        variant="danger"
                                        type="button"
                                        onClick={() => removeFormFields(index)}
                                      >
                                        Remove
                                      </Button>
                                    ) : null}
                                  </Col>
                                  <Col md={12}>
                                    <div className={`form${index}`}>
                                      {optState[`form${index}`]
                                        ? optState[`form${index}`].map(
                                            (element) => {
                                              return element;
                                            }
                                          )
                                        : null}
                                    </div>
                                  </Col>
                                </Row>
                              ))}
                              <Button
                                className="my-3 button add"
                                variant="primary"
                                type="button"
                                onClick={() => addFormFields()}
                              >
                                +
                              </Button>
                              <Row>
                                <Col xs={12} lg={4}>
                                  <div className="form-group">
                                    <label>Check Game</label>
                                    <select
                                      className="form-control"
                                      onChange={(e) =>
                                        setCheckGame(e.target.value)
                                      }
                                      value={checkGame}
                                    >
                                      <option value="">Pilih Game</option>
                                      <option value="ML">Mobile Legends</option>
                                      <option value="FF">Free Fire</option>
                                    </select>
                                  </div>
                                </Col>
                                <Col xs={12} lg={4}>
                                <div className="form-group">
                                    <label>Game Trending</label>
                                    <select
                                      className="form-control"
                                      onChange={(e) =>
                                        setTrending(e.target.value)
                                      }
                                      value={trending}
                                    >
                                      <option disabled>Pilih Status</option>
                                      <option value={true}>Aktif</option>
                                      <option value={false}>Nonaktif</option>
                                    </select>
                                  </div>
                                  </Col>
                                <Col
                                  xs={12}
                                  lg={4}
                                  style={{
                                    display: displayStatus.detail,
                                  }}
                                >
                                  <div className="form-group">
                                    <label>Status</label>
                                    <select
                                      className="form-control"
                                      onChange={(e) =>
                                        setStatus(e.target.value)
                                      }
                                      value={status}
                                    >
                                      <option disabled>Pilih Status</option>
                                      <option value={true}>Aktif</option>
                                      <option value={false}>Nonaktif</option>
                                    </select>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                          <div className="card-footer text-right">
                            <button
                              className="btn btn-primary mr-1"
                              type="submit"
                            >
                              Submit
                            </button>
                            <Link
                              to="/products/games"
                              className="btn btn-secondary"
                              type="button"
                            >
                              Batal
                            </Link>
                          </div>
                        </form>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-manual"
                        role="tabpanel"
                        aria-labelledby="pills-manual-tab"
                      >
                        <form onSubmit={handleSubmitManual}>
                          <div className="manual">
                            <Row>
                              <Col xs={12} lg={4}>
                                <div className="form-group">
                                  <label>Nama</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                    placeholder="Masukkan Nama"
                                  />
                                </div>
                              </Col>
                              <Col xs={12} lg={4}>
                                <div className="form-group">
                                  <label>Kode</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => setCode(e.target.value)}
                                    value={code}
                                    placeholder="Masukkan Kode"
                                  />
                                </div>
                              </Col>
                              <Col xs={12} lg={4}>
                                <div className="form-group">
                                  <label>Developer</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) =>
                                      setDeveloper(e.target.value)
                                    }
                                    value={developer}
                                    placeholder="Masukkan Developer"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} lg={6}>
                                <div className="form-group">
                                  <label>Tipe Produk</label>
                                  <select
                                    className="form-control"
                                    onChange={(e) =>
                                      setTypeProduct(e.target.value)
                                    }
                                    value={typeProduct}
                                  >
                                    <option value="">Pilih Tipe</option>
                                    <option value="otomatis">Otomatis</option>
                                    <option value="manual">Manual</option>
                                  </select>
                                </div>
                              </Col>
                              <Col xs={12} lg={6}>
                                <div className="form-group">
                                  <label>Kategori</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) =>
                                      setCategory(e.target.value)
                                    }
                                    value={category}
                                    placeholder="Masukkan Kategori"
                                  />
                                </div>
                              </Col>
                              <Col xs={12} lg={12}>
                                <div
                                  style={{
                                    display:
                                      typeProduct === "manual"
                                        ? "block"
                                        : "none",
                                  }}
                                  className="form-group"
                                >
                                  <label>Url WA (Manual)</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) =>
                                      SetUrlManual(e.target.value)
                                    }
                                    value={urlManual}
                                    placeholder="Masukkan Url WA Tipe Manual"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row
                              style={{
                                display:
                                  typeProduct === "manual" ? "none" : "block",
                              }}
                            >
                              <Col>
                                <div className="form-group">
                                  <label>Deskripsi</label>
                                  <div ref={quillRefManual} />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12}>
                                <div className="form-group">
                                  <div className="d-flex">
                                    <label className="mr-2">Icon</label>
                                    <OverlayTrigger
                                      key={"top"}
                                      top={"top"}
                                      overlay={
                                        <Tooltip id={`tooltip-${"top"}`}>
                                          <img
                                            src={`${process.env.REACT_APP_STATIC_SRC}/prev-Icon_1688384691622.webp`}
                                            alt="icon"
                                            width="400px"
                                          />
                                        </Tooltip>
                                      }
                                    >
                                      <div>
                                        <i className="fas fa-question-circle"></i>
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                  <div className="input-group mb-3">
                                    <input
                                      type="text"
                                      className="form-control url"
                                      placeholder="Masukkan Link Icon"
                                      onChange={(e) => setIcon(e.target.value)}
                                      value={icon}
                                      disabled
                                    />
                                    <div className="input-group-append">
                                      <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() => {
                                          handleShow();
                                          setSelect("icon");
                                        }}
                                      >
                                        Pilih File
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col
                                xs={12}
                                style={{
                                  display:
                                    typeProduct === "manual" ? "none" : "block",
                                }}
                              >
                                <div className="form-group">
                                  <div className="d-flex">
                                    <label className="mr-2">Cover</label>
                                    <OverlayTrigger
                                      key={"top"}
                                      top={"top"}
                                      overlay={
                                        <Tooltip id={`tooltip-${"top"}`}>
                                          <img
                                            src={`${process.env.REACT_APP_STATIC_SRC}/prevCover_1688384640029.webp`}
                                            alt="cover"
                                            width="400px"
                                          />
                                        </Tooltip>
                                      }
                                    >
                                      <div>
                                        <i className="fas fa-question-circle"></i>
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                  <div className="input-group mb-3">
                                    <input
                                      type="text"
                                      className="form-control url"
                                      placeholder="Masukkan Link Cover"
                                      onChange={(e) => setCover(e.target.value)}
                                      value={cover}
                                      disabled
                                    />
                                    <div className="input-group-append">
                                      <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() => {
                                          handleShow();
                                          setSelect("cover");
                                        }}
                                      >
                                        Pilih File
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col
                                xs={12}
                                style={{
                                  display:
                                    typeProduct === "manual" ? "none" : "block",
                                }}
                              >
                                <div className="form-group">
                                  <div className="d-flex">
                                    <label className="mr-2">Banner</label>
                                    <OverlayTrigger
                                      key={"top"}
                                      top={"top"}
                                      overlay={
                                        <Tooltip id={`tooltip-${"top"}`}>
                                          <img
                                            src={`${process.env.REACT_APP_STATIC_SRC}/prevBanner_1688384630309.webp`}
                                            alt="banner"
                                            width="400px"
                                          />
                                        </Tooltip>
                                      }
                                    >
                                      <div>
                                        <i className="fas fa-question-circle"></i>
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                  <div className="input-group mb-3">
                                    <input
                                      type="text"
                                      className="form-control url"
                                      placeholder="Masukkan Link Icon"
                                      onChange={(e) =>
                                        setBanner(e.target.value)
                                      }
                                      value={banner}
                                      disabled
                                    />
                                    <div className="input-group-append">
                                      <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() => {
                                          handleShow();
                                          setSelect("banner");
                                        }}
                                      >
                                        Pilih File
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <div
                              style={{
                                display:
                                  typeProduct === "manual" ? "none" : "block",
                              }}
                            >
                              {formValues.fields.map((element, index) => (
                                <Row key={index} className="bg-light py-3 mb-3">
                                  <Col md={3}>
                                    <div className="form-group">
                                      <label>Nama</label>
                                      <input
                                        ref={(ref) => {
                                          nameRef.current[index] = ref;
                                        }}
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => handleChange(index, e)}
                                        value={element.name}
                                        placeholder="Nama"
                                      />
                                    </div>
                                  </Col>
                                  <Col md={3}>
                                    <div className="form-group">
                                      <label>Placeholder</label>
                                      <input
                                        ref={(ref) => {
                                          phRef.current[index] = ref;
                                        }}
                                        name="placeholder"
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => handleChange(index, e)}
                                        value={element.placeholder}
                                        placeholder="Placeholder"
                                      />
                                    </div>
                                  </Col>
                                  <Col md={2}>
                                    <div className="form-group">
                                      <label>Tipe</label>
                                      <select
                                        ref={(ref) => {
                                          tipeRef.current[index] = ref;
                                        }}
                                        name="type"
                                        className="form-control"
                                        onChange={(e) => handleChange(index, e)}
                                        value={element.type}
                                      >
                                        <option value="">Pilih Tipe</option>
                                        <option value="number">Number</option>
                                        <option value="text">Text</option>
                                        <option value="email">Email</option>
                                        <option value="password">
                                          Password
                                        </option>
                                        <option value="select">Select</option>
                                      </select>
                                    </div>
                                  </Col>
                                  {element.type === "select" ? (
                                    <Col md={6}>
                                      <div className="form-group">
                                        <label>Opsi</label>
                                        <div className="form-group d-flex align-items-center">
                                          <input
                                            ref={(ref) => {
                                              optValRef.current[index] = ref;
                                            }}
                                            name="value"
                                            type="text"
                                            className="form-control mr-2"
                                            onChange={(e) => {
                                              setInput1Value((val) => ({
                                                ...val,
                                                [`form${index}`]: e.target
                                                  .value,
                                              }));
                                            }}
                                            value={
                                              input1Value[`form${index}`] || ""
                                            }
                                            placeholder="Value"
                                          />
                                          <input
                                            ref={(ref) => {
                                              optNameRef.current[index] = ref;
                                            }}
                                            name="valueName"
                                            type="text"
                                            className="form-control mr-2"
                                            onChange={(e) => {
                                              setInput2Value((val) => ({
                                                ...val,
                                                [`form${index}`]: e.target
                                                  .value,
                                              }));
                                            }}
                                            value={
                                              input2Value[`form${index}`] || ""
                                            }
                                            placeholder="Nama"
                                          />
                                          {/* <span onClick={(e) => addOpt(index)}>
                                  <i className="fas fa-plus"></i>
                                </span> */}
                                          <Button
                                            className="button add"
                                            variant="success"
                                            type="button"
                                            onClick={(e) => addOpt(index)}
                                          >
                                            Add
                                          </Button>
                                        </div>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  <Col md={1}>
                                    {index ? (
                                      <Button
                                        className="button add btnRemove"
                                        variant="danger"
                                        type="button"
                                        onClick={() => removeFormFields(index)}
                                      >
                                        Remove
                                      </Button>
                                    ) : null}
                                  </Col>
                                  <Col md={12}>
                                    <div className={`form${index}`}>
                                      {optState[`form${index}`]
                                        ? optState[`form${index}`].map(
                                            (element) => {
                                              return element;
                                            }
                                          )
                                        : null}
                                    </div>
                                  </Col>
                                </Row>
                              ))}
                              <Button
                                className="my-3 button add"
                                variant="primary"
                                type="button"
                                onClick={() => addFormFields()}
                              >
                                +
                              </Button>
                              <Row>
                                <Col xs={12} lg={4}>
                                  <div className="form-group">
                                    <label>Check Game</label>
                                    <select
                                      className="form-control"
                                      onChange={(e) =>
                                        setCheckGame(e.target.value)
                                      }
                                      value={checkGame}
                                    >
                                      <option value="">Pilih Game</option>
                                      <option value="ML">Mobile Legends</option>
                                      <option value="FF">Free Fire</option>
                                    </select>
                                  </div>
                                </Col>
                                <Col xs={12} lg={4}>
                                <div className="form-group">
                                    <label>Game Trending</label>
                                    <select
                                      className="form-control"
                                      onChange={(e) =>
                                        setTrending(e.target.value)
                                      }
                                      value={trending}
                                    >
                                      <option disabled>Pilih Status</option>
                                      <option value={true}>Aktif</option>
                                      <option value={false}>Nonaktif</option>
                                    </select>
                                  </div>
                                  </Col>
                                <Col
                                  xs={12}
                                  lg={4}
                                  style={{
                                    display: displayStatus.detail,
                                  }}
                                >
                                  <div className="form-group">
                                    <label>Status</label>
                                    <select
                                      className="form-control"
                                      onChange={(e) =>
                                        setStatus(e.target.value)
                                      }
                                      value={status}
                                    >
                                      <option disabled>Pilih Status</option>
                                      <option value={true}>Aktif</option>
                                      <option value={false}>Nonaktif</option>
                                    </select>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                          <div className="card-footer text-right">
                            <button
                              className="btn btn-primary mr-1"
                              type="submit"
                            >
                              Submit
                            </button>
                            <Link
                              to="/products/games"
                              className="btn btn-secondary"
                              type="button"
                            >
                              Batal
                            </Link>
                          </div>
                        </form>
                      </div>
                      <ModalIcons
                        show={show}
                        handleClose={handleClose}
                        assets={assets}
                        assetsSelect={assetsSelect}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </HelmetProvider>
  );
};

export default AddGames;
