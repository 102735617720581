import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import {
  Link,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom";
import SweetAlerMessage from "../../js/sweetalert";
import swal from "sweetalert";
import { Helmet, HelmetProvider } from "react-helmet-async";

const AddVendors = () => {
  const history = useHistory();
  const location = useLocation();

  const handleGoBack = () => {
    if (window.location.pathname === "/vendors/add-vendors") {
      history.goBack();
    } else {
         history.goBack();
    }
  };

  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [rate, setRate] = useState("");
  const [formValues, setFormValues] = useState([{ key: "", value: "" }]);

  const [url, setUrl] = useState(
    `${process.env.REACT_APP_BASE_URL}/api/vendor/`
  );
  const [method, setMethod] = useState("POST");
  const [title, setTitle] = useState("Tambah Vendor");
  const [msg, setMsg] = useState("Vendor berhasil ditambahkan");

  const keyRef = useRef([]);
  const valueRef = useRef([]);

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    let newFormValues = [...formValues];
    newFormValues.push({ key: "", value: "" });
    setFormValues(newFormValues);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const creds = {};
    formValues &&
      formValues.forEach((element, index) => {
        const key = keyRef.current[index].value;
        const value = valueRef.current[index].value;
        creds[key] = value;
      });

    const requestBody = {
      name,
      code,
      rate,
      creds,
      token: localStorage.getItem("token"),
    };

    fetch(url, {
      method: method,
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, data: data })))
      .then((data) => {
        if (data.status === 200) {
          swal({
            title: "Berhasil",
            text: msg,
            icon: "success",
            buttons: false,
            timer: 1000,
          });
          if (window.location.pathname === "/vendors/add-vendors") {
            history.goBack();
          } else {
            history.goBack();
            history.goBack();
          }
        } else {
          swal({
            title: "Gagal",
            text: data.data.msg,
            icon: "error",
            buttons: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    SweetAlerMessage();
    $("[data-height]").each(function() {
      $(this).css({
        height: $(this).data("height"),
      });
    });

    if (window.location.pathname === "/vendors/add-vendors") {
      setUrl(`${process.env.REACT_APP_BASE_URL}/api/vendor/`);
      setMethod("POST");
    } else {
      setUrl(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/vendor/${window.location.pathname.split("/").pop()}`
      );
      setMethod("PUT");

      fetch(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/vendor?id=${window.location.pathname.split("/").pop()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((r) => r.json().then((d) => ({ status: r.status, data: d.data })))
        .then((d) => {
          setName(d.data.name);
          setCode(d.data.code);
          setRate(d.data.rate);
          setFormValues(
            Object.keys(d.data.creds).map(function(key) {
              return {
                key: key,
                value: d.data.creds[key],
              };
            })
          );
        })
        .catch((err) => {
          swal(err.message, {
            icon: "error",
            button: false,
          });
        });
      setTitle("Edit Vendors");
      setMsg("Vendors berhasil diubah");
    }
  }, [location.pathname]);

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charset="utf-8" />
        <meta name="description" content="Add Vendors - Admin" />
        <meta
          name="google-site-verification"
          content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34="
        />
        <title>Add Vendors - Admin</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Vendors</h1>
            <div className="section-header-breadcrumb">
              <div className="breadcrumb-item active">
                <Link to="/dashboard">Dashboard</Link>
              </div>
              <div className="breadcrumb-item">
                <Link to="/vendors">List Vendors</Link>
              </div>
              <div className="breadcrumb-item" id="title-vendors">
                Add Vendors
              </div>
            </div>
          </div>

          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <form className="card" onSubmit={handleSubmit}>
                  <div className="card-header">
                    <div
                      className="cursor-pointer d-flex"
                      onClick={handleGoBack}
                    >
                      <span>
                        <i className="fas fa-arrow-left"></i>
                      </span>
                      <h4 className="ml-2">{title}</h4>
                    </div>
                  </div>
                  <div className="card-body">
                    <Row>
                      <Col xs={12} lg={4}>
                        <div className="form-group">
                          <label>Nama</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            placeholder="Masukkan Nama"
                          />
                        </div>
                      </Col>
                      <Col xs={12} lg={4}>
                        <div className="form-group">
                          <label>Kode</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => setCode(e.target.value)}
                            value={code}
                            placeholder="Masukkan Kode"
                          />
                        </div>
                      </Col>
                      <Col xs={12} lg={4}>
                        <div className="form-group">
                          <label>Rate</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => setRate(e.target.value)}
                            value={rate}
                            placeholder="Masukkan Rate"
                          />
                        </div>
                      </Col>
                    </Row>
                    {formValues.map((element, index) => (
                      <Row key={index} className="bg-light py-3 mb-3">
                        <Col md={3}>
                          <div className="form-group">
                            <label>Key</label>
                            <input
                              ref={(ref) => {
                                keyRef.current[index] = ref;
                              }}
                              name="key"
                              type="text"
                              className="form-control"
                              onChange={(e) => handleChange(index, e)}
                              value={element.key}
                              placeholder="Key"
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="form-group">
                            <label>Value</label>
                            <input
                              ref={(ref) => {
                                valueRef.current[index] = ref;
                              }}
                              name="value"
                              type="text"
                              className="form-control"
                              onChange={(e) => handleChange(index, e)}
                              value={element.value}
                              placeholder="Value"
                            />
                          </div>
                        </Col>
                        <Col md={1}>
                          {index ? (
                            <Button
                              className="button add btnRemove"
                              variant="danger"
                              type="button"
                              onClick={() => removeFormFields(index)}
                            >
                              Remove
                            </Button>
                          ) : null}
                        </Col>
                      </Row>
                    ))}
                    <Button
                      className="my-3 button add"
                      variant="primary"
                      type="button"
                      onClick={() => addFormFields()}
                    >
                      +
                    </Button>
                  </div>
                  <div className="card-footer text-right">
                    <button className="btn btn-primary mr-1" type="submit">
                      Submit
                    </button>
                    <Link
                      to="/vendors"
                      className="btn btn-secondary"
                      type="button"
                    >
                      Batal
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </HelmetProvider>
  );
};

export default AddVendors;
